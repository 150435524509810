@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.location-display {
  padding: 0 0 1.5em 1.25em;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: -0.5em;

  &--hide-arrow {
    padding-left: 0;
  }

  &--datetime {
    margin-top: -0.5em;
    text-align: right;
    white-space: nowrap;

    &--multiple {
      .location-display--datetime--item {
        @include media-breakpoint-up('md') {
          display: flex;

          .location-date {
            margin-right: math.div($grid-gutter-width, 2);
          }
        }
      }
    }
  }

  &--a {
    &:after {
      content: '';
      position: absolute;
      left: 0.3em;
      margin-left: -1px;
      top: 0.5em;
      bottom: -0.5em;
      border-left: 2px solid $color--blue;
    }
  }

  &--color-black {
    &.location-display--a {
      &:after {
        border-left: 2px solid $color--grey--teaserborder;
      }
    }
  }

  &--arrow {
    position: absolute;
    left: 0.3em;
    margin-left: -1px;
    top: 1em;
    border-left: 2px solid $color--primary;

    .location-display--color-black & {
      border-left: 2px solid $color--black;
    }

    bottom: 50%;
    margin-bottom: -0.5em;
    z-index: 10;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    animation: fadeinarrow 0.75s ease-in 0s 1 forwards;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      margin-left: -2px;
      bottom: -2px;
      height: 7px;
      border-left: 2px solid $color--primary;

      .location-display--color-black & {
        border-left: 2px solid $color--black;
      }

      transform: rotate(0deg);
      transform-origin: 50% 100%;
      animation: foldoutarrowleft 0.25s ease-in 0.75s 1 forwards;
    }

    &:after {
      transform: rotate(-0deg);
      animation: foldoutarrowright 0.25s ease-in 0.75s 1 forwards;
    }
  }

  &--dot {
    position: absolute;
    background: $color--white--hover;
    top: 0.5em;
    left: 0;
    width: 0.6em;
    height: 0.6em;
    border: 2px solid $color--primary;
    border-radius: 50%;
    z-index: 20;

    .location-display--color-black & {
      background: $color--white;
      border: 2px solid $color--black;
    }
  }

  &--hide-arrow {
    &.location-display--a::after,
    .location-display--dot,
    .location-display--arrow {
      display: none;
    }
  }

  &--b {
    .location-display--dot {
      background: $color--primary;
    }

    &.location-display--color-black {
      .location-display--dot {
        background: $color--black;
      }
    }

    &.location-display--color-white {
      .location-display--dot {
        border: 2px solid $color--black;
        background: $color--white;
      }
    }
  }

  &--header {
    font-weight: bold;

    .location-display--size-big & {
      font-size: 1.2em;
    }
  }

  .card--tr-business & {
    padding: 0 0 0 1.25em;
    margin-top: 0;
  }

  .location-labels {
    margin-bottom: 0.5em;

    @include media-breakpoint-up('md') {
      max-width: calc(100% - 130px);
      margin-top: -0.9em;

      .source-flow--business & {
        margin-top: 0;
      }
    }
  }
}

@keyframes fadeinarrow {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes foldoutarrowleft {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes foldoutarrowright {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-45deg);
  }
}
