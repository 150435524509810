@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.input-el--number-outer {
  display: inline-flex;
  flex-wrap: wrap;
  width: 120px;
  margin-bottom: math.div($grid-gutter-width, 2);

  input[type='number'] {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .input-el {
    width: 3em;
    margin: 0;
  }

  .input-el--label {
    width: auto;
    max-width: 130%;
    margin-left: -6px;
  }

  .input-el--feedback {
    white-space: nowrap;
    margin-left: -33px;
  }

  .input-el--number--modify {
    margin-top: 0.6em;
    width: 28px;
    height: 28px;
    background-color: $color--secondary;
    color: $color--white;
    border-radius: $border-radius--default;
    line-height: 1;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid;
    }

    &::before {
      height: 8px;
      margin-top: -4px;
      margin-left: -1px;
    }

    &::after {
      width: 8px;
      margin-left: -4px;
      margin-top: -1px;
    }

    &.is-disabled {
      background-color: $color--grey--light;
      cursor: not-allowed;
    }

    @include custom-breakpoint-up(1024px) {
      &:hover:not(.is-disabled) {
        background-color: $color--secondary--hover;
      }
    }

    &:first-of-type {
      margin-right: 5px;

      &::before {
        display: none;
      }
    }

    &:last-of-type {
      margin-left: 5px;
    }
  }
}
