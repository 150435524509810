@import '../../brenger-shared-ui/assets/scss/helpers/all';

.ps--item-display {
  position: relative;
  &.card {
    padding: 2.4rem;
    @include media-breakpoint-down(xs) {
      padding: 0.5rem 15px 2.5rem 15px;
      margin: 0 -15px 2rem -15px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $color--grey--teaserborder;
      box-shadow: none;
    }
  }
  &--title {
    color: $color--primary;
    font-size: 2rem;
    line-height: 3rem;
  }

  &--edit {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: inherit;
    padding: 0;
    margin: 0;
    width: 100%;
    b {
      margin: 0 0.5rem 0 0;
      text-align: left;
    }
    svg {
      margin: 0.2rem 0 0 0;
    }
  }

  &--counter {
    border: 1px solid $color--grey--teaserborder;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    width: 10rem;
    .ps-config--count--delete {
      svg {
        width: 1.2rem;
        height: 1.6rem;
        margin: 0;
      }
    }
  }

  &--image,
  &--no-image {
    border-radius: $border-radius--tile;
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;
    object-fit: cover;
  }
  &--image {
    img {
      width: 8rem;
      height: 8rem;
      object-fit: cover;
    }
  }
  &--no-image {
    background-color: $color--grey--lighter;
    .IconCamera {
      width: 1.5em;
      height: 1.5em;

      circle,
      path {
        fill: $color--grey--teaserborder;
      }
    }
  }
}
