@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.input-el--autocomplete {
  input {
    position: relative;
    padding-left: 35px;
    padding-right: 45px;
  }

  label {
    margin-top: 0;
    margin-bottom: 0;
  }

  .input-el--label {
    margin-left: 20px;
  }

  .input-el--feedback {
    margin-left: 37px;
  }

  &.input-el--has-focus {
    input {
      z-index: 5;
    }

    .input-el--label {
      z-index: 15;
    }
  }

  .autocomplete-list {
    position: absolute;
    top: 2.38em;
    left: 0;
    width: 100%;
    z-index: 50;
    border-style: solid;
    border-color: $color--primary;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 $border-radius--default $border-radius--default;
    overflow: hidden;
    box-shadow: $box-shadow--primary;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transform: translate3d(0, -10px, 0);
    transition: all 0.5s;
    visibility: hidden;
    transition-delay: 0.25s;

    &--show {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition-delay: 0s;
    }

    @include media-breakpoint-up(md) {
      top: 2.45em;
    }

    &--item {
      padding: 0.695em math.div($grid-gutter-width, 2);
      background: $color--white;
      @include custom-breakpoint-up(1024px) {
        cursor: pointer;

        &:hover {
          background: $color--primary--light;
        }
      }

      &.highlighted {
        background: $color--primary--light;
      }
    }
  }

  &--icon-wrapper {
    position: absolute;
    top: 10px;
    left: 9px;
    z-index: 10;

    @include media-breakpoint-up(md) {
      top: 12px;
    }

    .IconSmile {
      display: none;
      margin-left: -0.2em;
      margin-top: 0.2em;
    }
  }

  &--is-loading {
    .IconSmile {
      display: block;
    }

    .IconMarker {
      display: none;
    }
  }

  &--hints {
    position: relative;
    margin-top: math.div($grid-gutter-width-negative, 2);
    padding: 15px 5px 5px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color--white;
    background: $color--primary;
    border-radius: 0 0 $border-radius--default $border-radius--default;

    svg {
      margin-right: 5px;
    }
  }

  &--clear {
    position: absolute;
    right: math.div($grid-gutter-width, 2);
    top: 0.6em;
    z-index: 6;
    transition: opacity $default-transition-speed;
    opacity: 0;
    cursor: pointer;

    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }

  &.input-el--has-value {
    .input-el--autocomplete--clear {
      opacity: 1;
    }
  }
}

.grouped-autocomplete {
  @include media-breakpoint-down(xs) {
    .input-row:first-child .input-element,
    .input-row:first-child {
      margin-bottom: 0;
    }

    .input-row:first-child {
      .type-ba input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .ba-options-up.ba-focused.type-ba input {
        border-radius: 0;
      }
    }

    .input-row:last-child {
      .input-element {
        margin-top: -1px;
      }

      .type-ba input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;

    .input-row:first-child {
      .input-element {
        margin-right: -0.2em;
      }

      .type-ba input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .ba-options-up.ba-focused.type-ba input {
        border-radius: 0 0 0 $border-radius--default;
      }
    }

    .input-row:last-child {
      .type-ba input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .ba-options-up.ba-focused.type-ba input {
        border-radius: 0 0 $border-radius--default 0;
      }
    }
  }
}
