.flag-icon-wrapper {
  .flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .flag-icon {
    width: 1em;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    line-height: 1em;

    &::before {
      content: '\00a0';
    }
  }

  .flag-icon-at {
    background-image: url(../../../assets/flags/at.svg);
  }

  .flag-icon-be {
    background-image: url(../../../assets/flags/be.svg);
  }

  .flag-icon-ch {
    background-image: url(../../../assets/flags/ch.svg);
  }

  .flag-icon-de {
    background-image: url(../../../assets/flags/de.svg);
  }

  .flag-icon-dk {
    background-image: url(../../../assets/flags/dk.svg);
  }

  .flag-icon-es {
    background-image: url(../../../assets/flags/es.svg);
  }

  .flag-icon-fr {
    background-image: url(../../../assets/flags/fr.svg);
  }

  .flag-icon-gb {
    background-image: url(../../../assets/flags/gb.svg);
  }

  .flag-icon-ie {
    background-image: url(../../../assets/flags/ie.svg);
  }

  .flag-icon-it {
    background-image: url(../../../assets/flags/it.svg);
  }

  .flag-icon-nl {
    background-image: url(../../../assets/flags/nl.svg);
  }

  .flag-icon-se {
    background-image: url(../../../assets/flags/se.svg);
  }
}
