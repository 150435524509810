@import '../../../assets/scss/helpers/all';

$priceBreakSm: '340px';
$priceBreak: '450px';

.price-display {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  position: relative;
  width: 120px;

  @include custom-breakpoint-up(#{$priceBreakSm}) {
    width: 190px;

    &--is-simple,
    &--is-special--flat {
      width: 160px;
    }
  }

  @include media-breakpoint-up(sm) {
    &--is-simple {
      width: 160px;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    margin-top: 5px;
    justify-content: space-between;

    &--is-simple {
      margin-top: 0;
      justify-content: flex-start;
    }

    &--is-special--flat {
      width: auto;
    }
  }

  .sheet-layout--sidebar & {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 2px solid $color--primary;
  }

  &--head {
    color: $color--primary;
    line-height: 1em;
    margin-bottom: 0.2em;
    white-space: nowrap;

    .sheet-layout--footer & {
      width: 100%;
    }

    @include media-breakpoint-up('md') {
      margin-bottom: 0.5em;

      .price-display--is-special & {
        margin-bottom: 0.25em;
      }
    }

    .sheet-layout--footer & {
      svg {
        margin-right: 0.5em;
        width: 0.6em;
        height: 0.6em;
        transition: all $default-transition-speed;
        transform: rotate(-90deg);

        .sheet-layout--is-active & {
          transform: rotate(90deg);
        }
      }
    }
  }

  &--layout {
    font-size: 3rem;
    display: block;
    color: $color--primary;
    transition: color $default-transition-speed;
    white-space: nowrap;

    @include media-breakpoint-down('md') {
      display: flex;

      .price-display--is-special--flat & {
        display: block;
      }
    }

    .price-display--is-simple & {
      margin-left: 0;
    }

    .price-display--is-special & {
      @include media-breakpoint-down('sm') {
        font-size: 1.4em;
      }
    }

    small {
      font-size: 0.7em;
      position: relative;
      top: -0.3em;
    }

    @include media-breakpoint-up(lg) {
      order: 3;
      margin-left: 0;
    }
  }

  &.price-display-failure {
    span {
      color: #d63031;
    }
  }

  &--loading-element {
    display: inline;
    transition: all 0.2s;
    position: relative;
    top: -0.4em;
    display: none;

    .price-display--is-special & {
      top: 0;
    }

    @include media-breakpoint-up(lg) {
      order: 2;

      .price-display--is-simple & {
        order: 3;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-left: auto;
    }
  }

  &--is-loading {
    .price-display--layout {
      color: $color--grey--second;
    }

    .price-display--loading-element {
      display: block;
    }
  }

  &--is-visible {
    .price-display--head,
    .price-display--layout {
      animation: priceblink 0.5s ease-in-out 0.25s 1 forwards;

      svg path {
        animation: priceblinksmile 0.5s ease-in-out 0.25s 1 forwards;
      }
    }
  }

  &--special-copy,
  &--old-price {
    font-size: 50%;
    line-height: 60%;
    margin-top: 0.25em;

    @include custom-breakpoint-up(#{$priceBreak}) {
      margin-top: auto;
      margin-bottom: 0.3em;
      text-align: right;
    }

    .price-display--is-special--flat & {
      text-align: left;

      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
  }

  &--old-price {
    font-family: $font-family--normal;
    color: $color--grey--second;

    @include custom-breakpoint-up(#{$priceBreakSm}) {
      display: inline-block;
      font-size: 85%;
      line-height: 137%;
      vertical-align: baseline;
      margin: 0 5px 0 0;
    }

    @include media-breakpoint-up('md') {
      line-height: 120%;
    }

    @include media-breakpoint-up('lg') {
      line-height: 100%;
    }

    @include media-breakpoint-down('md') {
      margin-left: 5px;
      order: 2;
    }

    span {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: 1px solid $color--grey--second;
      }
    }
  }

  @keyframes priceblink {
    0%,
    25%,
    75%,
    100% {
      color: $color--primary;
    }

    50% {
      color: $color--secondary;
    }
  }

  @keyframes priceblinksmile {
    0%,
    25%,
    75%,
    100% {
      fill: $color--primary;
    }

    50% {
      fill: $color--secondary;
    }
  }
}
