@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.input-el--feedback {
  margin: 0.2em 0 0 math.div($grid-gutter-width, 2);
  font-size: 0.75em;
  line-height: 1.2em;

  .input-el--radio & {
    margin-left: 2.6em;
  }

  .input-el--radio--inline & {
    margin-left: 0;
  }

  .input-el--file &,
  .input-el--date--inline & {
    margin-left: 0;
  }

  &--error {
    color: $color--warn;
  }

  &--success {
    color: $color--secondary--hover;
  }

  &--warning {
    color: $color--orange;
  }
}
