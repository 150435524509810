@import '../../brenger-shared-ui/assets/scss/helpers/all';

.dashboard-welcome {
  position: relative;
  padding: 100px 0 30px 0;

  @include media-breakpoint-up(md) {
    padding: 30px 115px;
  }

  &--logo {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 0;
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-blue--bright;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      left: 0;
      top: 28px;
      margin-left: 0;
    }
  }
}
