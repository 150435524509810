@use 'sass:math';

@import '../../../assets/scss/helpers/all';

@keyframes backdrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.input-el--examples {
  &--backdrop {
    background: #223b58;
    opacity: 0;
    visibility: visible;
    position: fixed;
    inset: 0;
    animation: backdrop 0.25s linear 0.1s 1 forwards;
  }
  &--overlay {
    position: absolute;
    @include media-breakpoint-desk-and-up() {
      // These vars will be set in the component itself
      top: var(--input-el--examples--top);
      left: var(--input-el--examples--left);
      width: var(--input-el--examples--width);
      .input-el--inner {
        display: block;
      }
    }
    @include media-breakpoint-down(md) {
      inset: 0;
      background-color: #fff;
      padding: math.div($grid-gutter-width, 2);
      .input-el {
        height: 100%;
      }
      .input-el--inner {
        flex-direction: column;
        max-height: 100%;
      }
      input {
        border: 1px solid $color--primary !important; // to override valid green border color
      }
    }
    .input-el--tag--wrapper {
      @include media-breakpoint-desk-and-up() {
        .IconArrow {
          display: none;
        }
      }
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        .input-el--tag {
          flex-grow: 1;
          margin-left: 0.5em;
        }
        .IconArrow {
          width: 2em;
          height: 1em;
        }
      }
    }
  }
}

.input-el--examples-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -0.3em;
  background-color: $color--white;
  color: $color--black--light;
  overflow: hidden;
  z-index: 5;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    position: static;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-desk-and-up() {
    .input-el--examples-with-list & {
      border: 1px solid $color--primary;
      border-top: 1px solid $color--grey--teaserborder;
      border-radius: 0 0 $border-radius--default $border-radius--default;
    }
  }

  &--item {
    padding: $input-spacing;
    overflow: hidden;
    position: relative;
    padding-right: 2em;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 1.5em;
    }
    cursor: pointer;

    span {
      color: $color--grey--teaserborder;
      display: inline-block;
      margin-left: 1em;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 0.5em;
      @include media-breakpoint-down(md) {
        right: 0;
      }
      margin-top: -0.5em;
      width: 1em;
      height: 1em;

      .cls-1 {
        fill: $color--primary;
      }
    }

    &:hover,
    &--highlight {
      background-color: $color--primary;
      color: $color--white;

      span {
        color: $color--white;
      }

      svg {
        .cls-1 {
          fill: $color--white;
        }
      }
    }
  }

  &--title {
    padding: $input-spacing;
    color: $color--grey--teaserborder;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  &--predefined {
    overflow: auto;
    max-height: 30vh;
    @include media-breakpoint-down(md) {
      max-height: none;
      flex-grow: 1;
    }
    @include media-breakpoint-desk-and-up() {
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
