@import '../../brenger-shared-ui/assets/scss/helpers/all';

.ps-start-search-button {
  border: 1px solid $color--grey--teaserborder;
  color: $color--grey--darker;
  font-weight: normal;
  text-align: left;
  margin-top: 0;
  font-size: 1.8rem;
  padding: 1.3rem;
  @include media-breakpoint-desk-and-up() {
    font-size: 2rem;
    padding: 1.5rem;
  }
  svg {
    margin-right: 0.5em;
  }
}

.ps-warn-suffix {
  color: $color--grey--darker;
}

.ps--main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  @include media-breakpoint-desk-and-up() {
    align-items: flex-start;
  }
  .btn,
  h2 {
    margin: 0;
  }
  .btn {
    border-radius: 100px;
  }
}

.ps-config--update-head {
  font-weight: bold;
  color: $color--grey--darker;
  &--content {
    width: 100%;
    text-align: center;
  }
}
