@import '../../../assets/scss/helpers/all';

.input-el--button-select {
  &--option {
    @extend %button;

    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    width: 100%;
    color: $color--primary;
    margin-bottom: 0.5em;
    background-color: $color--white;
    animation: fadeInUp 0.33s ease-in-out 0s 1 forwards;
    transition: all $default-transition-speed;

    &-selected {
      color: $color--white;
      background-color: $color--secondary;
    }

    &-icon {
      height: 1.4em;
      width: auto;
      margin-right: 0.5em;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        text-decoration: none;
        color: $color--white;
        background-color: $color--secondary--hover;
      }

      &-selected {
        &:hover {
          background-color: $color--secondary;
        }
      }
    }
  }
}
