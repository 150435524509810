@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.check-mark-list {
  &--header {
    color: $color--primary;
    font-weight: bold;
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }

  &--item {
    display: flex;
    margin-bottom: 0.5em;
  }

  &--icon {
    padding-right: math.div($grid-gutter-width, 2);

    svg {
      width: 1em;
      height: 0.9em;
    }
  }
}
