@import '../../../assets/scss/helpers/all';

.progress-bar {
  background: $color--white--hover;
  height: 4px;
  position: relative;

  .progress-bar-indicator {
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.33s right ease-in-out;
    right: var(--progress-position);
    bottom: 0;
    border-radius: 0 $border-radius--default $border-radius--default 0;
    background: $color--primary;
  }

  .progress-header & {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
