@import '../../../assets/scss/helpers/all';

.location-marker {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);

  &--label {
    padding: 0 0.6em;
    font-size: 1.1em;
    line-height: 1.6em;
    border: $card-border;
    position: absolute;
    z-index: 5;
    border-radius: 20px;
    white-space: nowrap;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    transform: translate3d(-50%, -2.5em, 0);
    background: $color--white;

    .location-marker--driver & {
      top: 50%;
      left: 50%;
      margin-left: 0.2em;
      transform: translate3d(-50%, 1em, 0);
      z-index: 10;
    }

    @media print {
      display: none;
    }
  }

  &--icon {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
  }
}
