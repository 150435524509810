@import '../../../assets/scss/helpers/all';

.brenger_tooltip {
  display: inline-block;
  position: relative;
  top: 0.15em;
  z-index: 100;

  svg {
    width: 1em;
    height: 1em;
  }

  .brenger_tooltip-body {
    @include visible(false);

    position: absolute;
    bottom: 100%;
    left: 50%;
    transition: $default-transition-speed all;
    transform: translate3d(-50%, 10px, 0);
    font-size: 0.8em;
    line-height: 1.5em;
    background: $color--primary;
    padding: 8px 12px;
    margin-left: -1px;
    border-radius: $border-radius--default;
    color: $color--white;
    width: 150px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      width: 300px;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      background: $color--primary;
      transform: rotate(45deg) translate3d(-5px, 0, 0);
    }

    a {
      color: $color--white;
      display: inline-block;
      margin-left: 0.2em;
    }
  }

  &:hover {
    .brenger_tooltip-body {
      @include visible(true);

      transform: translate3d(-50%, 0, 0);
    }
  }

  &.brenger_tooltip-right {
    .brenger_tooltip-body {
      left: 0;
      transform: translate3d(-0.8em, 0.9em, 0);

      &:after {
        left: 1.5em;
      }
    }

    &:hover {
      .brenger_tooltip-body {
        transform: translate3d(-0.8em, 0, 0);
      }
    }
  }

  &.brenger_tooltip-left {
    .brenger_tooltip-body {
      left: auto;
      right: 0;
      transform: translate3d(0.9em, 0.9em, 0);

      &:after {
        left: auto;
        right: 0.85em;
      }
    }

    &:hover {
      .brenger_tooltip-body {
        transform: translate3d(0.9em, 0, 0);
      }
    }
  }
}
