@import '../../brenger-shared-ui/assets/scss/helpers/all';

.ps-search {
  &--head {
    flex-grow: 0;
    .input-default {
      padding: 0;
      border: none;
    }
    @include media-breakpoint-desk-and-up() {
      border-top: 1px solid $color--grey--teaserborder;
      .ps-modal--back {
        display: none;
      }
    }
    @include media-breakpoint-down(lg) {
      .ps-search--icon-mag {
        display: none;
      }
    }
  }

  &--desk-head {
    display: none;
    @include media-breakpoint-desk-and-up() {
      display: flex;
      align-items: center;
      color: $color--grey--darker;
      padding: 1.2em 28px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 10;
      b {
        margin: auto;
      }
      .IconCross {
        cursor: pointer;
        width: 0.9em;
        height: 0.9em;
      }
    }
  }

  &--add-manual {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color--grey--darker;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    &.border-top {
      border-top: 1px solid $color--grey--teaserborder;
    }
    .IconSmile {
      width: 0.75em;
      height: 0.75em;
    }
  }

  &--input {
    border: none;
    padding: 5px;
    border-radius: 0;
    width: 100%;
    font-size: 20px;
    font-family: $font-family--normal;
    /* clears the ‘X’ */
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
    &:focus,
    &:active {
      outline: none;
      border: none;
    }
    &-clear {
      background-color: $color--grey--teaserborder;
      border-radius: 100%;
      width: 1.1em;
      height: 1em;
      position: relative;
      svg {
        position: absolute;
        top: 0.3em;
        left: 0.3em;
        width: 0.4em;
        height: 0.4em;
      }
      .IconCross {
        path {
          fill: #fff;
        }
      }
    }
  }
}
