@import '../../../assets/scss/helpers/all';

.input-el--select {
  position: relative;
  background: $color--white;
  border-radius: $border-radius--default;

  .input-el--label {
    z-index: 0;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 5;
    padding-right: 35px;
  }

  select::-ms-expand {
    display: none;
  }

  .IconSmile {
    position: absolute;
    right: 0.75em;
    width: 0.35em;
    height: 0.7em;
    top: 1.3em;
    transform: translate3d(0, -50%, 0) rotate(90deg);
    z-index: 0;
  }
}
