@import '../../brenger-shared-ui/assets/scss/helpers/all';

.auction-types {
  img {
    width: 50px;
  }

  .input-el--tile--suffix {
    svg {
      font-size: 10px;
    }
  }
}

.datetime-period-string {
  color: $color--primary;

  &::first-letter {
    text-transform: uppercase;
  }
}
