@import '../../../assets/scss/helpers/all';

.person-balloon {
  &--wrapper {
    display: inline-block;
    width: 100%;
  }

  &--svg {
    width: 115px;
    height: 115px;

    @include media-breakpoint-up(md) {
      width: 150px;
      height: 150px;
    }
  }

  .col {
    align-items: center;
  }

  &--balloon {
    min-height: 110px;
    min-width: 110px;
    display: inline-block;
    position: relative;

    @include media-breakpoint-up(md) {
      min-height: 140px;
      min-width: 140px;
    }

    &--content {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 50%;
      background-color: $color--white;
      min-width: 100%;
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.3;

      @include media-breakpoint-up(md) {
        line-height: 1.5;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -25px;
        margin-top: -20px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 30px solid $color--white;
      }
    }
  }
}
