@import '../../../assets/scss/helpers/all';

.collapse {
  .IconSmile {
    width: 0.8em;
    height: 0.8em;
    transition: all $default-transition-speed;
    transform-origin: 50% 50%;
    transform: translate3d(0.5em, 0.1em, 0) rotate(90deg);
  }

  &--header h5 {
    color: $color--primary;
    padding: 0.5em 0;
    margin: 0;
  }

  &--content {
    display: none;
  }

  &--is-open {
    .IconSmile {
      transform: translate3d(0.5em, 0.1em, 0) rotate(-90deg);
    }

    .collapse--content {
      display: block;
    }
  }
}
