@import '../../brenger-shared-ui/assets/scss/helpers/all';

.api-settings {
  &--header {
    @include media-breakpoint-up('md') {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }
    }
  }
}
