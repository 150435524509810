@import '../../brenger-shared-ui/assets/scss/helpers/all';

.login-wrapper {
  padding: 2em;
  max-width: 650px;
  margin: 0 auto;
  display: block;
}

.invoices-wrapper {
  padding: 2em;
  max-width: 100%;
  margin: 50px auto;
  display: block;
}

.register-wrapper {
  padding: 2em;
  margin: 0 auto;
  display: block;

  button {
    float: left;
  }
}

.social-login-failed {
  text-align: center;

  h3 {
    color: #d70016 !important;
  }
}

.loginBtn {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin: 0.2em;
  min-width: 250px;
  width: 100%;
  float: left;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  color: #fff !important;
  cursor: pointer;
  text-shadow: none !important;
}

.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn--facebook {
  font-weight: 900;
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--google {
  font-weight: 900;
  background: #dd4b39;
}

.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

.proceed-to-login {
  margin: 15px 0;
}
