.hide,
.hidden {
  display: none;

  &--sm-down,
  &-mobile {
    display: none !important;

    @include media-breakpoint-up(sm) {
      display: block !important;
    }
  }

  &--md-down {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &--md-up {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--lg-up {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.show {
  &-mobile {
    display: block !important;

    @include media-breakpoint-up(sm) {
      display: none !important;
    }
  }
}
.bg {
  &--primary {
    background-color: $color--primary;
  }
}
.text {
  &--primary,
  &--blue {
    color: $color--primary;
  }

  &--secondary,
  &--green {
    color: $color--secondary;
  }

  &--yellow {
    color: $color--yellow;
  }

  &--orange {
    color: $color--orange;
  }

  &--red {
    color: $color--warn;
  }

  &--grey {
    color: $color--grey--teaserborder;
  }

  &--grey-darker {
    color: $color--grey--darker;
  }

  &--white {
    color: $color--white;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  &--explain {
    font-style: italic;
    font-size: 0.75em;
    line-height: 1.2em;
  }

  &--bold {
    font-weight: bold;
  }

  &--normal {
    font-family: $font-family--normal;
  }

  &--underline {
    text-decoration: underline;
  }
}

.bold {
  &--primary,
  &--blue {
    b {
      color: $color--primary;
    }
  }

  &--secondary,
  &--green {
    b {
      color: $color--secondary;
    }
  }
}

.indent {
  &--tile {
    padding-left: $tile-spacing--vertical;

    @include media-breakpoint-up(md) {
      padding-left: $tile-spacing--vertical--desk;
    }
  }

  &--gutter {
    padding-left: math.div($grid-gutter-width, 2);
  }
}

.mt-gtr-neg {
  margin-top: math.div($grid-gutter-width-negative, 2);
}

.pt-0 {
  padding-top: 0;
}

.pt-0-25 {
  padding-top: 0.25em;
}

.pt-0-5 {
  padding-top: 0.5em;
}

.pt-1 {
  padding-top: 1em;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0-25 {
  padding-bottom: 0.25em;
}

.pb-0-5 {
  padding-bottom: 0.5em;
}

.pb-1 {
  padding-bottom: 1em;
}

.md-pb-0 {
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
}
.md-pb-1 {
  @include media-breakpoint-up(md) {
    padding-bottom: 1em;
  }
}

.pl-0 {
  padding-left: 0;
}

.pl-0-25 {
  padding-left: 0.25em;
}

.pl-0-5 {
  padding-left: 0.5em;
}

.pl-1 {
  padding-left: 1em;
}

.px-4 {
  padding-left: 1em;
}

.gap {
  &-r-0-25 {
    row-gap: 0.25em;
  }
  &-r-0-5 {
    row-gap: 0.5em;
  }
  &-r-1 {
    row-gap: 1em;
  }
  &-c-0-25 {
    column-gap: 0.25em;
  }
  &-c-0-5 {
    column-gap: 0.5em;
  }
  &-c-1 {
    column-gap: 1em;
  }
}

.flex {
  display: flex;

  &--vc {
    align-items: center;
  }

  &--vs {
    align-items: flex-start;
  }

  &--hc {
    justify-content: center;
  }

  &--c {
    justify-content: center;
    align-items: center;
  }

  &--sb {
    justify-content: space-between;
  }
}

.w {
  &-full {
    width: 100%;
  }
}

.grow {
  &--1 {
    flex-grow: 1;
  }
}

.inline-block {
  display: inline-block;
}

.show-print {
  display: none;
}

.relative {
  position: relative;
}

.sr-only,
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

[class*='trigger'] {
  cursor: pointer;
}

.first-letter {
  &--caps::first-letter {
    text-transform: capitalize;
  }
}

@media print {
  .hide-print {
    display: none !important;
    padding: 0 !important;
  }

  .show-print {
    display: block !important;
  }
}

%starAnimation {
  @include visible(false);

  transform: rotate(45deg) translate3d(10px, 0, 0) scale(0.75);
  transform-origin: 50% 100%;
  animation: starRotateIn 0.25s linear 0.25s 1 forwards;
}

@for $i from 0 through 20 {
  .delay-index-#{$i} {
    animation-delay: (0.12 * $i + 0.1) * 1s;
  }
}

@for $i from 0 through 20 {
  .delay-index-#{$i} {
    .IconStar {
      @for $stars from 1 through 5 {
        &:nth-child(#{$stars}) {
          animation-delay: ((0.1 * $stars + 0.1)+ ($i * 0.3)) * 1s;
        }
      }
    }
  }
}

@for $i from 0 through 20 {
  .card-review:nth-child(#{$i}) {
    .IconStar {
      @for $stars from 1 through 5 {
        &:nth-child(#{$stars}) {
          animation-delay: ((0.1 * $stars + 0.1)+ (($i - 1) * 0.3)) * 1s;
        }
      }
    }
  }
}

@keyframes starRotateIn {
  0% {
    visibility: visible;
    opacity: 0;
    transform: rotate(100deg) translate3d(30px, 0, 0) scale(0.75);
  }

  50% {
    visibility: visible;
    opacity: 1;
    transform: rotate(25deg) translate3d(5px, 0, 0) scale(1.25);
  }

  100% {
    visibility: visible;
    opacity: 1;
    transform: rotate(0) translate3d(0, 0, 0) scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 0.5em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInGrey {
  0% {
    opacity: 0;
    filter: grayscale(100%);
  }

  25% {
    opacity: 1;
    filter: grayscale(100%);
  }

  100% {
    opacity: 1;
    filter: grayscale(0%);
  }
}

@keyframes spinIn {
  0% {
    transform: rotate(0deg) scale(0) translate3d(-100px, 100px, 0);
  }

  75% {
    transform: rotate(360deg) scale(1.2) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(720deg) scale(1) translate3d(0, 0, 0);
  }
}
