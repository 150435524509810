@import '../../brenger-shared-ui/assets/scss/helpers/all';

$search-height: 73px;

.ps-config {
  position: absolute;
  inset: 0;
  bottom: $search-height;
  &--new {
    padding-top: $search-height;
    bottom: 0;
    @include media-breakpoint-desk-and-up() {
      margin-top: $search-height;
    }
  }

  &--no-result {
    inset: 0;
    top: $search-height;
    @include media-breakpoint-desk-and-up() {
      .ps-config--bg {
        margin-top: $search-height;
      }
    }
  }

  &--bg {
    height: 100%;
    background-color: #fff;
    border-radius: 0 0 $border-radius--tile $border-radius--tile;
  }

  &--manual-head {
    color: $color--grey--darker;
    position: absolute;
    top: 0;
    left: 53px;
    right: 0;
    text-align: center;
    line-height: calc($search-height - 6px);
    background-color: #fff;
    @include media-breakpoint-desk-and-up() {
      display: none;
    }
  }

  &--content {
    @include media-breakpoint-up(xl) {
      width: 75%;
    }
  }

  &--dimensions {
    display: flex;
    gap: 0.5em;
    &-input {
      width: 100%;
      padding-right: 3em;
      font-family: $font-family--normal;

      &-suffix {
        position: absolute;
        right: 0.7em;
        top: 50%;
        margin-top: -0.9em;
      }
    }
    &-header {
      margin-bottom: 1rem;
    }
  }

  &--count {
    padding: 14px;
    border: 1px solid $color--grey--teaserborder;
    width: 100%;
    border-radius: $border-radius--tile;

    &-value {
      margin-left: auto;
      gap: 0.5em;
    }

    &--number {
      width: 3rem;
      text-align: center;
      margin-bottom: 0.05em;
    }

    .btn {
      background: none;
      padding: 0;
      margin: 0;
      color: $color--primary;
      font-size: 1.3em;
      line-height: 0.66em;
      &[disabled] {
        color: #aacdfb;
      }
      &.ps-config--count--decrease {
        svg {
          width: 1.2rem;
          height: 0.2rem;
        }
      }
      &.ps-config--count--increase {
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }

  &--image-upload {
    padding: 25px;
    border-radius: $border-radius--tile;
    border: 1px dashed $color--primary;
    gap: 25px;
    &.required {
      border-style: solid;
    }
    &.showError {
      border-color: $color--error;
      background-color: $color--warn--lighter;
    }
    .IconCamera {
      width: 1.5em;
      height: 1.5em;
      margin-bottom: 0.2em;

      circle,
      path {
        fill: $color--primary;
      }
    }
  }
  &--image-display {
    position: relative;
    img {
      object-fit: contain;
      width: 100%;
      max-height: 250px;
      background-color: $color--grey--light;
    }
    &--remove {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: #fff;
      line-height: 1;
      padding: 0.25em;
      border-radius: 5px;
      z-index: 1;
      .IconCross {
        width: 1em;
        height: 0.75em;
      }
    }
  }

  &--choice {
    gap: 1em;
    input {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }

  &--chips-choice {
    display: inline-block;
    margin: 0.5em 0.25em 0 0;
    input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    label {
      font-weight: bold;
      display: inline-block;
      color: $color--primary;
      border: 1px solid $color--primary;
      border-radius: 100px;
      padding: 0 0.7em;
      line-height: 1.95em;
      white-space: nowrap;
    }
    input:checked + label {
      color: #fff;
      background-color: $color--primary;
    }
    input[type='checkbox'] + label {
      &:before {
        content: '';
        display: inline-block;
        width: 0.8em;
        height: 0.4em;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transition: all 0.25s;
        opacity: 0;
        visibility: hidden;
        margin: 0 0 0 -0.7em;
        transform: rotate(0deg) translate3d(10px, 0, 0);
      }
    }
    input[type='checkbox']:checked + label {
      &:before {
        opacity: 1;
        visibility: visible;
        margin: 0 0.1em 0 0.3em;
        transform: rotate(-45deg) translate3d(0px, -5px, 0);
      }
    }
  }
}
