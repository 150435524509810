@import '../../../assets/scss/helpers/all';

.transport-request-flow #fc_frame {
  display: block;

  @include visible(false);

  transition: all $default-transition-speed;
  transform: translate3d(6px, -56px, 0);

  &.fc-open {
    max-height: calc(100vh - 80px) !important;
  }

  @include media-breakpoint-up('sm') {
    transform: translate3d(6px, 30px, 0);

    .chat--is-visible & {
      transform: translate3d(6px, 0, 0);
    }
  }
}

#fc_frame {
  @include visible(false);
}

.chat--is-visible #fc_frame {
  @include visible(true);

  transform: translate3d(6px, -86px, 0);
}
