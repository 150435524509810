@import '../../brenger-shared-ui/assets/scss/helpers/all';

.modal--backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(34, 59, 88, 0.2);
}

.ps-modal {
  &--content {
    position: fixed;
    inset: 0;
    background-color: #fff;
    text-align: left;
    overflow: auto;
    @include media-breakpoint-desk-and-up() {
      border-radius: $border-radius--tile;
      position: relative;
      margin: 20px auto 0 auto;
      height: calc(100vh - 20px);
    }
  }
  &--prompt.ps-modal--content {
    margin: 0;
    position: relative;
    top: 25vh;
    height: auto;
    background-color: transparent;
    transform: translate3d(0, -50%, 0);
    padding: 2.4rem;
  }

  &--prompt--inner {
    border-radius: $border-radius--tile;
    background-color: #fff;
    overflow: hidden;
    padding-bottom: 16px;
  }

  &--back {
    padding: 0;
    margin-right: 0.3em;
  }

  &--section {
    padding: 16px 24px 0 24px;
    &:last-child {
      padding-bottom: 16px;
    }
    @include media-breakpoint-desk-and-up() {
      padding: 20px 28px 0 28px;
      &:last-child {
        padding-bottom: 20px;
      }
    }
    &.bordered {
      border-bottom: 1px solid $color--grey--teaserborder;
    }
    &.bp {
      padding-bottom: 16px;
      @include media-breakpoint-desk-and-up() {
        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
}
