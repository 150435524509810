@import '../../assets/scss/helpers/all';

.input-default {
  padding: 1.2rem 1.6rem;
  color: $color--black;
  font-size: 1.8rem;
  font-family: inherit;
  width: 100%;
  border: 1px solid $color--grey--teaserborder;
  .input-loading & {
    padding-right: 2rem;
  }
  &::placeholder {
    color: $color--grey--darker;
  }
}

.input--error {
  font-size: 0.75em;
  color: $color--error;
}

.input--warning {
  font-size: 0.75em;
  color: $color--orange;
}

select.input-default {
  background-color: transparent;
}

.input-loading {
  position: relative;
  .loader {
    position: absolute;
    top: 1.1rem;
    right: 0.6rem;
  }
}
