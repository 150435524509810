@import '../../../assets/scss/helpers/all';

.pagination {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 80%;

  &--item {
    background: $color--grey--light;
    border: 1px solid $color--grey--light;
    border-radius: $border-radius--default;
    cursor: pointer;
    padding: 0.8em 1.2em;
    margin: 0.25em;

    &:hover {
      background: $color--primary;
      color: white;
    }

    &--active {
      pointer-events: none;
      cursor: default;
      background: $color--primary;
      color: white;
    }
  }
}
