@use 'sass:math';

// Import bootstrap vars
$grid-gutter-width: 30px;

@import '../../../../../node_modules/bootstrap/scss/variables';

// Fonts
$font-family--normal: 'bariol', Arial, sans-serif;

// Body
$font-size--body: 1.8rem;
$line-height--body: 2.8rem;
$font-size--body-space: $line-height--body;
$font-size--body--small: 1.6rem;
$line-height--body--small: 2.6rem;
$font-size--body-space--small: $line-height--body--small;

// Lead
$font-size--lead: 2.5rem;
$line-height--lead: 4rem;
$font-size--lead-space: 4rem;
$font-size--lead--small: $font-size--body;
$line-height--lead--small: $line-height--body;
$font-size--lead-space--small: 3rem;

// Headers
$font-size--h5: 2.2rem;
$line-height--h5: 3rem;
$font-size--h5-space: 1.8rem;
$font-size--h5--small: 1.8rem;
$line-height--h5--small: 2.2rem;
$font-size--h5-space--small: 1rem;

$font-size--h4: 2.5rem;
$line-height--h4: 3rem;
$font-size--h4-space: 2rem;
$font-size--h4--small: 1.8rem;
$line-height--h4--small: 2.2rem;
$font-size--h4-space--small: 1rem;

$font-size--h3: 3rem;
$line-height--h3: 3.5rem;
$font-size--h3-space: 2rem;
$font-size--h3--small: 2rem;
$line-height--h3--small: 2.5rem;
$font-size--h3-space--small: 1.5rem;

$font-size--h2: 4.5rem;
$line-height--h2: 5.5rem;
$font-size--h2-space: 3rem;
$font-size--h2--small: 2.2rem;
$line-height--h2--small: 3.2rem;
$font-size--h2-space--small: 1.5rem;

$font-size--h1: 5.5rem;
$line-height--h1: 5.5rem;
$font-size--h1-space: 3rem;
$font-size--h1--small: 2.5rem;
$line-height--h1--small: 3rem;
$font-size--h1-space--small: 2rem;

// Colors
$color--primary: #0073fa;
$color--primary--hover: #005fce;
$color--secondary: #00d896;
$color--secondary--hover: #00bd84;
$color--secondary--light: rgba(0, 216, 150, 0.33);

$color--lime: #00eba0;

$color--primary--light: #ecf5ff;

$color--black: #223b58; //#001b3b;
$color--black--trans: rgba(0, 0, 0, 0.66);
$color--black--dark: #001b3b;
$color--black--darker: #13191c;
$color--black--light: #263238;
$color--blue--light: #f2f8ff;
$color--blue: #a2cdff;
$color--green: #889093;
$color--grey--second: #889093;
$color--grey: #5f6e81;
$color--grey--darker: #7c90a8;
$color--grey--light: #e2eaf2;
$color--grey--lighter: #f7f9fa;
$color--grey--teaserborder: #aebfd4;
$color--white: #fff;
$color--white--hover: #cde4ff;
$color--white--darker: #faf9f7;
$color-blue--bright: #338ffb;
$color-blue--light: #80b9fc;
$color--warn: #e10000;
$color--error: #e10000;
$color--warn--lighter: #fff9f9;
$color--checked: #6fe100;
$color--yellow: #ffc63c;
$color--orange: #f59023;
$color--heading: inherit;

// Spacing and Sizes
$border-radius--default: 8px;
$border-radius--tile: 10px;
$border-radius--forms-checkbox: 3px;
$contentWidthLarge: 1500px;
$contentWidthSmall: 760px;
$input-spacing-top: 0.568em;
$input-spacing: $input-spacing-top math.div($grid-gutter-width, 2);
$input-spacing-top--small: 0.532em;
$input-spacing--small: $input-spacing-top--small math.div($grid-gutter-width, 2);
$tile-spacing--horizontal: 18px;
$tile-spacing--vertical: 20px;
$tile-spacing--vertical--desk: 25px;

//shadows
$box-shadow--primary: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
$box-shadow--primary-inverse: 0 -4px 6px 0 rgba(0, 0, 0, 0.12);
$box-shadow--secondary: 0 3px 8px 0 rgba(0, 0, 0, 0.19);
$box-shadow--form: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
$box-shadow--teaser: 0 8px 10px 0 rgba(0, 0, 0, 0.1);

//Other
$button-spacing: 0.56em math.div($grid-gutter-width, 2);
$default-transition-speed: 0.25s;
$grid-gutter-width-negative: $grid-gutter-width * -1;
$breakpoint-sidebar-view: 'lg';
$sidebar-width: 400 + $grid-gutter-width;

$header-height: 80px;
$progress-header-height: 60px;
$progress-header-height--small: 45px;
$sheet-layout-footer: 90px;
$sheet-layout-footer--small: 65px;
$sheet-layout-sidebar-padding: 50px;
$sheet-layout-sidebar: 100%;
$sheet-layout-sidebar-md: 350px;
$sheet-layout-sidebar-lg: 400px;
$sheet-layout-sidebar-xl: 450px;
$sidebar-xl-bp: 1450px;
$sidebar-xxl-bp: 2000px;
$sheet-layout-container: 695px;
$sheet-layout-container--small: 520px;
$google-maps-single-height: 300px;
$card-border: 1px solid $color--grey--teaserborder;
