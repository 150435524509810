@import '../../../assets/scss/helpers/all';

%defaultSvgStyle {
  display: inline-block;
  width: 1em;
  height: 1em;
}
.IconDoor,
.IconElevator,
.IconCross {
  path {
    fill: $color--primary;
  }
}

.line,
.IconCheckMark {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.line {
  stroke: $color--black;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.IconCheckMark {
  path {
    stroke-width: 1.6;
  }

  &--primary {
    path {
      stroke: $color--primary;
    }
  }

  &--secondary {
    path {
      stroke: $color--secondary;
    }
  }
}

a.with-icon {
  text-decoration: none;
  color: inherit;
}

.with-icon {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    margin-top: 0.4em;
    width: 0.75em;
    height: 0.75em;
  }

  &--right {
    * {
      order: 1;
    }

    svg {
      order: 2;
    }
  }

  &--left {
    * {
      order: 2;
    }

    svg {
      order: 1;
    }
  }
}

.IconOnePerson {
  position: relative;
  left: 0.3em;
}

.IconCheckmarkCircle {
  path {
    fill: $color--white;
  }
}

svg {
  .cls-1,
  .trashColor,
  .PhoneColor,
  .WhatsappColor,
  .MapsColor,
  .smile,
  .MailColor {
    fill: $color--primary;
  }
}

.IconSmile {
  &--down {
    transform: rotate(90deg);

    .btn--icon-right .icon-wrapper &,
    .btn--icon-left .icon-wrapper & {
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }
  }

  &--left {
    transform: rotate(180deg);

    .btn--icon-right .icon-wrapper &,
    .btn--icon-left .icon-wrapper & {
      transform: translate3d(0, -50%, 0) rotate(180deg);
    }
  }

  &--up {
    transform: rotate(270deg);

    .btn--icon-right .icon-wrapper &,
    .btn--icon-left .icon-wrapper & {
      transform: translate3d(0, -50%, 0) rotate(270deg);
    }
  }
}

.IconQuestionBubble {
  .bubble,
  .question-mark {
    fill: $color--primary;
  }
}

.IconArrow {
  @extend %defaultSvgStyle;

  .arrow-shape {
    fill: $color--white;
  }

  &--left {
    transform: scale(-1, 1);

    .btn--icon-left & {
      transform: scale(-1, 1) translate3d(0, -50%, 0);
    }
  }

  &--style-primary {
    .arrow-shape {
      fill: $color--primary;
    }
  }

  &--style-black {
    .arrow-shape {
      fill: $color--black;
    }
  }
}

.IconTrash {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1.2em;
  margin: 0 0 -0.15em 0.2em;

  &--lid,
  &--can {
    position: absolute;
    left: 0;
    top: 50%;
  }

  &--lid {
    height: 0.35em;
    width: 1em;
    margin-top: -0.5em;
    transition: 0.2s all ease;
  }

  &--can {
    margin-top: -0.2em;
    height: 0.8em;
    width: 1em;
  }

  .enter-done & {
    @include custom-breakpoint-up(1024px) {
      &--lid {
        transform: translate3d(0, 0, 0);
      }

      &:hover {
        .IconTrash--lid {
          transform: translate3d(0, -0.2em, 0);
        }
      }
    }
  }

  .exit-active & {
    .IconTrash--lid {
      transform-origin: 50% 0;
      animation: lidAnimate 0.75s ease-in-out 0.1s 1 forwards;
    }
  }

  @keyframes lidAnimate {
    0% {
      transform: rotate(0deg) translate3d(0, 0, 0);
    }

    33% {
      transform: rotate(0deg) translate3d(0, -0.3em, 0);
    }

    66% {
      transform: rotate(360deg) translate3d(0, -0.3em, 0);
    }

    75% {
      transform: rotate(360deg) translate3d(0, -0.3em, 0);
    }

    100% {
      transform: rotate(360deg) translate3d(0, 0, 0);
    }
  }
}

.IconMarktplaats {
  .aast0,
  .aast1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #eda566;
  }

  .aast1 {
    fill: #2d3c4d;
  }
}

.Icon2dehands {
  .aacls-1 {
    fill: #fb0;
  }
}
