@import '../../../assets/scss/helpers/all';

.input-el--switch {
  .input-el--switch--label {
    display: block;
    padding: 0.2em 0;
    line-height: 1;
    width: 100%;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-desk-and-up() {
      &:hover {
        background: $color--blue--light;
      }
    }
  }

  input {
    display: none;

    &:checked {
      & + label {
        background: $color--primary;
        color: $color--white;
        pointer-events: none;
      }
    }
  }

  &-options {
    display: flex;
    width: 100%;
    border: 1px solid $color--primary;
    border-radius: $border-radius--default;
    color: $color--primary;
    font-weight: bold;
    overflow: hidden;

    & > div {
      width: 100%;
    }
  }
}
