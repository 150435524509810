@use 'sass:math';

@import '../../../assets/scss/helpers/all';

$card-image-spacing: 1.15em;

.card-with-image {
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
  animation: fadeInGrey 1s 1 forwards ease-in-out;
  padding: $card-image-spacing;

  &--header {
    font-weight: bold;
    font-size: 1.5em;
    word-break: break-word;
    hyphens: auto;
  }

  &--content {
    width: calc(66% - #{math.div($grid-gutter-width, 2)});
    height: 100%;
    opacity: 0;
    animation: fadeInUp 0.25s 0.1s 1 forwards ease-in-out;

    p {
      margin-bottom: 0.2em;
    }
  }

  &.is-selected {
    color: $color--primary;
    border-color: $color--primary;
  }

  &--media-wrap {
    position: absolute;
    right: 0;
    top: -10px;
    left: 66%;
    height: calc(100% + 20px);
    background-size: cover;
    background-position: center;

    .price-display {
      color: $color--white;
      font-size: 0.7em;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, math.div($grid-gutter-width-negative, 2), 0);
    }
  }

  .input-el--file {
    margin-bottom: 0;
  }

  .input-el--file-compact {
    max-width: 100%;
  }

  &.trigger {
    &:after {
      content: '';
      position: absolute;
      top: $card-image-spacing;
      right: $card-image-spacing;
      display: block;
      z-index: 50;
      background: url(../../../assets/images/smiley_right_white.svg) center center / 7px no-repeat;
      width: 7px;
      height: 14px;
    }
  }
}
