@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.btn {
  @extend %button;

  &:focus {
    outline: none;
  }

  &:empty {
    display: none;
  }

  &.btn--primary,
  &.btn--secondary {
    .nav-item--label {
      color: $color--white;
    }
  }

  &.btn--no-bg {
    background: none;
  }

  &--primary {
    color: $color--white;
    background: $color--primary;
    border-color: $color--primary;

    @include media-breakpoint-up(md) {
      &:hover {
        background: $color--primary--hover;
        border-color: $color--primary--hover;
      }
    }
  }

  &--secondary {
    color: $color--white;
    background: $color--secondary;
    border-color: $color--secondary;

    @include media-breakpoint-up(md) {
      &:hover {
        background: $color--secondary--hover;
        border-color: $color--secondary--hover;
      }
    }
  }

  &--tertiary {
    background: none;
    border-color: transparent;
    color: $color--primary;
    padding-left: 0.5em;
    padding-right: 0.5em;

    @include media-breakpoint-up(lg) {
      &:hover {
        color: $color--primary--hover;
      }
    }
  }

  &--outline {
    background: $color--white;
    border-color: $color--primary;
    color: $color--primary;

    @include media-breakpoint-up(md) {
      &:hover {
        background: $color--primary--hover;
        color: $color--white;
      }
    }
  }

  &--delete {
    background: $color--warn;
    border-color: $color--warn;
    color: $color--white;

    @include media-breakpoint-up(md) {
      &:hover {
        background: $color--warn--lighter;
        color: $color--warn;
      }
    }
  }

  &--grey {
    background: transparent;
    border-color: transparent;
    color: $color--grey--darker;

    @include media-breakpoint-up(md) {
      &:hover {
        background: transparent;
        color: $color--grey;
      }
    }
  }

  &--loading {
    cursor: wait;
  }

  &--size {
    &-sm {
      font-size: 0.75em;
      padding-top: 0.4em;
      padding-bottom: 0.4em;
      line-height: 1;
      border-radius: $border-radius--default * 0.6;
    }

    &-xl {
      font-size: 1.5em;
      padding: 0.6em 1em;
    }
  }

  &.btn--is-disabled,
  &[disabled] {
    background: $color--grey--light;
    border-color: $color--grey--light;
    color: $color--white;
    cursor: not-allowed;
    pointer-events: none;

    .IconSmile {
      fill: $color--white;
    }
  }

  .smile {
    fill: $color--white;
  }

  &--tertiary,
  &--outline {
    .smile {
      fill: $color--primary;
    }
  }

  & > svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: -0.55em 0 0.05em 0.7em;

    &.IconSmile {
      margin: -0.05em 0 -0.1em 0.1em;
    }
  }

  &--transparant {
    background-color: transparent;
    border: none;
    & > svg {
      width: initial;
      height: initial;
      margin: auto;
    }
  }

  &--icon-right,
  &--icon-left {
    position: relative;

    svg {
      margin: 0;
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);

      &.spinning-smile {
        margin: -0.6em 0 0 0;
      }
    }
  }

  &--icon-left {
    padding-left: 2.5em;

    svg {
      left: 0.6em;
    }

    &.btn--tertiary {
      padding-left: 1.9em;

      svg {
        left: 0;
      }
    }
  }

  &--icon-right {
    padding-right: 2.5em;

    svg {
      right: 0.6em;
    }
  }

  &--full-width {
    width: 100%;
    margin: math.div($grid-gutter-width, 2) 0;
  }

  @media print {
    display: none !important;
  }

  &.no-margin {
    margin: 0;
  }
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  clear: both;
  margin: 0 -0.5em;
  align-items: center;

  &--centered {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--left {
    justify-content: flex-start;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    margin: 0;
  }

  .btn {
    margin: 0.5em;

    @include media-breakpoint-up(md) {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.btn-bar {
  border-radius: $border-radius--default;
  border: 1px solid $color--grey--teaserborder;
  box-shadow: $box-shadow--teaser;
  padding: 0 math.div($grid-gutter-width, 2);
}
