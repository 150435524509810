.IconArrow {
  width: 1em;
  height: 1em;
  display: inline-block;
}

.IconDoor path, .IconElevator path, .IconCross path {
  fill: #0073fa;
}

.line, .IconCheckMark {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.line {
  stroke: #223b58;
  stroke-width: 4px;
  stroke-miterlimit: 10;
}

.IconCheckMark path {
  stroke-width: 1.6px;
}

.IconCheckMark--primary path {
  stroke: #0073fa;
}

.IconCheckMark--secondary path {
  stroke: #00d896;
}

a.with-icon {
  color: inherit;
  text-decoration: none;
}

.with-icon {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
}

.with-icon svg {
  width: .75em;
  height: .75em;
  margin-top: .4em;
}

.with-icon--right * {
  order: 1;
}

.with-icon--right svg, .with-icon--left * {
  order: 2;
}

.with-icon--left svg {
  order: 1;
}

.IconOnePerson {
  position: relative;
  left: .3em;
}

.IconCheckmarkCircle path {
  fill: #fff;
}

svg .cls-1, svg .trashColor, svg .PhoneColor, svg .WhatsappColor, svg .MapsColor, svg .smile, svg .MailColor {
  fill: #0073fa;
}

.IconSmile--down {
  transform: rotate(90deg);
}

.btn--icon-right .icon-wrapper .IconSmile--down, .btn--icon-left .icon-wrapper .IconSmile--down {
  transform: translate3d(0, -50%, 0)rotate(90deg);
}

.IconSmile--left {
  transform: rotate(180deg);
}

.btn--icon-right .icon-wrapper .IconSmile--left, .btn--icon-left .icon-wrapper .IconSmile--left {
  transform: translate3d(0, -50%, 0)rotate(180deg);
}

.IconSmile--up {
  transform: rotate(270deg);
}

.btn--icon-right .icon-wrapper .IconSmile--up, .btn--icon-left .icon-wrapper .IconSmile--up {
  transform: translate3d(0, -50%, 0)rotate(270deg);
}

.IconQuestionBubble .bubble, .IconQuestionBubble .question-mark {
  fill: #0073fa;
}

.IconArrow .arrow-shape {
  fill: #fff;
}

.IconArrow--left {
  transform: scale(-1, 1);
}

.btn--icon-left .IconArrow--left {
  transform: scale(-1, 1)translate3d(0, -50%, 0);
}

.IconArrow--style-primary .arrow-shape {
  fill: #0073fa;
}

.IconArrow--style-black .arrow-shape {
  fill: #223b58;
}

.IconTrash {
  width: 1em;
  height: 1.2em;
  margin: 0 0 -.15em .2em;
  display: inline-block;
  position: relative;
}

.IconTrash--lid, .IconTrash--can {
  position: absolute;
  top: 50%;
  left: 0;
}

.IconTrash--lid {
  width: 1em;
  height: .35em;
  margin-top: -.5em;
  transition: all .2s;
}

.IconTrash--can {
  width: 1em;
  height: .8em;
  margin-top: -.2em;
}

@media (min-width: 1024px) {
  .enter-done .IconTrash--lid {
    transform: translate3d(0, 0, 0);
  }

  .enter-done .IconTrash:hover .IconTrash--lid {
    transform: translate3d(0, -.2em, 0);
  }
}

.exit-active .IconTrash .IconTrash--lid {
  transform-origin: 50% 0;
  animation: .75s ease-in-out .1s forwards lidAnimate;
}

@keyframes lidAnimate {
  0% {
    transform: rotate(0)translate3d(0, 0, 0);
  }

  33% {
    transform: rotate(0)translate3d(0, -.3em, 0);
  }

  66% {
    transform: rotate(360deg)translate3d(0, -.3em, 0);
  }

  75% {
    transform: rotate(360deg)translate3d(0, -.3em, 0);
  }

  100% {
    transform: rotate(360deg)translate3d(0, 0, 0);
  }
}

.IconMarktplaats .aast0, .IconMarktplaats .aast1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #eda566;
}

.IconMarktplaats .aast1 {
  fill: #2d3c4d;
}

.Icon2dehands .aacls-1 {
  fill: #fb0;
}

.btn {
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-top: .3em;
  padding: .45em 15px;
  text-decoration: none;
  display: inline-block;
}

@media (min-width: 768px) {
  .btn {
    padding: .568em 15px;
  }

  .btn:hover {
    text-decoration: none;
  }
}

.btn:focus {
  outline: none;
}

.btn:empty {
  display: none;
}

.btn.btn--primary .nav-item--label, .btn.btn--secondary .nav-item--label {
  color: #fff;
}

.btn.btn--no-bg {
  background: none;
}

.btn--primary {
  color: #fff;
  background: #0073fa;
  border-color: #0073fa;
}

@media (min-width: 768px) {
  .btn--primary:hover {
    background: #005fce;
    border-color: #005fce;
  }
}

.btn--secondary {
  color: #fff;
  background: #00d896;
  border-color: #00d896;
}

@media (min-width: 768px) {
  .btn--secondary:hover {
    background: #00bd84;
    border-color: #00bd84;
  }
}

.btn--tertiary {
  color: #0073fa;
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: .5em;
  padding-right: .5em;
}

@media (min-width: 992px) {
  .btn--tertiary:hover {
    color: #005fce;
  }
}

.btn--outline {
  color: #0073fa;
  background: #fff;
  border-color: #0073fa;
}

@media (min-width: 768px) {
  .btn--outline:hover {
    color: #fff;
    background: #005fce;
  }
}

.btn--delete {
  color: #fff;
  background: #e10000;
  border-color: #e10000;
}

@media (min-width: 768px) {
  .btn--delete:hover {
    color: #e10000;
    background: #fff9f9;
  }
}

.btn--grey {
  color: #7c90a8;
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

@media (min-width: 768px) {
  .btn--grey:hover {
    color: #5f6e81;
    background: none;
  }
}

.btn--loading {
  cursor: wait;
}

.btn--size-sm {
  border-radius: 4.8px;
  padding-top: .4em;
  padding-bottom: .4em;
  font-size: .75em;
  line-height: 1;
}

.btn--size-xl {
  padding: .6em 1em;
  font-size: 1.5em;
}

.btn.btn--is-disabled, .btn[disabled] {
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
  background: #e2eaf2;
  border-color: #e2eaf2;
}

.btn.btn--is-disabled .IconSmile, .btn[disabled] .IconSmile, .btn .smile {
  fill: #fff;
}

.btn--tertiary .smile, .btn--outline .smile {
  fill: #0073fa;
}

.btn > svg {
  width: 1em;
  height: 1em;
  margin: -.55em 0 .05em .7em;
  display: inline-block;
}

.btn > svg.IconSmile {
  margin: -.05em 0 -.1em .1em;
}

.btn--transparant {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.btn--transparant > svg {
  width: initial;
  height: initial;
  margin: auto;
}

.btn--icon-right, .btn--icon-left {
  position: relative;
}

.btn--icon-right svg, .btn--icon-left svg {
  width: 1.2em;
  height: 1.2em;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.btn--icon-right svg.spinning-smile, .btn--icon-left svg.spinning-smile {
  margin: -.6em 0 0;
}

.btn--icon-left {
  padding-left: 2.5em;
}

.btn--icon-left svg {
  left: .6em;
}

.btn--icon-left.btn--tertiary {
  padding-left: 1.9em;
}

.btn--icon-left.btn--tertiary svg {
  left: 0;
}

.btn--icon-right {
  padding-right: 2.5em;
}

.btn--icon-right svg {
  right: .6em;
}

.btn--full-width {
  width: 100%;
  margin: 15px 0;
}

@media print {
  .btn {
    display: none !important;
  }
}

.btn.no-margin {
  margin: 0;
}

.btn-wrapper {
  clear: both;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 0 -.5em;
  display: flex;
}

.btn-wrapper--centered {
  justify-content: center;
}

.btn-wrapper--right {
  justify-content: flex-end;
}

.btn-wrapper--left {
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .btn-wrapper {
    width: 100%;
    margin: 0;
  }
}

.btn-wrapper .btn {
  margin: .5em;
}

@media (min-width: 768px) {
  .btn-wrapper .btn:first-child {
    margin-left: 0;
  }

  .btn-wrapper .btn:last-child {
    margin-right: 0;
  }
}

.btn-bar {
  border: 1px solid #aebfd4;
  border-radius: 8px;
  padding: 0 15px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}

.flag-icon-wrapper .flag-icon-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.flag-icon-wrapper .flag-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1em;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.flag-icon-wrapper .flag-icon:before {
  content: " ";
}

.flag-icon-wrapper .flag-icon-at {
  background-image: url("at.79d35377.svg");
}

.flag-icon-wrapper .flag-icon-be {
  background-image: url("be.3200729c.svg");
}

.flag-icon-wrapper .flag-icon-ch {
  background-image: url("ch.a96605d2.svg");
}

.flag-icon-wrapper .flag-icon-de {
  background-image: url("de.a19856dd.svg");
}

.flag-icon-wrapper .flag-icon-dk {
  background-image: url("dk.0915cea0.svg");
}

.flag-icon-wrapper .flag-icon-es {
  background-image: url("es.ae19ae8b.svg");
}

.flag-icon-wrapper .flag-icon-fr {
  background-image: url("fr.33c30906.svg");
}

.flag-icon-wrapper .flag-icon-gb {
  background-image: url("gb.cd98deb3.svg");
}

.flag-icon-wrapper .flag-icon-ie {
  background-image: url("ie.d195b55f.svg");
}

.flag-icon-wrapper .flag-icon-it {
  background-image: url("it.b78aa9e8.svg");
}

.flag-icon-wrapper .flag-icon-nl {
  background-image: url("nl.43ff13ad.svg");
}

.flag-icon-wrapper .flag-icon-se {
  background-image: url("se.e9ca82e4.svg");
}

h5, h4, h3, h2, h1 {
  color: inherit;
  margin: 0;
  font-weight: bold;
}

h5 a, h4 a, h3 a, h2 a, h1 a, h5 a:hover, h4 a:hover, h3 a:hover, h2 a:hover, h1 a:hover {
  text-decoration: none;
}

h5.no-margin, h4.no-margin, h3.no-margin, h2.no-margin, h1.no-margin {
  margin: 0 !important;
}

h1 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  line-height: 3rem;
}

@media (min-width: 768px) {
  h1 {
    margin-bottom: 3rem;
    font-size: 5.5rem;
    line-height: 5.5rem;
  }
}

h2 {
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

@media (min-width: 768px) {
  h2 {
    margin-bottom: 3rem;
    font-size: 4.5rem;
    line-height: 5.5rem;
  }
}

h3 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

@media (min-width: 768px) {
  h3 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

h4 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@media (min-width: 768px) {
  h4 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

h5 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@media (min-width: 768px) {
  h5 {
    margin-bottom: 1.8rem;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

.status-label {
  color: #7c90a8;
  background: #fff;
  border: 1px solid #e2eaf2;
  border-radius: 5em;
  margin-right: .5em;
  padding: .3em .7em;
  font-size: .8em;
  line-height: 1;
  display: inline-block;
}

.status-label:last-child {
  margin-left: 0;
}

.status-label--caps {
  text-transform: uppercase;
}

.status-label--blue {
  color: #fff;
  background: #0073fa;
  border-color: #0073fa;
  font-weight: bold;
}

.status-label--blue-light {
  color: #fff;
  background: #338ffb;
  border-color: #338ffb;
  font-weight: bold;
}

.status-label--green {
  color: #fff;
  background: #00d896;
  border-color: #00d896;
  font-weight: bold;
}

.status-label--red {
  color: #fff;
  background: #e10000;
  border-color: #e10000;
  font-weight: bold;
}

.status-label--black-outline {
  color: #223b58;
  background: #fff;
  border-color: #223b58;
  font-weight: bold;
}

.input-el--feedback {
  margin: .2em 0 0 15px;
  font-size: .75em;
  line-height: 1.2em;
}

.input-el--radio .input-el--feedback {
  margin-left: 2.6em;
}

.input-el--radio--inline .input-el--feedback, .input-el--file .input-el--feedback, .input-el--date--inline .input-el--feedback {
  margin-left: 0;
}

.input-el--feedback--error {
  color: #e10000;
}

.input-el--feedback--success {
  color: #00bd84;
}

.input-el--feedback--warning {
  color: #f59023;
}

.input-el select, .input-el textarea, .input-el input {
  color: #223b58;
  font-family: bariol, Arial, sans-serif;
  line-height: inherit;
  background: #fff;
  width: 100%;
  padding: 12px 15px 2.112px;
  font-size: 1.6rem;
  display: block;
}

@media (min-width: 768px) {
  .input-el select, .input-el textarea, .input-el input {
    padding: 14.4px 17px 4.176px;
    font-size: 1.8rem;
  }
}

.input-el select:focus, .input-el textarea:focus, .input-el input:focus {
  outline: none;
}

.input-el select[disabled], .input-el textarea[disabled], .input-el input[disabled] {
  pointer-events: none;
  background: #f2f8ff;
}

.input-el select label, .input-el textarea label, .input-el input label {
  margin: 0;
}

select, textarea, input {
  border: 1px solid #aebfd4;
  border-radius: 8px;
  padding: 15px;
}

.input-el--has-focus select, .input-el--has-focus textarea, .input-el--has-focus input {
  border-color: #0073fa;
  box-shadow: 0 3px 7px rgba(0, 0, 0, .1);
}

.input-el--has-value.input-el--is-valid select, .input-el--has-value.input-el--is-valid textarea, .input-el--has-value.input-el--is-valid input {
  border-color: #00d896;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  font-family: bariol, Arial, sans-serif;
}

.input-el {
  margin-bottom: 15px;
  position: relative;
}

.location-input--wrapper .input-el select, .location-input--wrapper .input-el textarea, .location-input--wrapper .input-el input {
  padding-left: 35px;
}

.input-el--no-label input {
  padding: .432em 15px;
}

.input-el--label {
  color: #5f6e81;
  transform-origin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  max-width: 80%;
  margin: 0;
  font-family: bariol, Arial, sans-serif;
  transition: all .25s;
  position: absolute;
  top: 9px;
  left: 15px;
  overflow: hidden;
  transform: translate3d(0, 0, 0)scale(1);
}

@media (min-width: 768px) {
  .input-el--label {
    top: 10px;
    left: 16px;
  }
}

.input-el--label--required:after {
  content: "*";
  color: #e10000;
  padding-left: .2em;
}

.input-el--has-value .input-el--label, .input-el--has-focus .input-el--label {
  transform: translate3d(1px, -13px, 0)scale(.66);
}

@media (min-width: 768px) {
  .input-el--has-value .input-el--label, .input-el--has-focus .input-el--label {
    top: 1.2rem;
  }
}

.input-el--tag {
  width: 100%;
  position: relative;
}

.input-el--inner {
  display: flex;
  overflow: hidden;
}

.input-el--prepend, .input-el--append {
  text-align: center;
  background-color: #e2eaf2;
  border-radius: 8px;
  justify-items: center;
  padding-top: 8px;
  padding-bottom: 5px;
  display: flex;
}

.input-el--prepend {
  margin-right: -12px;
  padding-left: 5px;
  padding-right: 16px;
}

.input-el--append {
  margin-left: -12px;
  padding-left: 16px;
  padding-right: 5px;
}

.input-el--radio input, .input-el--checkbox input {
  display: none;
}

.input-el--radio label, .input-el--checkbox label {
  white-space: initial;
  color: #223b58;
  cursor: pointer;
  z-index: 1;
  background: none;
  max-width: none;
  padding: 0 0 0 1.5em;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  overflow: visible;
  transform: none !important;
}

.input-el--radio label:before, .input-el--radio label:after, .input-el--checkbox label:before, .input-el--checkbox label:after {
  content: "";
  display: block;
  position: absolute;
}

.input-el--radio label:after, .input-el--checkbox label:after {
  background: #fff;
  border: 1px solid #aebfd4;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 0;
}

.input-el--radio label:before, .input-el--checkbox label:before {
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: transform .25s;
}

.input-el--radio.input-el--has-value label, .input-el--radio.input-el--has-focus label, .input-el--checkbox.input-el--has-value label, .input-el--checkbox.input-el--has-focus label {
  top: auto;
}

.input-el--radio input:checked + label:before, .input-el--checkbox input:checked + label:before {
  opacity: 1;
  visibility: visible;
}

.input-el--radio .input-el--feedback, .input-el--checkbox .input-el--feedback {
  margin: .2em 0 0;
}

.input-el--radio--inline .input-el--option--list, .input-el--checkbox--inline .input-el--option--list {
  display: flex;
}

.input-el--radio--inline .input-el--option--item, .input-el--checkbox--inline .input-el--option--item {
  margin-left: 2em;
}

.input-el--radio--inline .input-el--option--item:first-child, .input-el--checkbox--inline .input-el--option--item:first-child {
  margin-left: 0;
}

.input-el--checkbox label:after {
  border-radius: 3px;
}

.input-el--checkbox label:before {
  border-bottom: 3px solid #00d896;
  border-right: 3px solid #00d896;
  width: 9px;
  height: 15px;
  top: 4px;
  left: 5px;
  transform: rotate(45deg)scale(0, 1);
}

.region-content .input-el--checkbox label:before {
  top: 4px !important;
}

.input-el--checkbox input:checked + label:before {
  transform: rotate(45deg)scale(1);
}

.input-el--radio label:after {
  border-radius: 50%;
}

.input-el--radio label:before {
  background-color: #00d896;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 9px;
  left: 4px;
  transform: scale(.2);
}

.input-el--radio input:checked + label:before {
  transform: scale(1);
}

.input-el--bordered {
  box-shadow: none;
  border: 1px solid #aebfd4;
  border-radius: 8px;
  transition: box-shadow .25s;
}

.input-el--bordered .input-el--label {
  padding: 15px 15px 15px 2.5em !important;
}

.input-el--bordered .input-el--label:before, .input-el--bordered .input-el--label:after {
  margin: .85em 0 0 .7em;
}

.input-el--bordered .input-el--label-title {
  font-size: 1.1em;
  font-weight: bold;
}

.input-el--bordered .input-el--label-content {
  flex-grow: 1;
}

.input-el--bordered .input-el--label-amount {
  white-space: nowrap;
  color: #007bff;
  text-align: right;
  width: 100px;
  padding-left: 15px;
  font-weight: bold;
}

.input-el--bordered .input-el--label-text {
  font-weight: bold;
}

@media (min-width: 768px) {
  .input-el--bordered:hover {
    text-decoration: none;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .12);
  }
}

.input-el--label--with-suffix {
  flex-wrap: wrap;
  display: flex;
}

.input-el--label--with-suffix .input-el--label-text {
  width: calc(100% - 80px);
  font-weight: bold;
}

.input-el--label--with-suffix .input-el--description {
  width: calc(100% - 80px);
}

.input-el--label--with-suffix .input-el--suffix {
  text-align: right;
  width: 80px;
}

.location-input--wrapper {
  position: relative;
}

.location-input--wrapper .IconMarker {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate3d(0, -50%, 0);
}

.input-el--button-select--option {
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-top: .3em;
  padding: .45em 15px;
  text-decoration: none;
  display: inline-block;
}

@media (min-width: 768px) {
  .input-el--button-select--option {
    padding: .568em 15px;
  }

  .input-el--button-select--option:hover {
    text-decoration: none;
  }
}

.input-el--button-select--option {
  opacity: 0;
  color: #0073fa;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: .5em;
  transition: all .25s;
  animation: .33s ease-in-out forwards fadeInUp;
  display: flex;
}

.input-el--button-select--option-selected {
  color: #fff;
  background-color: #00d896;
}

.input-el--button-select--option-icon {
  width: auto;
  height: 1.4em;
  margin-right: .5em;
}

@media (min-width: 992px) {
  .input-el--button-select--option:hover {
    color: #fff;
    background-color: #00bd84;
    text-decoration: none;
  }

  .input-el--button-select--option-selected:hover {
    background-color: #00d896;
  }
}

.input-file-uploads--wrapper {
  margin-bottom: 15px;
}

.input-file-uploads--wrapper input[type="file"] {
  display: none;
}

.input-file-uploads--wrapper .uploaded-file {
  justify-content: space-between;
  align-items: flex-start;
  font-size: .8em;
  display: flex;
}

.input-file-uploads--wrapper .uploaded-file--remove {
  color: #0073fa;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em;
  margin-right: .5em;
  padding-top: .1em;
  display: flex;
}

.input-file-uploads--wrapper .uploaded-file--remove .IconTrash {
  transform: translate3d(0, -.1em, 0)scale(.8);
}

.input-file-uploads--wrapper .usp-list {
  max-width: 400px;
}

.input-file-uploads--wrapper .usp-list--header {
  color: #223b58;
  margin: 0;
  font-size: .8em;
}

.input-file-uploads--wrapper .usp-list--item {
  border-bottom: 1px solid #e2eaf2;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 5px 0;
}

.input-file-uploads--wrapper .usp-list--item:last-child {
  border: none;
}

.input-file-uploads--wrapper .usp-list--text {
  flex-grow: 1;
}

.input-file-uploads--with-preview .IconCamera {
  width: 1.5em;
  height: 1.5em;
  margin: 0 0 -.4em;
}

.input-file-uploads--with-preview .IconCamera circle, .input-file-uploads--with-preview .IconCamera path {
  fill: #0073fa;
}

@media (min-width: 1025px) {
  .input-file-uploads--with-preview .btn--outline:hover .IconCamera circle, .input-file-uploads--with-preview .btn--outline:hover .IconCamera path {
    fill: #fff;
  }
}

.input-el--file-photo-display--image {
  position: relative;
}

.input-el--file-photo-display--image-src {
  cursor: pointer;
  background: #fff center / cover;
  border: 1px solid #00d896;
  border-radius: 8px;
  width: 2.8em;
  height: 2.8em;
  display: inline-block;
  overflow: hidden;
}

.input-el--file-photo-display--image-value {
  word-break: break-all;
  align-items: center;
  font-size: 11px;
  line-height: 1.1;
  display: flex;
  position: absolute;
  inset: 0 0 .7em 6em;
  overflow: hidden;
}

.input-el--file-photo-display--image-value .value-wrap {
  max-width: 100%;
  max-height: 3.4em;
  overflow: hidden;
}

.input-el--file-photo-display--image-delete {
  cursor: pointer;
  text-align: center;
  color: #fff;
  background-color: #0073fa;
  border-radius: 8px;
  width: .9em;
  padding: 0 0 .3em;
  font-size: 1.5em;
  line-height: .6;
  position: absolute;
  top: -.45em;
  left: 1.4em;
}

.check-mark-list--header {
  color: #0073fa;
  margin-bottom: .5em;
  font-size: 1.4em;
  font-weight: bold;
}

.check-mark-list--item {
  margin-bottom: .5em;
  display: flex;
}

.check-mark-list--icon {
  padding-right: 15px;
}

.check-mark-list--icon svg {
  width: 1em;
  height: .9em;
}

.input-el--number-outer {
  flex-wrap: wrap;
  width: 120px;
  margin-bottom: 15px;
  display: inline-flex;
}

.input-el--number-outer input[type="number"] {
  text-align: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.input-el--number-outer .input-el {
  width: 3em;
  margin: 0;
}

.input-el--number-outer .input-el--label {
  width: auto;
  max-width: 130%;
  margin-left: -6px;
}

.input-el--number-outer .input-el--feedback {
  white-space: nowrap;
  margin-left: -33px;
}

.input-el--number-outer .input-el--number--modify {
  color: #fff;
  cursor: pointer;
  background-color: #00d896;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  margin-top: .6em;
  line-height: 1;
  position: relative;
}

.input-el--number-outer .input-el--number--modify:before, .input-el--number-outer .input-el--number--modify:after {
  content: "";
  border: 1px solid;
  position: absolute;
  top: 50%;
  left: 50%;
}

.input-el--number-outer .input-el--number--modify:before {
  height: 8px;
  margin-top: -4px;
  margin-left: -1px;
}

.input-el--number-outer .input-el--number--modify:after {
  width: 8px;
  margin-top: -1px;
  margin-left: -4px;
}

.input-el--number-outer .input-el--number--modify.is-disabled {
  cursor: not-allowed;
  background-color: #e2eaf2;
}

@media (min-width: 1024px) {
  .input-el--number-outer .input-el--number--modify:hover:not(.is-disabled) {
    background-color: #00bd84;
  }
}

.input-el--number-outer .input-el--number--modify:first-of-type {
  margin-right: 5px;
}

.input-el--number-outer .input-el--number--modify:first-of-type:before {
  display: none;
}

.input-el--number-outer .input-el--number--modify:last-of-type {
  margin-left: 5px;
}

.input-phone-number .input-el--prepend {
  padding-left: 10px;
  padding-right: 20px;
}

.input-phone-number .country-list {
  z-index: 500;
  background: #fff;
  border: 1px solid #aaa;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.input-phone-number .country-list ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.input-phone-number .country-list ul li {
  cursor: pointer;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0 5px;
}

.input-phone-number .country-list ul li:hover {
  background: #eee;
}

.input-phone-number .country-list ul li .flag-icon-wrapper {
  float: left;
  margin: 0 10px;
}

.input-phone-number .country-list ul li span {
  padding-left: 5px;
  font-weight: bold;
}



.input-el--select {
  background: #fff;
  border-radius: 8px;
  position: relative;
}

.input-el--select .input-el--label {
  z-index: 0;
}

.input-el--select select {
  -webkit-appearance: none;
  appearance: none;
  z-index: 5;
  background: none;
  padding-right: 35px;
  position: relative;
}

.input-el--select select::-ms-expand {
  display: none;
}

.input-el--select .IconSmile {
  z-index: 0;
  width: .35em;
  height: .7em;
  position: absolute;
  top: 1.3em;
  right: .75em;
  transform: translate3d(0, -50%, 0)rotate(90deg);
}

.info-message-container {
  visibility: hidden;
  position: absolute;
  bottom: calc(100% + 10px);
  left: -15px;
  right: -15px;
}

.info-message-container.info-message--visible {
  visibility: visible;
}

@media (min-width: 576px) {
  .info-message-container {
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .info-message-container {
    bottom: calc(100% + 20px);
  }
}

.info-message-container .info-message--example {
  max-width: 250px;
  display: flex;
}

.info-message-container .info-message--example-name {
  flex-grow: 1;
}

.footer-notification-bar {
  z-index: 0;
  opacity: .7;
  width: 100%;
  padding: 0 45px;
  font-weight: bold;
  animation: .5s forwards slideToTop;
  position: absolute;
  top: -20px;
  left: 0;
}

.footer-notification-bar p {
  margin: 0;
  padding: 2.5px 0;
  font-size: 15px;
}

.footer-notification-bar a {
  color: #fff;
}

.footer-notification-bar span {
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  right: 45px;
}

.footer-notification-bar.error-notification {
  color: #fff;
  background: #d63031;
}

.footer-notification-bar.success-notification {
  color: #fff;
  background: #27ae60;
}

.footer-notification-bar.warn-notification {
  color: #fff;
  background: #f39c12;
}

.modal .help-header {
  padding-top: .3em;
}

.modal .help-header .IconQuestionBubble {
  float: left;
  position: relative;
  top: -.3em;
}

.modal .help-header .question-mark, .modal .help-header .bubble {
  fill: #fff;
}

@keyframes slideToTop {
  100% {
    opacity: 1;
    top: -30px;
  }
}

@media (max-width: 991.98px) {
  .notifications--with-contact-bar {
    top: -45px;
  }
}

.input-el--autocomplete input {
  padding-left: 35px;
  padding-right: 45px;
  position: relative;
}

.input-el--autocomplete label {
  margin-top: 0;
  margin-bottom: 0;
}

.input-el--autocomplete .input-el--label {
  margin-left: 20px;
}

.input-el--autocomplete .input-el--feedback {
  margin-left: 37px;
}

.input-el--autocomplete.input-el--has-focus input {
  z-index: 5;
}

.input-el--autocomplete.input-el--has-focus .input-el--label {
  z-index: 15;
}

.input-el--autocomplete .autocomplete-list {
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #0073fa;
  border-top-width: 0;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  width: 100%;
  transition: all .5s .25s;
  display: flex;
  position: absolute;
  top: 2.38em;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, -10px, 0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, .12);
}

.input-el--autocomplete .autocomplete-list--show {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .input-el--autocomplete .autocomplete-list {
    top: 2.45em;
  }
}

.input-el--autocomplete .autocomplete-list--item {
  background: #fff;
  padding: .695em 15px;
}

@media (min-width: 1024px) {
  .input-el--autocomplete .autocomplete-list--item {
    cursor: pointer;
  }

  .input-el--autocomplete .autocomplete-list--item:hover {
    background: #ecf5ff;
  }
}

.input-el--autocomplete .autocomplete-list--item.highlighted {
  background: #ecf5ff;
}

.input-el--autocomplete--icon-wrapper {
  z-index: 10;
  position: absolute;
  top: 10px;
  left: 9px;
}

@media (min-width: 768px) {
  .input-el--autocomplete--icon-wrapper {
    top: 12px;
  }
}

.input-el--autocomplete--icon-wrapper .IconSmile {
  margin-top: .2em;
  margin-left: -.2em;
  display: none;
}

.input-el--autocomplete--is-loading .IconSmile {
  display: block;
}

.input-el--autocomplete--is-loading .IconMarker {
  display: none;
}

.input-el--autocomplete--hints {
  color: #fff;
  background: #0073fa;
  border-radius: 0 0 8px 8px;
  justify-content: flex-start;
  align-items: center;
  margin-top: -15px;
  padding: 15px 5px 5px 15px;
  display: flex;
  position: relative;
}

.input-el--autocomplete--hints svg {
  margin-right: 5px;
}

.input-el--autocomplete--clear {
  z-index: 6;
  opacity: 0;
  cursor: pointer;
  transition: opacity .25s;
  position: absolute;
  top: .6em;
  right: 15px;
}

.input-el--autocomplete--clear svg {
  width: .7em;
  height: .7em;
}

.input-el--autocomplete.input-el--has-value .input-el--autocomplete--clear {
  opacity: 1;
}

@media (max-width: 575.98px) {
  .grouped-autocomplete .input-row:first-child .input-element, .grouped-autocomplete .input-row:first-child {
    margin-bottom: 0;
  }

  .grouped-autocomplete .input-row:first-child .type-ba input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .grouped-autocomplete .input-row:first-child .ba-options-up.ba-focused.type-ba input {
    border-radius: 0;
  }

  .grouped-autocomplete .input-row:last-child .input-element {
    margin-top: -1px;
  }

  .grouped-autocomplete .input-row:last-child .type-ba input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 576px) {
  .grouped-autocomplete {
    display: flex;
  }

  .grouped-autocomplete .input-row:first-child .input-element {
    margin-right: -.2em;
  }

  .grouped-autocomplete .input-row:first-child .type-ba input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .grouped-autocomplete .input-row:first-child .ba-options-up.ba-focused.type-ba input {
    border-radius: 0 0 0 8px;
  }

  .grouped-autocomplete .input-row:last-child .type-ba input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .grouped-autocomplete .input-row:last-child .ba-options-up.ba-focused.type-ba input {
    border-radius: 0 0 8px;
  }
}

@media (min-width: 768px) {
  .input-el--tile--option:hover {
    text-decoration: none;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .12);
  }
}

.is-selected.input-el--tile--option {
  border-color: #00d896;
  box-shadow: 0 14px 10px rgba(0, 0, 0, .33);
}

@media (min-width: 768px) {
  .is-selected.input-el--tile--option:hover {
    border-color: #00d896;
    box-shadow: 0 14px 10px rgba(0, 0, 0, .33);
  }
}

.input-el--tile--title {
  font-size: 1.1em;
  font-weight: bold;
}

.input-el--tile--option {
  cursor: pointer;
  opacity: 0;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #aebfd4;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  padding: 20px;
  line-height: 1.45em;
  transition: box-shadow .25s;
  animation: .33s ease-in-out forwards fadeInUp;
  display: flex;
}

@media (min-width: 768px) {
  .input-el--tile--option {
    padding: 25px;
  }
}

.input-el--tile-compact .input-el--tile--option {
  margin-bottom: .5em;
  padding: 15px;
}

.input-el--tile-compact .input-el--tile--option:last-child {
  margin-bottom: 0;
}

.input-el--tile--option--time {
  border: 1px solid #aebfd4;
  flex-wrap: wrap;
  cursor: default !important;
}

.input-el--tile--option--time .input-el--tile--copy {
  flex-grow: 1;
  width: 70%;
}

.input-el--tile--option--time .input-el--tile--suffix {
  width: 30%;
}

.input-el--tile--option--align-top {
  align-items: flex-start;
}

.input-el--tile--option--time-fields {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.input-el--tile--option--time-fields .input-el--select {
  width: calc(50% - 15px);
}

.input-el--tile--option, .input-el--tile--animation {
  opacity: 0;
  animation: .33s ease-in-out forwards fadeInUp;
}

.input-el--tile--highlight {
  opacity: 1;
  animation: .5s ease-in-out 3 forwards backgroundBlink;
  transform: translate3d(0, 0, 0);
}

.input-el--tile--highlight-none {
  opacity: 1;
  animation: none;
  transform: translate3d(0, 0, 0);
}

@keyframes backgroundBlink {
  0%, 100% {
    opacity: 1;
    background: #fff;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
    background: #cde4ff;
    transform: translate3d(0, 0, 0);
  }
}

.input-el--tile--copy {
  text-align: left;
  width: 100%;
  padding: 0 15px 0 0;
}

.input-el--tile--desc {
  font-family: bariol, Arial, sans-serif;
}

.input-el--tile--suffix, .input-el--tile--prefix {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.input-el--tile--suffix svg, .input-el--tile--prefix svg {
  width: 1.7em;
  height: 1.7em;
}

.input-el--tile--suffix svg.IconEcoFriendly, .input-el--tile--prefix svg.IconEcoFriendly {
  width: 1.4em;
  height: 1em;
  margin: 0 .5em -.15em 0;
}

.input-el--tile--suffix svg.IconSmile, .input-el--tile--prefix svg.IconSmile {
  width: 1em;
  height: 1em;
}

.input-el--tile--prefix {
  justify-content: flex-start;
  padding-right: 25px;
}

.input-el--tile--suffix {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
  font-weight: bold;
}

.input-el--switch .input-el--switch--label {
  text-align: center;
  cursor: pointer;
  width: 100%;
  padding: .2em 0;
  line-height: 1;
  display: block;
}

@media (min-width: 1025px) {
  .input-el--switch .input-el--switch--label:hover {
    background: #f2f8ff;
  }
}

.input-el--switch input {
  display: none;
}

.input-el--switch input:checked + label {
  color: #fff;
  pointer-events: none;
  background: #0073fa;
}

.input-el--switch-options {
  color: #0073fa;
  border: 1px solid #0073fa;
  border-radius: 8px;
  width: 100%;
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

.input-el--switch-options > div {
  width: 100%;
}

.input-el--password input {
  padding-right: 45px;
}

.input-el--password--trigger {
  position: absolute;
  top: .7em;
  right: .6em;
}

.input-el--password .IconEye {
  opacity: .75;
}

.input-el--password--visible .IconEye {
  opacity: 1;
}

.input-el--text-toggle input {
  display: none;
}

.input-el--text-toggle--label {
  color: #0073fa;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes backdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .7;
  }
}

.input-el--examples--backdrop {
  opacity: 0;
  visibility: visible;
  background: #223b58;
  animation: .25s linear .1s forwards backdrop;
  position: fixed;
  inset: 0;
}

.input-el--examples--overlay {
  position: absolute;
}

@media (min-width: 1025px) {
  .input-el--examples--overlay {
    top: var(--input-el--examples--top);
    left: var(--input-el--examples--left);
    width: var(--input-el--examples--width);
  }

  .input-el--examples--overlay .input-el--inner {
    display: block;
  }
}

@media (max-width: 991.98px) {
  .input-el--examples--overlay {
    background-color: #fff;
    padding: 15px;
    inset: 0;
  }

  .input-el--examples--overlay .input-el {
    height: 100%;
  }

  .input-el--examples--overlay .input-el--inner {
    flex-direction: column;
    max-height: 100%;
  }

  .input-el--examples--overlay input {
    border: 1px solid #0073fa !important;
  }
}

@media (min-width: 1025px) {
  .input-el--examples--overlay .input-el--tag--wrapper .IconArrow {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .input-el--examples--overlay .input-el--tag--wrapper {
    align-items: center;
    display: flex;
  }

  .input-el--examples--overlay .input-el--tag--wrapper .input-el--tag {
    flex-grow: 1;
    margin-left: .5em;
  }

  .input-el--examples--overlay .input-el--tag--wrapper .IconArrow {
    width: 2em;
    height: 1em;
  }
}

.input-el--examples-list {
  color: #263238;
  z-index: 5;
  background-color: #fff;
  margin-top: -.3em;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .input-el--examples-list {
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0;
    display: flex;
    position: static;
  }
}

@media (min-width: 1025px) {
  .input-el--examples-with-list .input-el--examples-list {
    border: 1px solid #0073fa;
    border-top-color: #aebfd4;
    border-radius: 0 0 8px 8px;
  }
}

.input-el--examples-list--item {
  cursor: pointer;
  padding: .568em 2em .568em 15px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .input-el--examples-list--item {
    padding-left: 0;
    padding-right: 1.5em;
  }
}

.input-el--examples-list--item span {
  color: #aebfd4;
  margin-left: 1em;
  display: inline-block;
}

.input-el--examples-list--item svg {
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  right: .5em;
}

@media (max-width: 991.98px) {
  .input-el--examples-list--item svg {
    right: 0;
  }
}

.input-el--examples-list--item svg .cls-1 {
  fill: #0073fa;
}

.input-el--examples-list--item:hover, .input-el--examples-list--item--highlight {
  color: #fff;
  background-color: #0073fa;
}

.input-el--examples-list--item:hover span, .input-el--examples-list--item--highlight span {
  color: #fff;
}

.input-el--examples-list--item:hover svg .cls-1, .input-el--examples-list--item--highlight svg .cls-1 {
  fill: #fff;
}

.input-el--examples-list--title {
  color: #aebfd4;
  padding: .568em 15px;
}

@media (max-width: 991.98px) {
  .input-el--examples-list--title {
    padding-left: 0;
  }
}

.input-el--examples-list--predefined {
  max-height: 30vh;
  overflow: auto;
}

@media (max-width: 991.98px) {
  .input-el--examples-list--predefined {
    flex-grow: 1;
    max-height: none;
  }
}

@media (min-width: 1025px) {
  .input-el--examples-list--predefined::-webkit-scrollbar {
    width: 5px;
  }

  .input-el--examples-list--predefined::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .input-el--examples-list--predefined::-webkit-scrollbar-thumb {
    background: #888;
  }

  .input-el--examples-list--predefined::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.input-default {
  color: #223b58;
  border: 1px solid #aebfd4;
  width: 100%;
  padding: 1.2rem 1.6rem;
  font-family: inherit;
  font-size: 1.8rem;
}

.input-loading .input-default {
  padding-right: 2rem;
}

.input-default::placeholder {
  color: #7c90a8;
}

.input--error {
  color: #e10000;
  font-size: .75em;
}

.input--warning {
  color: #f59023;
  font-size: .75em;
}

select.input-default {
  background-color: rgba(0, 0, 0, 0);
}

.input-loading {
  position: relative;
}

.input-loading .loader {
  position: absolute;
  top: 1.1rem;
  right: .6rem;
}

.input-switch {
  color: #0073fa;
  background-color: #fff;
  border: 1px solid #0073fa;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

.input-switch label {
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.input-switch input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.input-switch input:checked + label {
  color: #fff;
  background-color: #0073fa;
}

@media (min-width: 768px) {
  .input-el--tile--option:hover {
    text-decoration: none;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .12);
  }
}

.is-selected.input-el--tile--option {
  border-color: #00d896;
  box-shadow: 0 14px 10px rgba(0, 0, 0, .33);
}

@media (min-width: 768px) {
  .is-selected.input-el--tile--option:hover {
    border-color: #00d896;
    box-shadow: 0 14px 10px rgba(0, 0, 0, .33);
  }
}

.input-el--tile--title {
  font-size: 1.1em;
  font-weight: bold;
}

.input-el--tile--option {
  cursor: pointer;
  opacity: 0;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #aebfd4;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  padding: 20px;
  line-height: 1.45em;
  transition: box-shadow .25s;
  animation: .33s ease-in-out forwards fadeInUp;
  display: flex;
}

@media (min-width: 768px) {
  .input-el--tile--option {
    padding: 25px;
  }
}

.input-el--tile-compact .input-el--tile--option {
  margin-bottom: .5em;
  padding: 15px;
}

.input-el--tile-compact .input-el--tile--option:last-child {
  margin-bottom: 0;
}

.input-el--tile--option--time {
  border: 1px solid #aebfd4;
  flex-wrap: wrap;
  cursor: default !important;
}

.input-el--tile--option--time .input-el--tile--copy {
  flex-grow: 1;
  width: 70%;
}

.input-el--tile--option--time .input-el--tile--suffix {
  width: 30%;
}

.input-el--tile--option--align-top {
  align-items: flex-start;
}

.input-el--tile--option--time-fields {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.input-el--tile--option--time-fields .input-el--select {
  width: calc(50% - 15px);
}

.input-el--tile--option, .input-el--tile--animation {
  opacity: 0;
  animation: .33s ease-in-out forwards fadeInUp;
}

.input-el--tile--highlight {
  opacity: 1;
  animation: .5s ease-in-out 3 forwards backgroundBlink;
  transform: translate3d(0, 0, 0);
}

.input-el--tile--highlight-none {
  opacity: 1;
  animation: none;
  transform: translate3d(0, 0, 0);
}

@keyframes backgroundBlink {
  0%, 100% {
    opacity: 1;
    background: #fff;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
    background: #cde4ff;
    transform: translate3d(0, 0, 0);
  }
}

.input-el--tile--copy {
  text-align: left;
  width: 100%;
  padding: 0 15px 0 0;
}

.input-el--tile--desc {
  font-family: bariol, Arial, sans-serif;
}

.input-el--tile--suffix, .input-el--tile--prefix {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.input-el--tile--suffix svg, .input-el--tile--prefix svg {
  width: 1.7em;
  height: 1.7em;
}

.input-el--tile--suffix svg.IconEcoFriendly, .input-el--tile--prefix svg.IconEcoFriendly {
  width: 1.4em;
  height: 1em;
  margin: 0 .5em -.15em 0;
}

.input-el--tile--suffix svg.IconSmile, .input-el--tile--prefix svg.IconSmile {
  width: 1em;
  height: 1em;
}

.input-el--tile--prefix {
  justify-content: flex-start;
  padding-right: 25px;
}

.input-el--tile--suffix {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
  font-weight: bold;
}

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*, :before, :after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.card {
  color: #223b58;
  background: #fff;
  border-radius: 10px;
  margin: 0 0 15px;
  padding: 15px;
  overflow: hidden;
  box-shadow: 8px 8px 20px rgba(174, 191, 212, .2);
}

.card--bordered {
  border: 1px solid #aebfd4;
}

.card--shade-2 {
  margin-bottom: 30px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}

.card--tr-business, .card--transport-request {
  padding: 0 15px;
}

.card--tr-business .section-wrapper, .card--transport-request .section-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}

.card--tr-business {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .card--tr-business .section-wrapper {
    padding-top: 30px;
  }
}

@media (min-width: 992px) {
  .card--tr-business--short-id {
    text-align: right;
  }
}

.card--tr-business--actions {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 575.98px) {
  .card--tr-business--actions {
    order: 3;
  }
}

.card--tr-business--actions .btn {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: .5em;
}

.card--tr-business--actions .btn:last-child {
  margin-right: 0;
}

.card--disabled .IconSmile, .card.trigger .IconSmile {
  width: .4em;
  height: 1em;
  position: absolute;
  top: 1.1em;
  right: 15px;
}

.card--disabled {
  cursor: not-allowed;
  opacity: .75;
  background-color: #aebfd4;
}

.card-with-image {
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  min-height: 120px;
  padding: 1.15em;
  animation: 1s ease-in-out forwards fadeInGrey;
  display: flex;
  position: relative;
}

.card-with-image--header {
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-size: 1.5em;
  font-weight: bold;
}

.card-with-image--content {
  opacity: 0;
  width: calc(66% - 15px);
  height: 100%;
  animation: .25s ease-in-out .1s forwards fadeInUp;
}

.card-with-image--content p {
  margin-bottom: .2em;
}

.card-with-image.is-selected {
  color: #0073fa;
  border-color: #0073fa;
}

.card-with-image--media-wrap {
  background-position: center;
  background-size: cover;
  height: calc(100% + 20px);
  position: absolute;
  top: -10px;
  left: 66%;
  right: 0;
}

.card-with-image--media-wrap .price-display {
  color: #fff;
  font-size: .7em;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, -15px, 0);
}

.card-with-image .input-el--file {
  margin-bottom: 0;
}

.card-with-image .input-el--file-compact {
  max-width: 100%;
}

.card-with-image.trigger:after {
  content: "";
  z-index: 50;
  background: url("smiley_right_white.66c09045.svg") center / 7px no-repeat;
  width: 7px;
  height: 14px;
  display: block;
  position: absolute;
  top: 1.15em;
  right: 1.15em;
}

.section-wrapper {
  width: auto;
  margin-bottom: 15px;
  padding: 15px;
}

.section-wrapper h1, .section-wrapper h2, .section-wrapper h3, .section-wrapper h4, .section-wrapper h5 {
  color: inherit;
}

.section-wrapper:not(.login-wrapper) {
  margin-left: -15px;
  margin-right: -15px;
}

.region-content .section-wrapper:not(.login-wrapper) {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.region-content .section-wrapper:not(.login-wrapper) .dashboard-opening h4 {
  font-size: 26px;
}

@media (min-width: 576px) {
  .section-wrapper {
    padding: 30px 15px;
  }
}

.section-wrapper-content {
  position: relative;
}

.item-config-section .section-wrapper-content {
  z-index: 5;
}

.section-wrapper--dashed {
  border-style: dashed;
  border-width: 2px 0;
}

.section-wrapper--full-screen {
  min-height: calc(100vh - 80px);
}

.section-wrapper--primary {
  color: #fff;
  background: #0073fa;
  border-color: #f2f8ff;
}

.section-wrapper--primary-light {
  background: #f2f8ff;
  border-color: #0073fa;
}

.section-wrapper--secondary {
  color: #fff;
  background: #00d896;
  border-color: rgba(0, 216, 150, .33);
}

.section-wrapper--secondary-light {
  background: rgba(0, 216, 150, .33);
  border-color: #00d896;
}

.section-wrapper .sheet-layout--main-content {
  padding-bottom: 0;
}

.progress-steps {
  color: #aebfd4;
  padding: 15px;
  display: inline-block;
  position: relative;
}

.progress-steps--with-exit {
  padding-right: 45px;
}

.progress-steps .progress-step {
  opacity: 0;
  visibility: hidden;
  transition: all .33s ease-in-out;
  position: absolute;
  top: 15px;
  left: 0;
  transform: translate3d(10px, 10px, 0)rotate(45deg);
}

.progress-steps .progress-step--done {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-10px, 10px, 0)rotate(-45deg);
}

.progress-steps .progress-step--active {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0)rotate(0);
}

.progress-steps--exit {
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  right: 15px;
}

.progress-steps--exit svg {
  width: .7em;
  height: .7em;
}

.progress-bar {
  background: #cde4ff;
  height: 4px;
  position: relative;
}

.progress-bar .progress-bar-indicator {
  top: 0;
  left: 0;
  right: var(--progress-position);
  background: #0073fa;
  border-radius: 0 8px 8px 0;
  transition: right .33s ease-in-out;
  position: absolute;
  bottom: 0;
}

.progress-header .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

body.bd-progress-header-loaded {
  padding-top: 60px !important;
}

body.bd-progress-header-loaded header.region-header, body.bd-progress-header-loaded footer.footer.clearfix {
  display: none;
}

.progress-header {
  text-align: center;
  z-index: 10;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 15px;
  line-height: 1;
  transition: all .15s ease-in-out .33s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .progress-header {
    height: 60px;
  }
}

.bd-progress-header-finished .progress-header {
  transform: translate3d(0, -110%, 0);
}

.progress-header--logo-wrapper {
  align-items: center;
  height: 45px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@media (min-width: 768px) {
  .progress-header--logo-wrapper {
    height: 60px;
  }
}

.progress-header--logo-wrapper .brenger-logo {
  height: 36px;
}

@media (min-width: 768px) {
  .progress-header--logo-wrapper .brenger-logo {
    height: 48px;
  }
}

@media (max-width: 767.98px) {
  .progress-header--with-third-party-logo .progress-header--logo-wrapper {
    justify-content: center;
    width: calc(100% - 100px);
  }

  .progress-header--with-third-party-logo .brenger-logo {
    width: 72px;
    height: 20px;
  }
}

.progress-header--third-party-text {
  margin-left: 15px;
  font-size: .8em;
}

.progress-header .go-back {
  padding: 15px 15px 15px 0;
}

.progress-header .go-back-text {
  color: #aebfd4;
  padding-left: 15px;
  display: none;
}

@media (min-width: 576px) {
  .progress-header .go-back-text {
    display: inline;
  }
}

.progress-header .go-back svg {
  width: 6px;
  height: 10px;
  transform: rotate(-180deg);
}

.progress-header .progress-steps {
  margin: 0 auto 0 0;
}

.progress-header .input-el--select--lang {
  margin: 0 0 0 auto;
}

@media (min-width: 576px) {
  .progress-header .input-el--select--lang {
    margin: 0;
  }
}

.progress-header .input-el--select--lang select {
  padding: .4em 1.4em .4em .4em;
}

.progress-header .input-el--select--lang .IconSmile {
  top: 50%;
}

.bd-progress-header-loaded .bd-main-header {
  transform: translate3d(0, -200px, 0);
}

.bd-progress-header-finished .bd-main-header {
  background: #0073fa;
  transform: translate3d(0, 0, 0);
}

.bd-progress-header-finished .bd-main-header .brenger-logo .cls-1 {
  fill: #fff;
}

.bd-progress-header-loaded .bd-main-header .bd-nav-main, .bd-progress-header-loaded .bd-main-header .bd-nav-toggle, .bd-hide-menu .bd-main-header .bd-nav-main, .bd-hide-menu .bd-main-header .bd-nav-toggle {
  display: none;
}

.sheet-layout {
  width: 100%;
  margin-top: 5px;
}

.sheet-layout--sidebar, .sheet-layout--footer {
  padding: 15px 0;
}

.sheet-layout--main {
  text-align: center;
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .sheet-layout--main {
    overflow-x: hidden;
  }
}

.sheet-layout--main-container {
  padding: 0 15px;
}

@media (min-width: 992px) {
  .sheet-layout--notifications, .sheet-layout--main, .sheet-layout--footer {
    padding-right: 400px;
  }
}

@media (min-width: 1450px) {
  .sheet-layout--notifications, .sheet-layout--main, .sheet-layout--footer {
    padding-right: 450px;
  }
}

@media (min-width: 2000px) {
  .sheet-layout--notifications, .sheet-layout--main, .sheet-layout--footer {
    padding-right: 0;
  }
}

.sheet-layout--sidebar {
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  background: rgba(34, 59, 88, .37);
  padding-top: 0;
  transition: all .5s cubic-bezier(0, .69, .46, 1);
  position: fixed;
  inset: 0 0 65px;
}

@media (min-width: 768px) {
  .sheet-layout--sidebar {
    transition: none;
  }
}

.sheet-layout--sidebar-background {
  z-index: 1;
  background: #cde4ff;
  padding: 15px;
  transition: all .5s cubic-bezier(0, .69, .46, 1);
  position: absolute;
  inset: 45px 0 0;
  overflow: auto;
  transform: translate3d(0, 110%, 0);
}

@media (min-width: 768px) {
  .sheet-layout--sidebar-background {
    flex-direction: column;
    padding: 50px 50px 40px;
    transition: none;
    display: flex;
    top: 0;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
}

.sheet-layout--sidebar-contact {
  color: #fff;
  background-color: #0073fa;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .sheet-layout--sidebar-contact {
    display: flex;
  }
}

.sheet-layout--sidebar-contact svg {
  margin: 0 .2em -.2em;
}

.sheet-layout--is-active .sheet-layout--sidebar {
  opacity: 1;
  visibility: visible;
}

.sheet-layout--is-active .sheet-layout--sidebar-background {
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .sheet-layout--sidebar {
    opacity: 1;
    visibility: visible;
    width: 350px;
    font-size: 1.6rem;
    line-height: 2.6rem;
    top: 60px;
    bottom: 90px;
    left: auto;
    transform: translate3d(110%, 0, 0);
  }

  .sheet-layout--is-active .sheet-layout--sidebar {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@media (min-width: 992px) {
  .sheet-layout--sidebar {
    opacity: 1;
    visibility: visible;
    width: 400px;
    top: 60px;
    bottom: 90px;
    left: auto;
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@media (min-width: 1450px) {
  .sheet-layout--sidebar {
    width: 450px;
  }
}

.sheet-layout--sidebar-shade-toggle {
  z-index: 5;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sheet-layout--sidebar-heading, .sheet-layout--sidebar .summary-heading {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 3.2rem;
}

.sheet-layout--sidebar .summary--close svg {
  width: .9em;
  height: .9em;
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (min-width: 992px) {
  .sheet-layout--sidebar .summary--close {
    display: none;
  }
}

.sheet-layout--sidebar-heading {
  z-index: 50;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

@media (min-width: 768px) {
  .sheet-layout--sidebar-heading {
    top: 2em;
  }
}

.sheet-layout--sidebar .summary-heading {
  color: #0073fa;
}

.sheet-layout--sidebar-content {
  font-size: .85em;
}

.sheet-layout--sticky-footer {
  white-space: nowrap;
  margin-right: 15px;
  right: 0;
}

@media (min-width: 992px) {
  .sheet-layout--sticky-footer {
    right: 400px;
  }
}

@media (min-width: 1450px) {
  .sheet-layout--sticky-footer {
    right: 450px;
  }
}

.sheet-layout--footer {
  z-index: 15;
  background: #fff;
  width: 100%;
  height: 65px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2), 0 -50px 50px #fff, 0 17px 60px #fff;
}

.sheet-layout--is-active .sheet-layout--footer {
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

@media (min-width: 768px) {
  .sheet-layout--footer {
    height: 90px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }
}

.sheet-layout--footer .btn {
  margin-top: 0;
}

.sheet-layout--footer .btn--secondary {
  float: right;
  width: 155px;
  margin: 0;
  padding: 8px 45px 8px 15px;
  font-size: 1.1em;
}

@media (min-width: 768px) {
  .sheet-layout--footer .btn--secondary {
    width: 100%;
    padding-top: .65em;
    padding-bottom: .75em;
    font-size: 1em;
  }
}

.sheet-layout--footer-contact {
  color: #fff;
  background-color: #0073fa;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  display: flex;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .sheet-layout--footer-contact {
    display: none;
  }
}

.sheet-layout--footer-contact a {
  color: inherit;
  text-decoration: none;
}

.sheet-layout--footer-contact svg {
  margin: 0 .2em -.2em;
}

.sheet-layout--content-wrapper {
  text-align: left;
  padding-bottom: 8rem;
}

.sheet-layout--content-wrapper--top-header {
  padding: 0 0 .5em;
}

@media (min-width: 768px) {
  .sheet-layout--content-wrapper--top-header {
    padding: 1em 0 .5em;
  }
}

.sheet-layout--main-header {
  opacity: 0;
  z-index: 5;
  background: #fff;
  max-width: 520px;
  margin: 0 auto;
  animation: .33s ease-in-out forwards fadeInRight;
}

@media (min-width: 1200px) {
  .sheet-layout--main-header {
    max-width: 695px;
    margin: 0 8% 0 auto;
  }
}

@media (min-width: 2000px) {
  .sheet-layout--main-header {
    margin: 0 auto;
  }
}

.sheet-layout--is-navigating .sheet-layout--main-header {
  animation-delay: 50ms;
}

.sheet-layout--main-content {
  opacity: 0;
  z-index: 0;
  flex-grow: 1;
  padding-bottom: 15px;
  animation: .33s ease-in-out 50ms forwards fadeInRight;
  position: relative;
}

.sheet-layout--container, .sheet-layout--main-content {
  max-width: 520px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .sheet-layout--container, .sheet-layout--main-content {
    max-width: 695px;
    margin: 0 8% 0 auto;
  }
}

@media (min-width: 2000px) {
  .sheet-layout--container, .sheet-layout--main-content {
    margin: 0 auto;
  }
}

.sheet-layout--is-navigating .sheet-layout--container, .sheet-layout--is-navigating .sheet-layout--main-content {
  animation-delay: 0s;
}

.sheet-layout--nav-forwards.sheet-layout--is-navigating .sheet-layout--main-header, .sheet-layout--nav-forwards.sheet-layout--is-navigating .sheet-layout--main-content {
  opacity: 1;
  animation-name: fadeOutleft;
}

.sheet-layout--nav-backwards .sheet-layout--main-header, .sheet-layout--nav-backwards .sheet-layout--main-content {
  animation-name: fadeInLeft;
}

.sheet-layout--nav-backwards.sheet-layout--is-navigating .sheet-layout--main-header, .sheet-layout--nav-backwards.sheet-layout--is-navigating .sheet-layout--main-content {
  opacity: 1;
  animation-name: fadeOutRight;
}

.sheet-layout--notifications {
  z-index: 30;
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
}

.sheet-layout--notifications .info-message-container {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
}

@media (min-width: 768px) {
  .sheet-layout--notifications {
    bottom: 90px;
  }
}

.sheet-layout--notifications .container, .sheet-layout--footer .container {
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .sheet-layout--notifications .container, .sheet-layout--footer .container {
    max-width: 725px;
    margin: 0 8% 0 auto;
  }
}

@media (min-width: 2000px) {
  .sheet-layout--notifications .container, .sheet-layout--footer .container {
    margin: 0 auto;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100px)scale(.95);
  }

  50% {
    opacity: .25;
  }

  100% {
    opacity: 1;
    transform: translateX(0)scale(1);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px)scale(.95);
  }

  50% {
    opacity: .25;
  }

  100% {
    opacity: 1;
    transform: translateX(0)scale(1);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0)scale(1);
  }

  50% {
    opacity: .25;
  }

  100% {
    opacity: 0;
    transform: translateX(100px)scale(.95);
  }
}

@keyframes fadeOutleft {
  0% {
    opacity: 1;
    transform: translateX(0)scale(1);
  }

  50% {
    opacity: .25;
  }

  100% {
    opacity: 0;
    transform: translateX(-100px)scale(.95);
  }
}

.collapse .IconSmile {
  transform-origin: 50%;
  width: .8em;
  height: .8em;
  transition: all .25s;
  transform: translate3d(.5em, .1em, 0)rotate(90deg);
}

.collapse--header h5 {
  color: #0073fa;
  margin: 0;
  padding: .5em 0;
}

.collapse--content {
  display: none;
}

.collapse--is-open .IconSmile {
  transform: translate3d(.5em, .1em, 0)rotate(-90deg);
}

.collapse--is-open .collapse--content {
  display: block;
}

.pagination {
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  margin: 20px auto;
  display: flex;
}

.pagination--item {
  cursor: pointer;
  background: #e2eaf2;
  border: 1px solid #e2eaf2;
  border-radius: 8px;
  margin: .25em;
  padding: .8em 1.2em;
}

.pagination--item:hover {
  color: #fff;
  background: #0073fa;
}

.pagination--item--active {
  pointer-events: none;
  cursor: default;
  color: #fff;
  background: #0073fa;
}

.message {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  width: 100%;
  margin-top: 0;
  padding: .56em 15px;
  line-height: 1.1;
}

.message .trigger, .message a {
  color: inherit;
  text-decoration: underline !important;
}

.message--error {
  color: #e10000;
  background: #fff9f9;
  border-color: #e10000;
}

.message--success {
  color: #fff;
  background: #00d896;
  border-color: #00d896;
}

.message--info {
  color: #fff;
  background: #0073fa;
  border-color: #0073fa;
}

.message--info-light {
  color: #0073fa;
  background: #ecf5ff;
  border-color: #ecf5ff;
}

@media print {
  .messages, .message {
    display: none;
  }
}

.modal {
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transition: opacity .25s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.modal--style-status-page {
  z-index: 9900;
}

.modal.modal--isOpen {
  opacity: 1;
  visibility: visible;
}

.modal a {
  color: inherit;
  text-decoration: underline;
}

.modal-backdrop {
  background: rgba(0, 0, 0, .66);
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  overflow: auto;
}

.modal-backdrop--no-shade {
  background: none;
}

.modal--style-chat .modal-backdrop {
  overflow: hidden scroll;
}

.modal-inner {
  background: #fff;
  border-radius: 8px;
  max-width: 575px;
  margin: 15px;
  padding: 15px;
  transition: transform .25s;
  position: relative;
  transform: translate3d(0, -60px, 0);
}

.modal--style-blue .modal-inner {
  color: #fff;
  background: #0073fa;
  border-radius: 0;
  max-width: 430px;
}

.modal--style-blue .modal-inner .IconCross path {
  fill: #fff;
}

.modal--style-white > .modal-backdrop > .modal-inner, .modal--style-status-page > .modal-backdrop > .modal-inner, .modal--style-chat > .modal-backdrop > .modal-inner {
  border-radius: 0;
  max-width: 940px;
}

.modal--style-small .modal-inner {
  max-width: 450px;
}

.modal--style-white .modal-inner {
  flex-direction: column;
  max-width: 450px;
  max-height: calc(100% - 5vh);
  margin: 0 auto;
  display: flex;
  overflow: auto;
}

@media (min-width: 576px) {
  .modal--style-white .modal-inner {
    height: auto;
  }
}

.modal--style-white > .modal-backdrop > .modal-inner {
  max-width: 335px;
}

.modal--style-status-page > .modal-backdrop > .modal-inner {
  padding: 0;
}

@media (min-width: 576px) {
  .modal-inner {
    margin: 5vh auto;
  }
}

.modal-inner p {
  line-height: 1.2;
}

.modal-inner .liveVideo-msg {
  color: #e10000;
  background: #fff9f9;
  border: 1px solid #e10000;
  border-radius: 8px;
  margin-top: 10px;
  padding: .56em 15px;
  line-height: 1.1;
  display: block;
}

.modal-inner .prepaid-msg {
  margin-bottom: 20px;
  display: block;
}

.modal--isOpen .modal-inner {
  transform: translate3d(0, 0, 0);
}

.modal-inner .modal-header {
  border-bottom: 1px solid #aebfd4;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-right: 1.5em;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
}

.modal--style-white .modal-inner .modal-header, .modal--style-status-page .modal-inner .modal-header {
  z-index: 5;
  border: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}

.modal--style-blue .modal-inner .modal-header {
  border: none;
}

.modal--style-chat .modal-inner .modal-header {
  display: none;
}

.modal-inner .modal-footer {
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 15px;
  display: flex;
}

.modal-inner .modal-footer .btn {
  margin: 7.5px 0;
}

.modal-inner .modal-footer-sub {
  text-align: center;
  font-size: .8em;
}

.modal-inner .modal-footer-sub .btn {
  margin: 0 0 7.5px;
}

.modal-inner .modal-footer-sub .trigger {
  text-decoration: underline;
}

.modal-close {
  position: absolute;
  top: .025em;
  right: 0;
}

.modal--style-status-page > .modal-backdrop > .modal-inner > .modal-header .modal-close {
  top: 30px;
  left: 30px;
}

.modal--style-white .modal-close {
  top: -2px;
  right: 0;
}

.modal-close svg {
  width: .75em;
  height: .75em;
}

.prompt-modal ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.info-message {
  visibility: hidden;
  max-height: 0;
  position: relative;
  overflow: hidden;
}

.info-message--wrapper {
  color: #0073fa;
  background: #a2cdff;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: all .5s cubic-bezier(0, .69, .46, 1);
  display: flex;
  transform: translate3d(0, 110%, 0);
}

@media (min-width: 576px) {
  .info-message--wrapper {
    padding: 15px 0;
  }
}

.info-message--visible .info-message {
  visibility: visible;
  max-height: 1000px;
}

.info-message--visible .info-message .info-message--wrapper {
  transform: translate3d(0, 0, 0);
}

.info-message--prefix {
  padding-left: 10px;
  line-height: 1;
}

@media (min-width: 576px) {
  .info-message--prefix {
    padding-left: 15px;
  }
}

.info-message--prefix svg {
  width: 40px;
  height: 40px;
}

@media (min-width: 576px) {
  .info-message--prefix svg {
    width: 65px;
    height: 65px;
  }
}

.info-message--content {
  flex-grow: 1;
  padding: 0 10px;
}

@media (min-width: 576px) {
  .info-message--content {
    padding: 0 15px;
  }
}

@media (max-width: 767.98px) {
  .info-message--content {
    line-height: 1.3;
  }
}

.info-message--suffix {
  padding-right: 10px;
}

@media (min-width: 576px) {
  .info-message--suffix {
    padding-right: 15px;
  }
}

.info-message--close {
  z-index: 10;
  padding: 5px 10px 10px;
  line-height: 1;
  position: absolute;
  top: 6px;
  right: 0;
}

@media (min-width: 1200px) {
  .info-message--close {
    top: 3px;
  }
}

.info-message--close svg {
  width: .7em;
  height: .7em;
}

.info-message ul {
  margin: 5px 0 0;
  padding-left: 15px;
}

@media print {
  .info-message {
    display: none;
  }
}

.person-balloon--wrapper {
  width: 100%;
  display: inline-block;
}

.person-balloon--svg {
  width: 115px;
  height: 115px;
}

@media (min-width: 768px) {
  .person-balloon--svg {
    width: 150px;
    height: 150px;
  }
}

.person-balloon .col {
  align-items: center;
}

.person-balloon--balloon {
  min-width: 110px;
  min-height: 110px;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .person-balloon--balloon {
    min-width: 140px;
    min-height: 140px;
  }
}

.person-balloon--balloon--content {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  line-height: 1.3;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .person-balloon--balloon--content {
    line-height: 1.5;
  }
}

.person-balloon--balloon--content:after {
  content: "";
  border-top: 20px solid rgba(0, 0, 0, 0);
  border-bottom: 20px solid rgba(0, 0, 0, 0);
  border-right: 30px solid #fff;
  width: 0;
  height: 0;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  left: -25px;
}

.price-display {
  flex-wrap: wrap;
  width: 120px;
  font-weight: bold;
  display: flex;
  position: relative;
}

@media (min-width: 340px) {
  .price-display {
    width: 190px;
  }

  .price-display--is-simple, .price-display--is-special--flat {
    width: 160px;
  }
}

@media (min-width: 576px) {
  .price-display--is-simple {
    width: 160px;
  }
}

@media (min-width: 992px) {
  .price-display {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }

  .price-display--is-simple {
    justify-content: flex-start;
    margin-top: 0;
  }

  .price-display--is-special--flat {
    width: auto;
  }
}

.sheet-layout--sidebar .price-display {
  border-top: 2px solid #0073fa;
  margin-top: 1rem;
  padding-top: 1rem;
}

.price-display--head {
  color: #0073fa;
  white-space: nowrap;
  margin-bottom: .2em;
  line-height: 1em;
}

.sheet-layout--footer .price-display--head {
  width: 100%;
}

@media (min-width: 768px) {
  .price-display--head {
    margin-bottom: .5em;
  }

  .price-display--is-special .price-display--head {
    margin-bottom: .25em;
  }
}

.sheet-layout--footer .price-display--head svg {
  width: .6em;
  height: .6em;
  margin-right: .5em;
  transition: all .25s;
  transform: rotate(-90deg);
}

.sheet-layout--is-active .sheet-layout--footer .price-display--head svg {
  transform: rotate(90deg);
}

.price-display--layout {
  color: #0073fa;
  white-space: nowrap;
  font-size: 3rem;
  transition: color .25s;
  display: block;
}

@media (max-width: 991.98px) {
  .price-display--layout {
    display: flex;
  }

  .price-display--is-special--flat .price-display--layout {
    display: block;
  }
}

.price-display--is-simple .price-display--layout {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .price-display--is-special .price-display--layout {
    font-size: 1.4em;
  }
}

.price-display--layout small {
  font-size: .7em;
  position: relative;
  top: -.3em;
}

@media (min-width: 992px) {
  .price-display--layout {
    order: 3;
    margin-left: 0;
  }
}

.price-display.price-display-failure span {
  color: #d63031;
}

.price-display--loading-element {
  transition: all .2s;
  display: none;
  position: relative;
  top: -.4em;
}

.price-display--is-special .price-display--loading-element {
  top: 0;
}

@media (min-width: 992px) {
  .price-display--loading-element {
    order: 2;
  }

  .price-display--is-simple .price-display--loading-element {
    order: 3;
  }

  .price-display--loading-element {
    margin-left: auto;
  }
}

.price-display--is-loading .price-display--layout {
  color: #889093;
}

.price-display--is-loading .price-display--loading-element {
  display: block;
}

.price-display--is-visible .price-display--head, .price-display--is-visible .price-display--layout {
  animation: .5s ease-in-out .25s forwards priceblink;
}

.price-display--is-visible .price-display--head svg path, .price-display--is-visible .price-display--layout svg path {
  animation: .5s ease-in-out .25s forwards priceblinksmile;
}

.price-display--special-copy, .price-display--old-price {
  margin-top: .25em;
  font-size: 50%;
  line-height: 60%;
}

@media (min-width: 450px) {
  .price-display--special-copy, .price-display--old-price {
    text-align: right;
    margin-top: auto;
    margin-bottom: .3em;
  }
}

.price-display--is-special--flat .price-display--special-copy, .price-display--is-special--flat .price-display--old-price {
  text-align: left;
}

@media (min-width: 992px) {
  .price-display--is-special--flat .price-display--special-copy, .price-display--is-special--flat .price-display--old-price {
    text-align: right;
  }
}

.price-display--old-price {
  color: #889093;
  font-family: bariol, Arial, sans-serif;
}

@media (min-width: 340px) {
  .price-display--old-price {
    vertical-align: baseline;
    margin: 0 5px 0 0;
    font-size: 85%;
    line-height: 137%;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .price-display--old-price {
    line-height: 120%;
  }
}

@media (min-width: 992px) {
  .price-display--old-price {
    line-height: 100%;
  }
}

@media (max-width: 991.98px) {
  .price-display--old-price {
    order: 2;
    margin-left: 5px;
  }
}

.price-display--old-price span {
  position: relative;
}

.price-display--old-price span:after {
  content: "";
  border-bottom: 1px solid #889093;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

@keyframes priceblink {
  0%, 25%, 75%, 100% {
    color: #0073fa;
  }

  50% {
    color: #00d896;
  }
}

@keyframes priceblinksmile {
  0%, 25%, 75%, 100% {
    fill: #0073fa;
  }

  50% {
    fill: #00d896;
  }
}

.price-label {
  color: #fff;
  border-radius: 5em;
  margin: 0 .5em;
  padding: 0 1em;
  font-size: .8em;
  line-height: 1.5em;
}

.price-label--type-rush {
  background: #ffc63c;
}

.price-label--type-budget {
  background: #0073fa;
}

.price-label--type-sustainable {
  background: #00d896;
}

.loading-app-placeholder {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  display: flex;
}

.brenger_tooltip {
  z-index: 100;
  display: inline-block;
  position: relative;
  top: .15em;
}

.brenger_tooltip svg {
  width: 1em;
  height: 1em;
}

.brenger_tooltip .brenger_tooltip-body {
  opacity: 0;
  visibility: hidden;
  color: #fff;
  text-align: left;
  background: #0073fa;
  border-radius: 8px;
  width: 150px;
  margin-left: -1px;
  padding: 8px 12px;
  font-size: .8em;
  line-height: 1.5em;
  transition: all .25s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 10px, 0);
}

@media (min-width: 576px) {
  .brenger_tooltip .brenger_tooltip-body {
    width: 300px;
  }
}

.brenger_tooltip .brenger_tooltip-body:after {
  content: "";
  background: #0073fa;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: rotate(45deg)translate3d(-5px, 0, 0);
}

.brenger_tooltip .brenger_tooltip-body a {
  color: #fff;
  margin-left: .2em;
  display: inline-block;
}

.brenger_tooltip:hover .brenger_tooltip-body {
  opacity: 1;
  visibility: visible;
  transform: translate3d(-50%, 0, 0);
}

.brenger_tooltip.brenger_tooltip-right .brenger_tooltip-body {
  left: 0;
  transform: translate3d(-.8em, .9em, 0);
}

.brenger_tooltip.brenger_tooltip-right .brenger_tooltip-body:after {
  left: 1.5em;
}

.brenger_tooltip.brenger_tooltip-right:hover .brenger_tooltip-body {
  transform: translate3d(-.8em, 0, 0);
}

.brenger_tooltip.brenger_tooltip-left .brenger_tooltip-body {
  left: auto;
  right: 0;
  transform: translate3d(.9em, .9em, 0);
}

.brenger_tooltip.brenger_tooltip-left .brenger_tooltip-body:after {
  left: auto;
  right: .85em;
}

.brenger_tooltip.brenger_tooltip-left:hover .brenger_tooltip-body {
  transform: translate3d(.9em, 0, 0);
}

.location-display {
  z-index: 0;
  margin-top: -.5em;
  padding: 0 0 1.5em 1.25em;
  position: relative;
  overflow: hidden;
}

.location-display--hide-arrow {
  padding-left: 0;
}

.location-display--datetime {
  text-align: right;
  white-space: nowrap;
  margin-top: -.5em;
}

@media (min-width: 768px) {
  .location-display--datetime--multiple .location-display--datetime--item {
    display: flex;
  }

  .location-display--datetime--multiple .location-display--datetime--item .location-date {
    margin-right: 15px;
  }
}

.location-display--a:after {
  content: "";
  border-left: 2px solid #a2cdff;
  margin-left: -1px;
  position: absolute;
  top: .5em;
  bottom: -.5em;
  left: .3em;
}

.location-display--color-black.location-display--a:after {
  border-left: 2px solid #aebfd4;
}

.location-display--arrow {
  z-index: 10;
  opacity: 0;
  border-left: 2px solid #0073fa;
  margin-bottom: -.5em;
  margin-left: -1px;
  animation: .75s ease-in forwards fadeinarrow;
  position: absolute;
  top: 1em;
  bottom: 50%;
  left: .3em;
  transform: translate3d(0, -10px, 0);
}

.location-display--color-black .location-display--arrow {
  border-left: 2px solid #223b58;
}

.location-display--arrow:after, .location-display--arrow:before {
  content: "";
  transform-origin: 50% 100%;
  border-left: 2px solid #0073fa;
  height: 7px;
  margin-left: -2px;
  animation: .25s ease-in .75s forwards foldoutarrowleft;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: rotate(0);
}

.location-display--color-black .location-display--arrow:after, .location-display--color-black .location-display--arrow:before {
  border-left: 2px solid #223b58;
}

.location-display--arrow:after {
  animation: .25s ease-in .75s forwards foldoutarrowright;
  transform: rotate(0);
}

.location-display--dot {
  z-index: 20;
  background: #cde4ff;
  border: 2px solid #0073fa;
  border-radius: 50%;
  width: .6em;
  height: .6em;
  position: absolute;
  top: .5em;
  left: 0;
}

.location-display--color-black .location-display--dot {
  background: #fff;
  border: 2px solid #223b58;
}

.location-display--hide-arrow.location-display--a:after, .location-display--hide-arrow .location-display--dot, .location-display--hide-arrow .location-display--arrow {
  display: none;
}

.location-display--b .location-display--dot {
  background: #0073fa;
}

.location-display--b.location-display--color-black .location-display--dot {
  background: #223b58;
}

.location-display--b.location-display--color-white .location-display--dot {
  background: #fff;
  border: 2px solid #223b58;
}

.location-display--header {
  font-weight: bold;
}

.location-display--size-big .location-display--header {
  font-size: 1.2em;
}

.card--tr-business .location-display {
  margin-top: 0;
  padding: 0 0 0 1.25em;
}

.location-display .location-labels {
  margin-bottom: .5em;
}

@media (min-width: 768px) {
  .location-display .location-labels {
    max-width: calc(100% - 130px);
    margin-top: -.9em;
  }

  .source-flow--business .location-display .location-labels {
    margin-top: 0;
  }
}

@keyframes fadeinarrow {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes foldoutarrowleft {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes foldoutarrowright {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-45deg);
  }
}

.location-marker {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
}

.location-marker--label {
  z-index: 5;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #aebfd4;
  border-radius: 20px;
  padding: 0 .6em;
  font-size: 1.1em;
  line-height: 1.6em;
  position: absolute;
  transform: translate3d(-50%, -2.5em, 0);
  box-shadow: 0 8px 10px rgba(0, 0, 0, .2);
}

.location-marker--driver .location-marker--label {
  z-index: 10;
  margin-left: .2em;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 1em, 0);
}

@media print {
  .location-marker--label {
    display: none;
  }
}

.location-marker--icon {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
}

.step-indicator {
  color: #0073fa;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.step-indicator--circle {
  text-align: center;
  background: #a2cdff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: .75em;
  padding-bottom: .1em;
  font-size: 2.8rem;
  display: flex;
}

@media (min-width: 768px) {
  .step-indicator--circle {
    width: 38px;
    height: 38px;
    font-size: 3.2rem;
  }
}

.step-indicator--title {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.step-indicator .bubble-wrapper {
  margin-left: auto;
  line-height: .8;
}

@media (min-width: 768px) {
  .step-indicator .bubble-wrapper:hover .bubble, .step-indicator .bubble-wrapper:hover .question-mark {
    fill: #00d896;
  }
}

.simple-switch {
  text-transform: capitalize;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.simple-switch--button {
  background-color: #fff;
  border: 1px solid #aebfd4;
  border-radius: .5em;
  width: 2.2em;
  height: 1em;
  margin-right: .5em;
  position: relative;
}

.simple-switch--button:after {
  content: "";
  background-color: #7c90a8;
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  transition: all .15s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-.2em, -.15em, 0);
}

.simple-switch--button--active:after {
  background-color: #00d896;
  transform: translate3d(1em, -.15em, 0);
}

.share-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-size: 100%;
  border: none;
  width: 3em;
  height: 3em;
  margin-right: 1em;
  display: inline-block;
}

.share-button:last-child {
  margin-right: none;
}

.share-button--facebook {
  background-image: url("https://www.brenger.nl/img/icons/facebook-green.svg");
}

.share-button--linkedin {
  background-image: url("https://www.brenger.nl/img/icons/linkedin-green.svg");
}

.share-button--email {
  background-image: url("https://www.brenger.nl/img/icons/email-green.svg");
}

.share-button--pinterest {
  background-image: url("https://www.brenger.nl/img/icons/pinterest-green.svg");
}

.share-button--twitter {
  background-image: url("https://www.brenger.nl/img/icons/twitter-green.svg");
}

.share-button--whatsapp {
  background-image: url("https://www.brenger.nl/img/icons/phone-green.svg");
}

@media (min-width: 768px) {
  .share-button--whatsapp {
    display: none;
  }
}

@font-face {
  font-family: bariol;
  src: url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff2") format("woff2"), url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

html {
  font-size: 62.5%;
}

main {
  display: block;
}

body {
  color: #223b58;
  margin: 0;
  font-family: bariol, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.6rem;
}

@media (min-width: 768px) {
  body {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

body.overflow-hidden {
  overflow: hidden;
}

b, strong {
  font-weight: bold;
}

small {
  font-size: .75em;
  line-height: 1.45em;
  display: block;
}

a {
  color: #0073fa;
}

@media (min-width: 768px) {
  a[href*="tel:"] {
    color: inherit;
    pointer-events: none;
    text-decoration: none;
  }
}

form {
  margin-bottom: 2em;
}

p {
  margin: 0 0 2.6rem;
}

@media (min-width: 768px) {
  p {
    margin: 0 0 2.8rem;
  }
}

p.lead {
  margin: 0 0 3rem;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

@media (min-width: 768px) {
  p.lead {
    margin: 0 0 4rem;
    font-size: 2.5rem;
    line-height: 4rem;
  }
}

table {
  border: none;
  margin: 0 0 .5em;
  padding: 0;
}

table tr td {
  vertical-align: top;
  border: none;
  padding: .5em;
}

table tr:nth-child(2n) {
  background: none;
}

table tr:nth-child(odd) {
  background: #e2eaf2;
}

.hide, .hidden {
  display: none;
}

.hide--sm-down, .hide-mobile, .hidden--sm-down, .hidden-mobile {
  display: none !important;
}

@media (min-width: 576px) {
  .hide--sm-down, .hide-mobile, .hidden--sm-down, .hidden-mobile {
    display: block !important;
  }
}

.hide--md-down, .hidden--md-down {
  display: none;
}

@media (min-width: 768px) {
  .hide--md-down, .hidden--md-down {
    display: block;
  }

  .hide--md-up, .hidden--md-up {
    display: none;
  }
}

@media (min-width: 992px) {
  .hide--lg-up, .hidden--lg-up {
    display: none;
  }
}

.show-mobile {
  display: block !important;
}

@media (min-width: 576px) {
  .show-mobile {
    display: none !important;
  }
}

.bg--primary {
  background-color: #0073fa;
}

.text--primary, .text--blue {
  color: #0073fa;
}

.text--secondary, .text--green {
  color: #00d896;
}

.text--yellow {
  color: #ffc63c;
}

.text--orange {
  color: #f59023;
}

.text--red {
  color: #e10000;
}

.text--grey {
  color: #aebfd4;
}

.text--grey-darker {
  color: #7c90a8;
}

.text--white {
  color: #fff;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--explain {
  font-size: .75em;
  font-style: italic;
  line-height: 1.2em;
}

.text--bold {
  font-weight: bold;
}

.text--normal {
  font-family: bariol, Arial, sans-serif;
}

.text--underline {
  text-decoration: underline;
}

.bold--primary b, .bold--blue b {
  color: #0073fa;
}

.bold--secondary b, .bold--green b {
  color: #00d896;
}

.indent--tile {
  padding-left: 20px;
}

@media (min-width: 768px) {
  .indent--tile {
    padding-left: 25px;
  }
}

.indent--gutter {
  padding-left: 15px;
}

.mt-gtr-neg {
  margin-top: -15px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0-25 {
  padding-top: .25em;
}

.pt-0-5 {
  padding-top: .5em;
}

.pt-1 {
  padding-top: 1em;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0-25 {
  padding-bottom: .25em;
}

.pb-0-5 {
  padding-bottom: .5em;
}

.pb-1 {
  padding-bottom: 1em;
}

@media (min-width: 768px) {
  .md-pb-0 {
    padding-bottom: 0;
  }

  .md-pb-1 {
    padding-bottom: 1em;
  }
}

.pl-0 {
  padding-left: 0;
}

.pl-0-25 {
  padding-left: .25em;
}

.pl-0-5 {
  padding-left: .5em;
}

.pl-1, .px-4 {
  padding-left: 1em;
}

.gap-r-0-25 {
  row-gap: .25em;
}

.gap-r-0-5 {
  row-gap: .5em;
}

.gap-r-1 {
  row-gap: 1em;
}

.gap-c-0-25 {
  column-gap: .25em;
}

.gap-c-0-5 {
  column-gap: .5em;
}

.gap-c-1 {
  column-gap: 1em;
}

.flex {
  display: flex;
}

.flex--vc {
  align-items: center;
}

.flex--vs {
  align-items: flex-start;
}

.flex--hc {
  justify-content: center;
}

.flex--c {
  justify-content: center;
  align-items: center;
}

.flex--sb {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.grow--1 {
  flex-grow: 1;
}

.inline-block {
  display: inline-block;
}

.show-print {
  display: none;
}

.relative {
  position: relative;
}

.sr-only, .visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

[class*="trigger"] {
  cursor: pointer;
}

.first-letter--caps:first-letter {
  text-transform: capitalize;
}

@media print {
  .hide-print {
    padding: 0 !important;
    display: none !important;
  }

  .show-print {
    display: block !important;
  }
}

.delay-index-0 {
  animation-delay: .1s;
}

.delay-index-1 {
  animation-delay: .22s;
}

.delay-index-2 {
  animation-delay: .34s;
}

.delay-index-3 {
  animation-delay: .46s;
}

.delay-index-4 {
  animation-delay: .58s;
}

.delay-index-5 {
  animation-delay: .7s;
}

.delay-index-6 {
  animation-delay: .82s;
}

.delay-index-7 {
  animation-delay: .94s;
}

.delay-index-8 {
  animation-delay: 1.06s;
}

.delay-index-9 {
  animation-delay: 1.18s;
}

.delay-index-10 {
  animation-delay: 1.3s;
}

.delay-index-11 {
  animation-delay: 1.42s;
}

.delay-index-12 {
  animation-delay: 1.54s;
}

.delay-index-13 {
  animation-delay: 1.66s;
}

.delay-index-14 {
  animation-delay: 1.78s;
}

.delay-index-15 {
  animation-delay: 1.9s;
}

.delay-index-16 {
  animation-delay: 2.02s;
}

.delay-index-17 {
  animation-delay: 2.14s;
}

.delay-index-18 {
  animation-delay: 2.26s;
}

.delay-index-19 {
  animation-delay: 2.38s;
}

.delay-index-20 {
  animation-delay: 2.5s;
}

.delay-index-0 .IconStar:first-child {
  animation-delay: .2s;
}

.delay-index-0 .IconStar:nth-child(2) {
  animation-delay: .3s;
}

.delay-index-0 .IconStar:nth-child(3) {
  animation-delay: .4s;
}

.delay-index-0 .IconStar:nth-child(4) {
  animation-delay: .5s;
}

.delay-index-0 .IconStar:nth-child(5) {
  animation-delay: .6s;
}

.delay-index-1 .IconStar:first-child {
  animation-delay: .5s;
}

.delay-index-1 .IconStar:nth-child(2) {
  animation-delay: .6s;
}

.delay-index-1 .IconStar:nth-child(3) {
  animation-delay: .7s;
}

.delay-index-1 .IconStar:nth-child(4) {
  animation-delay: .8s;
}

.delay-index-1 .IconStar:nth-child(5) {
  animation-delay: .9s;
}

.delay-index-2 .IconStar:first-child {
  animation-delay: .8s;
}

.delay-index-2 .IconStar:nth-child(2) {
  animation-delay: .9s;
}

.delay-index-2 .IconStar:nth-child(3) {
  animation-delay: 1s;
}

.delay-index-2 .IconStar:nth-child(4) {
  animation-delay: 1.1s;
}

.delay-index-2 .IconStar:nth-child(5) {
  animation-delay: 1.2s;
}

.delay-index-3 .IconStar:first-child {
  animation-delay: 1.1s;
}

.delay-index-3 .IconStar:nth-child(2) {
  animation-delay: 1.2s;
}

.delay-index-3 .IconStar:nth-child(3) {
  animation-delay: 1.3s;
}

.delay-index-3 .IconStar:nth-child(4) {
  animation-delay: 1.4s;
}

.delay-index-3 .IconStar:nth-child(5) {
  animation-delay: 1.5s;
}

.delay-index-4 .IconStar:first-child {
  animation-delay: 1.4s;
}

.delay-index-4 .IconStar:nth-child(2) {
  animation-delay: 1.5s;
}

.delay-index-4 .IconStar:nth-child(3) {
  animation-delay: 1.6s;
}

.delay-index-4 .IconStar:nth-child(4) {
  animation-delay: 1.7s;
}

.delay-index-4 .IconStar:nth-child(5) {
  animation-delay: 1.8s;
}

.delay-index-5 .IconStar:first-child {
  animation-delay: 1.7s;
}

.delay-index-5 .IconStar:nth-child(2) {
  animation-delay: 1.8s;
}

.delay-index-5 .IconStar:nth-child(3) {
  animation-delay: 1.9s;
}

.delay-index-5 .IconStar:nth-child(4) {
  animation-delay: 2s;
}

.delay-index-5 .IconStar:nth-child(5) {
  animation-delay: 2.1s;
}

.delay-index-6 .IconStar:first-child {
  animation-delay: 2s;
}

.delay-index-6 .IconStar:nth-child(2) {
  animation-delay: 2.1s;
}

.delay-index-6 .IconStar:nth-child(3) {
  animation-delay: 2.2s;
}

.delay-index-6 .IconStar:nth-child(4) {
  animation-delay: 2.3s;
}

.delay-index-6 .IconStar:nth-child(5) {
  animation-delay: 2.4s;
}

.delay-index-7 .IconStar:first-child {
  animation-delay: 2.3s;
}

.delay-index-7 .IconStar:nth-child(2) {
  animation-delay: 2.4s;
}

.delay-index-7 .IconStar:nth-child(3) {
  animation-delay: 2.5s;
}

.delay-index-7 .IconStar:nth-child(4) {
  animation-delay: 2.6s;
}

.delay-index-7 .IconStar:nth-child(5) {
  animation-delay: 2.7s;
}

.delay-index-8 .IconStar:first-child {
  animation-delay: 2.6s;
}

.delay-index-8 .IconStar:nth-child(2) {
  animation-delay: 2.7s;
}

.delay-index-8 .IconStar:nth-child(3) {
  animation-delay: 2.8s;
}

.delay-index-8 .IconStar:nth-child(4) {
  animation-delay: 2.9s;
}

.delay-index-8 .IconStar:nth-child(5) {
  animation-delay: 3s;
}

.delay-index-9 .IconStar:first-child {
  animation-delay: 2.9s;
}

.delay-index-9 .IconStar:nth-child(2) {
  animation-delay: 3s;
}

.delay-index-9 .IconStar:nth-child(3) {
  animation-delay: 3.1s;
}

.delay-index-9 .IconStar:nth-child(4) {
  animation-delay: 3.2s;
}

.delay-index-9 .IconStar:nth-child(5) {
  animation-delay: 3.3s;
}

.delay-index-10 .IconStar:first-child {
  animation-delay: 3.2s;
}

.delay-index-10 .IconStar:nth-child(2) {
  animation-delay: 3.3s;
}

.delay-index-10 .IconStar:nth-child(3) {
  animation-delay: 3.4s;
}

.delay-index-10 .IconStar:nth-child(4) {
  animation-delay: 3.5s;
}

.delay-index-10 .IconStar:nth-child(5) {
  animation-delay: 3.6s;
}

.delay-index-11 .IconStar:first-child {
  animation-delay: 3.5s;
}

.delay-index-11 .IconStar:nth-child(2) {
  animation-delay: 3.6s;
}

.delay-index-11 .IconStar:nth-child(3) {
  animation-delay: 3.7s;
}

.delay-index-11 .IconStar:nth-child(4) {
  animation-delay: 3.8s;
}

.delay-index-11 .IconStar:nth-child(5) {
  animation-delay: 3.9s;
}

.delay-index-12 .IconStar:first-child {
  animation-delay: 3.8s;
}

.delay-index-12 .IconStar:nth-child(2) {
  animation-delay: 3.9s;
}

.delay-index-12 .IconStar:nth-child(3) {
  animation-delay: 4s;
}

.delay-index-12 .IconStar:nth-child(4) {
  animation-delay: 4.1s;
}

.delay-index-12 .IconStar:nth-child(5) {
  animation-delay: 4.2s;
}

.delay-index-13 .IconStar:first-child {
  animation-delay: 4.1s;
}

.delay-index-13 .IconStar:nth-child(2) {
  animation-delay: 4.2s;
}

.delay-index-13 .IconStar:nth-child(3) {
  animation-delay: 4.3s;
}

.delay-index-13 .IconStar:nth-child(4) {
  animation-delay: 4.4s;
}

.delay-index-13 .IconStar:nth-child(5) {
  animation-delay: 4.5s;
}

.delay-index-14 .IconStar:first-child {
  animation-delay: 4.4s;
}

.delay-index-14 .IconStar:nth-child(2) {
  animation-delay: 4.5s;
}

.delay-index-14 .IconStar:nth-child(3) {
  animation-delay: 4.6s;
}

.delay-index-14 .IconStar:nth-child(4) {
  animation-delay: 4.7s;
}

.delay-index-14 .IconStar:nth-child(5) {
  animation-delay: 4.8s;
}

.delay-index-15 .IconStar:first-child {
  animation-delay: 4.7s;
}

.delay-index-15 .IconStar:nth-child(2) {
  animation-delay: 4.8s;
}

.delay-index-15 .IconStar:nth-child(3) {
  animation-delay: 4.9s;
}

.delay-index-15 .IconStar:nth-child(4) {
  animation-delay: 5s;
}

.delay-index-15 .IconStar:nth-child(5) {
  animation-delay: 5.1s;
}

.delay-index-16 .IconStar:first-child {
  animation-delay: 5s;
}

.delay-index-16 .IconStar:nth-child(2) {
  animation-delay: 5.1s;
}

.delay-index-16 .IconStar:nth-child(3) {
  animation-delay: 5.2s;
}

.delay-index-16 .IconStar:nth-child(4) {
  animation-delay: 5.3s;
}

.delay-index-16 .IconStar:nth-child(5) {
  animation-delay: 5.4s;
}

.delay-index-17 .IconStar:first-child {
  animation-delay: 5.3s;
}

.delay-index-17 .IconStar:nth-child(2) {
  animation-delay: 5.4s;
}

.delay-index-17 .IconStar:nth-child(3) {
  animation-delay: 5.5s;
}

.delay-index-17 .IconStar:nth-child(4) {
  animation-delay: 5.6s;
}

.delay-index-17 .IconStar:nth-child(5) {
  animation-delay: 5.7s;
}

.delay-index-18 .IconStar:first-child {
  animation-delay: 5.6s;
}

.delay-index-18 .IconStar:nth-child(2) {
  animation-delay: 5.7s;
}

.delay-index-18 .IconStar:nth-child(3) {
  animation-delay: 5.8s;
}

.delay-index-18 .IconStar:nth-child(4) {
  animation-delay: 5.9s;
}

.delay-index-18 .IconStar:nth-child(5) {
  animation-delay: 6s;
}

.delay-index-19 .IconStar:first-child {
  animation-delay: 5.9s;
}

.delay-index-19 .IconStar:nth-child(2) {
  animation-delay: 6s;
}

.delay-index-19 .IconStar:nth-child(3) {
  animation-delay: 6.1s;
}

.delay-index-19 .IconStar:nth-child(4) {
  animation-delay: 6.2s;
}

.delay-index-19 .IconStar:nth-child(5) {
  animation-delay: 6.3s;
}

.delay-index-20 .IconStar:first-child {
  animation-delay: 6.2s;
}

.delay-index-20 .IconStar:nth-child(2) {
  animation-delay: 6.3s;
}

.delay-index-20 .IconStar:nth-child(3) {
  animation-delay: 6.4s;
}

.delay-index-20 .IconStar:nth-child(4) {
  animation-delay: 6.5s;
}

.delay-index-20 .IconStar:nth-child(5) {
  animation-delay: 6.6s;
}

.card-review:nth-child(0) .IconStar:first-child {
  animation-delay: -.1s;
}

.card-review:nth-child(0) .IconStar:nth-child(2) {
  animation-delay: 0s;
}

.card-review:nth-child(0) .IconStar:nth-child(3) {
  animation-delay: .1s;
}

.card-review:nth-child(0) .IconStar:nth-child(4) {
  animation-delay: .2s;
}

.card-review:nth-child(0) .IconStar:nth-child(5) {
  animation-delay: .3s;
}

.card-review:first-child .IconStar:first-child {
  animation-delay: .2s;
}

.card-review:first-child .IconStar:nth-child(2) {
  animation-delay: .3s;
}

.card-review:first-child .IconStar:nth-child(3) {
  animation-delay: .4s;
}

.card-review:first-child .IconStar:nth-child(4) {
  animation-delay: .5s;
}

.card-review:first-child .IconStar:nth-child(5) {
  animation-delay: .6s;
}

.card-review:nth-child(2) .IconStar:first-child {
  animation-delay: .5s;
}

.card-review:nth-child(2) .IconStar:nth-child(2) {
  animation-delay: .6s;
}

.card-review:nth-child(2) .IconStar:nth-child(3) {
  animation-delay: .7s;
}

.card-review:nth-child(2) .IconStar:nth-child(4) {
  animation-delay: .8s;
}

.card-review:nth-child(2) .IconStar:nth-child(5) {
  animation-delay: .9s;
}

.card-review:nth-child(3) .IconStar:first-child {
  animation-delay: .8s;
}

.card-review:nth-child(3) .IconStar:nth-child(2) {
  animation-delay: .9s;
}

.card-review:nth-child(3) .IconStar:nth-child(3) {
  animation-delay: 1s;
}

.card-review:nth-child(3) .IconStar:nth-child(4) {
  animation-delay: 1.1s;
}

.card-review:nth-child(3) .IconStar:nth-child(5) {
  animation-delay: 1.2s;
}

.card-review:nth-child(4) .IconStar:first-child {
  animation-delay: 1.1s;
}

.card-review:nth-child(4) .IconStar:nth-child(2) {
  animation-delay: 1.2s;
}

.card-review:nth-child(4) .IconStar:nth-child(3) {
  animation-delay: 1.3s;
}

.card-review:nth-child(4) .IconStar:nth-child(4) {
  animation-delay: 1.4s;
}

.card-review:nth-child(4) .IconStar:nth-child(5) {
  animation-delay: 1.5s;
}

.card-review:nth-child(5) .IconStar:first-child {
  animation-delay: 1.4s;
}

.card-review:nth-child(5) .IconStar:nth-child(2) {
  animation-delay: 1.5s;
}

.card-review:nth-child(5) .IconStar:nth-child(3) {
  animation-delay: 1.6s;
}

.card-review:nth-child(5) .IconStar:nth-child(4) {
  animation-delay: 1.7s;
}

.card-review:nth-child(5) .IconStar:nth-child(5) {
  animation-delay: 1.8s;
}

.card-review:nth-child(6) .IconStar:first-child {
  animation-delay: 1.7s;
}

.card-review:nth-child(6) .IconStar:nth-child(2) {
  animation-delay: 1.8s;
}

.card-review:nth-child(6) .IconStar:nth-child(3) {
  animation-delay: 1.9s;
}

.card-review:nth-child(6) .IconStar:nth-child(4) {
  animation-delay: 2s;
}

.card-review:nth-child(6) .IconStar:nth-child(5) {
  animation-delay: 2.1s;
}

.card-review:nth-child(7) .IconStar:first-child {
  animation-delay: 2s;
}

.card-review:nth-child(7) .IconStar:nth-child(2) {
  animation-delay: 2.1s;
}

.card-review:nth-child(7) .IconStar:nth-child(3) {
  animation-delay: 2.2s;
}

.card-review:nth-child(7) .IconStar:nth-child(4) {
  animation-delay: 2.3s;
}

.card-review:nth-child(7) .IconStar:nth-child(5) {
  animation-delay: 2.4s;
}

.card-review:nth-child(8) .IconStar:first-child {
  animation-delay: 2.3s;
}

.card-review:nth-child(8) .IconStar:nth-child(2) {
  animation-delay: 2.4s;
}

.card-review:nth-child(8) .IconStar:nth-child(3) {
  animation-delay: 2.5s;
}

.card-review:nth-child(8) .IconStar:nth-child(4) {
  animation-delay: 2.6s;
}

.card-review:nth-child(8) .IconStar:nth-child(5) {
  animation-delay: 2.7s;
}

.card-review:nth-child(9) .IconStar:first-child {
  animation-delay: 2.6s;
}

.card-review:nth-child(9) .IconStar:nth-child(2) {
  animation-delay: 2.7s;
}

.card-review:nth-child(9) .IconStar:nth-child(3) {
  animation-delay: 2.8s;
}

.card-review:nth-child(9) .IconStar:nth-child(4) {
  animation-delay: 2.9s;
}

.card-review:nth-child(9) .IconStar:nth-child(5) {
  animation-delay: 3s;
}

.card-review:nth-child(10) .IconStar:first-child {
  animation-delay: 2.9s;
}

.card-review:nth-child(10) .IconStar:nth-child(2) {
  animation-delay: 3s;
}

.card-review:nth-child(10) .IconStar:nth-child(3) {
  animation-delay: 3.1s;
}

.card-review:nth-child(10) .IconStar:nth-child(4) {
  animation-delay: 3.2s;
}

.card-review:nth-child(10) .IconStar:nth-child(5) {
  animation-delay: 3.3s;
}

.card-review:nth-child(11) .IconStar:first-child {
  animation-delay: 3.2s;
}

.card-review:nth-child(11) .IconStar:nth-child(2) {
  animation-delay: 3.3s;
}

.card-review:nth-child(11) .IconStar:nth-child(3) {
  animation-delay: 3.4s;
}

.card-review:nth-child(11) .IconStar:nth-child(4) {
  animation-delay: 3.5s;
}

.card-review:nth-child(11) .IconStar:nth-child(5) {
  animation-delay: 3.6s;
}

.card-review:nth-child(12) .IconStar:first-child {
  animation-delay: 3.5s;
}

.card-review:nth-child(12) .IconStar:nth-child(2) {
  animation-delay: 3.6s;
}

.card-review:nth-child(12) .IconStar:nth-child(3) {
  animation-delay: 3.7s;
}

.card-review:nth-child(12) .IconStar:nth-child(4) {
  animation-delay: 3.8s;
}

.card-review:nth-child(12) .IconStar:nth-child(5) {
  animation-delay: 3.9s;
}

.card-review:nth-child(13) .IconStar:first-child {
  animation-delay: 3.8s;
}

.card-review:nth-child(13) .IconStar:nth-child(2) {
  animation-delay: 3.9s;
}

.card-review:nth-child(13) .IconStar:nth-child(3) {
  animation-delay: 4s;
}

.card-review:nth-child(13) .IconStar:nth-child(4) {
  animation-delay: 4.1s;
}

.card-review:nth-child(13) .IconStar:nth-child(5) {
  animation-delay: 4.2s;
}

.card-review:nth-child(14) .IconStar:first-child {
  animation-delay: 4.1s;
}

.card-review:nth-child(14) .IconStar:nth-child(2) {
  animation-delay: 4.2s;
}

.card-review:nth-child(14) .IconStar:nth-child(3) {
  animation-delay: 4.3s;
}

.card-review:nth-child(14) .IconStar:nth-child(4) {
  animation-delay: 4.4s;
}

.card-review:nth-child(14) .IconStar:nth-child(5) {
  animation-delay: 4.5s;
}

.card-review:nth-child(15) .IconStar:first-child {
  animation-delay: 4.4s;
}

.card-review:nth-child(15) .IconStar:nth-child(2) {
  animation-delay: 4.5s;
}

.card-review:nth-child(15) .IconStar:nth-child(3) {
  animation-delay: 4.6s;
}

.card-review:nth-child(15) .IconStar:nth-child(4) {
  animation-delay: 4.7s;
}

.card-review:nth-child(15) .IconStar:nth-child(5) {
  animation-delay: 4.8s;
}

.card-review:nth-child(16) .IconStar:first-child {
  animation-delay: 4.7s;
}

.card-review:nth-child(16) .IconStar:nth-child(2) {
  animation-delay: 4.8s;
}

.card-review:nth-child(16) .IconStar:nth-child(3) {
  animation-delay: 4.9s;
}

.card-review:nth-child(16) .IconStar:nth-child(4) {
  animation-delay: 5s;
}

.card-review:nth-child(16) .IconStar:nth-child(5) {
  animation-delay: 5.1s;
}

.card-review:nth-child(17) .IconStar:first-child {
  animation-delay: 5s;
}

.card-review:nth-child(17) .IconStar:nth-child(2) {
  animation-delay: 5.1s;
}

.card-review:nth-child(17) .IconStar:nth-child(3) {
  animation-delay: 5.2s;
}

.card-review:nth-child(17) .IconStar:nth-child(4) {
  animation-delay: 5.3s;
}

.card-review:nth-child(17) .IconStar:nth-child(5) {
  animation-delay: 5.4s;
}

.card-review:nth-child(18) .IconStar:first-child {
  animation-delay: 5.3s;
}

.card-review:nth-child(18) .IconStar:nth-child(2) {
  animation-delay: 5.4s;
}

.card-review:nth-child(18) .IconStar:nth-child(3) {
  animation-delay: 5.5s;
}

.card-review:nth-child(18) .IconStar:nth-child(4) {
  animation-delay: 5.6s;
}

.card-review:nth-child(18) .IconStar:nth-child(5) {
  animation-delay: 5.7s;
}

.card-review:nth-child(19) .IconStar:first-child {
  animation-delay: 5.6s;
}

.card-review:nth-child(19) .IconStar:nth-child(2) {
  animation-delay: 5.7s;
}

.card-review:nth-child(19) .IconStar:nth-child(3) {
  animation-delay: 5.8s;
}

.card-review:nth-child(19) .IconStar:nth-child(4) {
  animation-delay: 5.9s;
}

.card-review:nth-child(19) .IconStar:nth-child(5) {
  animation-delay: 6s;
}

.card-review:nth-child(20) .IconStar:first-child {
  animation-delay: 5.9s;
}

.card-review:nth-child(20) .IconStar:nth-child(2) {
  animation-delay: 6s;
}

.card-review:nth-child(20) .IconStar:nth-child(3) {
  animation-delay: 6.1s;
}

.card-review:nth-child(20) .IconStar:nth-child(4) {
  animation-delay: 6.2s;
}

.card-review:nth-child(20) .IconStar:nth-child(5) {
  animation-delay: 6.3s;
}

@keyframes starRotateIn {
  0% {
    visibility: visible;
    opacity: 0;
    transform: rotate(100deg)translate3d(30px, 0, 0)scale(.75);
  }

  50% {
    visibility: visible;
    opacity: 1;
    transform: rotate(25deg)translate3d(5px, 0, 0)scale(1.25);
  }

  100% {
    visibility: visible;
    opacity: 1;
    transform: rotate(0)translate3d(0, 0, 0)scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, .5em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInGrey {
  0% {
    opacity: 0;
    filter: grayscale();
  }

  25% {
    opacity: 1;
    filter: grayscale();
  }

  100% {
    opacity: 1;
    filter: grayscale(0%);
  }
}

@keyframes spinIn {
  0% {
    transform: rotate(0)scale(0)translate3d(-100px, 100px, 0);
  }

  75% {
    transform: rotate(360deg)scale(1.2)translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(720deg)scale(1)translate3d(0, 0, 0);
  }
}

.btn-no-style {
  font-size: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  font-family: inherit;
}

.summary-line {
  justify-content: space-between;
  display: flex;
}

.summary-line--in-header .summary-line {
  display: block;
}

.summary-line--in-header .summary-line > div {
  display: inline-block;
}

.summary-line div:first-letter {
  text-transform: uppercase;
}

.summary-line .adds-price {
  color: #0073fa;
  text-align: right;
  width: 80px;
  display: inline-block;
}

.summary-line--in-header .summary-line .adds-price {
  white-space: nowrap;
  width: 70px;
}

.summary-line--in-header {
  font-size: .9em;
  line-height: 1.5;
  display: none;
}

.show-previous-choices-list .summary-line--in-header {
  display: block;
}

@media (min-width: 992px) {
  .summary-line--in-header, .show-previous-choices-list .summary-line--in-header {
    display: none;
  }
}

.van-assets-image {
  width: 181px;
}

.modal--style-white .van-assets-image {
  margin-left: -20px;
}

.external-widget {
  margin-top: 100px;
}

.app-render .external-widget {
  display: none;
}

.list--open-dot {
  margin-top: 0;
  padding: 0 0 0 1.25em;
  position: relative;
}

.list--open-dot:after {
  content: "";
  z-index: 20;
  background: #fff;
  border: 2px solid #223b58;
  border-radius: 50%;
  width: .6em;
  height: .6em;
  position: absolute;
  top: .5em;
  left: 0;
}

.transport-request-flow #fc_frame {
  opacity: 0;
  visibility: hidden;
  transition: all .25s;
  display: block;
  transform: translate3d(6px, -56px, 0);
}

.transport-request-flow #fc_frame.fc-open {
  max-height: calc(100vh - 80px) !important;
}

@media (min-width: 576px) {
  .transport-request-flow #fc_frame {
    transform: translate3d(6px, 30px, 0);
  }

  .chat--is-visible .transport-request-flow #fc_frame {
    transform: translate3d(6px, 0, 0);
  }
}

#fc_frame {
  opacity: 0;
  visibility: hidden;
}

.chat--is-visible #fc_frame {
  opacity: 1;
  visibility: visible;
  transform: translate3d(6px, -86px, 0);
}

.app-main-wrapper {
  position: relative;
}

@media print {
  .app-main-wrapper {
    font-size: 14px;
  }
}

.app-main-wrapper .main-centered-content {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
}

#root-customer-dialog {
  z-index: 10000;
  position: relative;
}

@media (max-width: 767.98px) {
  .path-node .region .hero .hero-cta-container {
    display: block !important;
  }
}

.spinner-parent {
  position: relative;
}

.spinner-parent.xs {
  min-height: 200px;
}

.spinning-smile {
  transform-origin: 30%;
  width: .8em;
  height: .8em;
  margin-right: .4em;
  animation: .33s linear infinite spin;
  display: inline-block;
  position: relative;
  left: .6em;
}

.spinning-smile.centered {
  margin: -.4em 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .api-settings--header {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .api-settings--header h3 {
    margin: 0;
  }
}

.floor-indication {
  text-align: center;
  color: #0073fa;
  border-bottom: 1px solid #0073fa;
  width: 1.2em;
  margin-left: -.2em;
  padding-bottom: .1em;
  font-size: 1.5em;
}

.another-collection-date {
  color: #0073fa;
  cursor: pointer;
  font-size: 1.2em;
  text-decoration: underline;
}

.no-available-auction-pickup-date {
  background: #f1c40f;
  border: 1px solid #f39c12;
  border-radius: 5px;
  padding: 5px 15px;
}

.more-days .icon-wrapper svg {
  width: .8em;
  height: .8em;
}

.invoice-item a {
  margin: -20px 0 20px;
  display: block;
}

@media (max-width: 767.98px) {
  .sheet-layout--footer .price-display .dd_tooltip-body {
    display: none;
  }
}

.sheet-layout--sidebar .summary {
  flex-direction: column;
  max-height: 100%;
  display: flex;
  overflow: hidden;
}

.sheet-layout--sidebar .summary--body {
  height: 100%;
  overflow: auto;
}

.sheet-layout--sidebar .summary--hide-prices .adds-price, .sheet-layout--sidebar .summary--hide-prices .price-display {
  display: none;
}

.sheet-layout--sidebar .is-guaranteed {
  opacity: .8;
  margin: 0;
}

.sheet-layout--sidebar .is-guaranteed svg {
  width: 12px;
  height: 12px;
}

.sheet-layout--sidebar .is-guaranteed .IconCheckMark path {
  fill: #00d896;
}

.sheet-layout--sidebar .is-guaranteed .IconCross path {
  fill: #c10707;
}

.show-usps-test .sheet-layout--sidebar .usps-present .summary {
  height: auto;
  display: block;
  overflow: auto;
}

.show-usps-test .sheet-layout--sidebar .usps-present .summary--body {
  height: auto;
}

.help-content h3 {
  font-family: bariol, Arial, sans-serif;
}

.help-content .close-chat {
  display: none;
}

.chat--is-visible .help-content .close-chat {
  display: inline-block;
}

.chat--is-visible .help-content .open-chat {
  display: none;
}

.general-help--trigger {
  margin-left: auto;
  font-family: bariol, Arial, sans-serif;
  font-size: .8em;
}

.general-help--trigger svg {
  margin-left: .2em;
  position: relative;
  top: .25em;
}

.general-help--trigger path {
  fill: #0073fa;
}

.time-pref-modal .btn:last-child {
  margin: 0;
}

@media only screen and (max-device-width: 667px), only screen and (max-device-width: 900px) and (min-device-width: 668px) and (orientation: landscape) {
  #fc_frame.h-open-container {
    height: 85% !important;
    min-height: 85px !important;
    max-height: 85vh !important;
  }
}

@media only screen and (max-device-width: 667px), only screen and (max-device-width: 900px) and (min-device-width: 668px) and (orientation: portrait) {
  #fc_frame.h-open-container {
    height: 85% !important;
    min-height: 85px !important;
    max-height: 85vh !important;
  }
}

b.old-price {
  display: inline-block;
  position: relative;
}

b.old-price:after {
  content: "";
  border-bottom: 1px solid #0073fa;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

table.price-table {
  color: #0073fa;
  border-collapse: collapse;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

table.price-table tr:last-child {
  font-weight: bold;
}

table.price-table tr:nth-child(odd) {
  background-color: #cde4ff;
}

table.price-table tr:nth-child(2n) {
  background-color: #ecf5ff;
}

table.price-table svg {
  width: 1em;
  height: 1em;
  position: relative;
  top: .1em;
  left: 2px;
}

@media (min-width: 992px) {
  table.price-table svg {
    display: none;
  }
}

.blocked-reason-list-messages .message {
  margin-bottom: .2em;
}

.app-main-wrapper .internal-widget .brenger-tr-widget--wrapper .brenger-autocomplete-widget {
  border-radius: 10px;
  margin-bottom: 15px;
  padding: .5em .9em;
  box-shadow: 0 8px 10px rgba(174, 191, 212, .2);
}

.app-main-wrapper .internal-widget .brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete {
  left: -.9em;
  right: -.9em;
}

.app-main-wrapper .internal-widget .brenger-tr-widget {
  box-shadow: none;
  padding: 0;
}

.app-main-wrapper .internal-widget .brenger-tr-widget .btn--secondary, .app-main-wrapper .internal-widget .brenger-tr-widget--header, .app-main-wrapper .internal-widget .brenger-tr-widget svg.route, .app-main-wrapper .internal-widget .brenger-tr-widget--divider {
  display: none;
}

.app-main-wrapper .internal-widget .brenger-tr-widget .IconMarker {
  opacity: 1;
}

.app-main-wrapper .internal-widget .brenger-tr-widget .autocomplete-target .input-el--autocomplete, .app-main-wrapper .internal-widget .brenger-tr-widget .destination-fields {
  width: 100%;
  display: block;
}

.app-main-wrapper .internal-widget .brenger-tr-widget .input-el--autocomplete {
  margin-bottom: 0;
  margin-left: 0;
}

.app-main-wrapper .internal-widget .brenger-tr-widget .input-el--label {
  margin-left: 0;
}

.external-widget {
  display: block;
}

.render-app .external-widget {
  display: none;
}

.ps-config {
  position: absolute;
  inset: 0 0 73px;
}

.ps-config--new {
  padding-top: 73px;
  bottom: 0;
}

@media (min-width: 1025px) {
  .ps-config--new {
    margin-top: 73px;
  }
}

.ps-config--no-result {
  inset: 73px 0 0;
}

@media (min-width: 1025px) {
  .ps-config--no-result .ps-config--bg {
    margin-top: 73px;
  }
}

.ps-config--bg {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  height: 100%;
}

.ps-config--manual-head {
  color: #7c90a8;
  text-align: center;
  background-color: #fff;
  line-height: 67px;
  position: absolute;
  top: 0;
  left: 53px;
  right: 0;
}

@media (min-width: 1025px) {
  .ps-config--manual-head {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ps-config--content {
    width: 75%;
  }
}

.ps-config--dimensions {
  gap: .5em;
  display: flex;
}

.ps-config--dimensions-input {
  width: 100%;
  padding-right: 3em;
  font-family: bariol, Arial, sans-serif;
}

.ps-config--dimensions-input-suffix {
  margin-top: -.9em;
  position: absolute;
  top: 50%;
  right: .7em;
}

.ps-config--dimensions-header {
  margin-bottom: 1rem;
}

.ps-config--count {
  border: 1px solid #aebfd4;
  border-radius: 10px;
  width: 100%;
  padding: 14px;
}

.ps-config--count-value {
  gap: .5em;
  margin-left: auto;
}

.ps-config--count--number {
  text-align: center;
  width: 3rem;
  margin-bottom: .05em;
}

.ps-config--count .btn {
  color: #0073fa;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 1.3em;
  line-height: .66em;
}

.ps-config--count .btn[disabled] {
  color: #aacdfb;
}

.ps-config--count .btn.ps-config--count--decrease svg {
  width: 1.2rem;
  height: .2rem;
}

.ps-config--count .btn.ps-config--count--increase svg {
  width: 1.2rem;
  height: 1.2rem;
}

.ps-config--image-upload {
  border: 1px dashed #0073fa;
  border-radius: 10px;
  gap: 25px;
  padding: 25px;
}

.ps-config--image-upload.required {
  border-style: solid;
}

.ps-config--image-upload.showError {
  background-color: #fff9f9;
  border-color: #e10000;
}

.ps-config--image-upload .IconCamera {
  width: 1.5em;
  height: 1.5em;
  margin-bottom: .2em;
}

.ps-config--image-upload .IconCamera circle, .ps-config--image-upload .IconCamera path {
  fill: #0073fa;
}

.ps-config--image-display {
  position: relative;
}

.ps-config--image-display img {
  object-fit: contain;
  background-color: #e2eaf2;
  width: 100%;
  max-height: 250px;
}

.ps-config--image-display--remove {
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  padding: .25em;
  line-height: 1;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.ps-config--image-display--remove .IconCross {
  width: 1em;
  height: .75em;
}

.ps-config--choice {
  gap: 1em;
}

.ps-config--choice input {
  margin-left: 0;
  margin-right: .5em;
}

.ps-config--chips-choice {
  margin: .5em .25em 0 0;
  display: inline-block;
}

.ps-config--chips-choice input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.ps-config--chips-choice label {
  color: #0073fa;
  white-space: nowrap;
  border: 1px solid #0073fa;
  border-radius: 100px;
  padding: 0 .7em;
  font-weight: bold;
  line-height: 1.95em;
  display: inline-block;
}

.ps-config--chips-choice input:checked + label {
  color: #fff;
  background-color: #0073fa;
}

.ps-config--chips-choice input[type="checkbox"] + label:before {
  content: "";
  opacity: 0;
  visibility: hidden;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: .8em;
  height: .4em;
  margin: 0 0 0 -.7em;
  transition: all .25s;
  display: inline-block;
  transform: rotate(0)translate3d(10px, 0, 0);
}

.ps-config--chips-choice input[type="checkbox"]:checked + label:before {
  opacity: 1;
  visibility: visible;
  margin: 0 .1em 0 .3em;
  transform: rotate(-45deg)translate3d(0, -5px, 0);
}

.modal--backdrop {
  background-color: rgba(34, 59, 88, .2);
  position: fixed;
  inset: 0;
}

.ps-modal--content {
  text-align: left;
  background-color: #fff;
  position: fixed;
  inset: 0;
  overflow: auto;
}

@media (min-width: 1025px) {
  .ps-modal--content {
    border-radius: 10px;
    height: calc(100vh - 20px);
    margin: 20px auto 0;
    position: relative;
  }
}

.ps-modal--prompt.ps-modal--content {
  background-color: rgba(0, 0, 0, 0);
  height: auto;
  margin: 0;
  padding: 2.4rem;
  position: relative;
  top: 25vh;
  transform: translate3d(0, -50%, 0);
}

.ps-modal--prompt--inner {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 16px;
  overflow: hidden;
}

.ps-modal--back {
  margin-right: .3em;
  padding: 0;
}

.ps-modal--section {
  padding: 16px 24px 0;
}

.ps-modal--section:last-child {
  padding-bottom: 16px;
}

@media (min-width: 1025px) {
  .ps-modal--section {
    padding: 20px 28px 0;
  }

  .ps-modal--section:last-child {
    padding-bottom: 20px;
  }
}

.ps-modal--section.bordered {
  border-bottom: 1px solid #aebfd4;
}

.ps-modal--section.bp {
  padding-bottom: 16px;
}

@media (min-width: 1025px) {
  .ps-modal--section.bp:last-child {
    padding-bottom: 20px;
  }
}

.ps-search--head {
  flex-grow: 0;
}

.ps-search--head .input-default {
  border: none;
  padding: 0;
}

@media (min-width: 1025px) {
  .ps-search--head {
    border-top: 1px solid #aebfd4;
  }

  .ps-search--head .ps-modal--back {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .ps-search--head .ps-search--icon-mag {
    display: none;
  }
}

.ps-search--desk-head {
  display: none;
}

@media (min-width: 1025px) {
  .ps-search--desk-head {
    color: #7c90a8;
    z-index: 10;
    background: #fff;
    align-items: center;
    padding: 1.2em 28px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .ps-search--desk-head b {
    margin: auto;
  }

  .ps-search--desk-head .IconCross {
    cursor: pointer;
    width: .9em;
    height: .9em;
  }
}

.ps-search--add-manual {
  color: #7c90a8;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
  bottom: 0;
}

.ps-search--add-manual.border-top {
  border-top: 1px solid #aebfd4;
}

.ps-search--add-manual .IconSmile {
  width: .75em;
  height: .75em;
}

.ps-search--input {
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 5px;
  font-family: bariol, Arial, sans-serif;
  font-size: 20px;
}

.ps-search--input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.ps-search--input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

.ps-search--input::-webkit-search-decoration {
  display: none;
}

.ps-search--input::-webkit-search-cancel-button {
  display: none;
}

.ps-search--input::-webkit-search-results-button {
  display: none;
}

.ps-search--input::-webkit-search-results-decoration {
  display: none;
}

.ps-search--input:focus, .ps-search--input:active {
  border: none;
  outline: none;
}

.ps-search--input-clear {
  background-color: #aebfd4;
  border-radius: 100%;
  width: 1.1em;
  height: 1em;
  position: relative;
}

.ps-search--input-clear svg {
  width: .4em;
  height: .4em;
  position: absolute;
  top: .3em;
  left: .3em;
}

.ps-search--input-clear .IconCross path {
  fill: #fff;
}

.ps--item-display {
  position: relative;
}

.ps--item-display.card {
  padding: 2.4rem;
}

@media (max-width: 575.98px) {
  .ps--item-display.card {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #aebfd4;
    border-radius: 0;
    margin: 0 -15px 2rem;
    padding: .5rem 15px 2.5rem;
  }
}

.ps--item-display--title {
  color: #0073fa;
  font-size: 2rem;
  line-height: 3rem;
}

.ps--item-display--edit {
  color: inherit;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.ps--item-display--edit b {
  text-align: left;
  margin: 0 .5rem 0 0;
}

.ps--item-display--edit svg {
  margin: .2rem 0 0;
}

.ps--item-display--counter {
  border: 1px solid #aebfd4;
  border-radius: 100px;
  width: 10rem;
  padding: .5rem 1rem;
}

.ps--item-display--counter .ps-config--count--delete svg {
  width: 1.2rem;
  height: 1.6rem;
  margin: 0;
}

.ps--item-display--image, .ps--item-display--no-image {
  object-fit: cover;
  border-radius: 10px;
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
}

.ps--item-display--image img {
  object-fit: cover;
  width: 8rem;
  height: 8rem;
}

.ps--item-display--no-image {
  background-color: #f7f9fa;
}

.ps--item-display--no-image .IconCamera {
  width: 1.5em;
  height: 1.5em;
}

.ps--item-display--no-image .IconCamera circle, .ps--item-display--no-image .IconCamera path {
  fill: #aebfd4;
}

.ps-start-search-button {
  color: #7c90a8;
  text-align: left;
  border: 1px solid #aebfd4;
  margin-top: 0;
  padding: 1.3rem;
  font-size: 1.8rem;
  font-weight: normal;
}

@media (min-width: 1025px) {
  .ps-start-search-button {
    padding: 1.5rem;
    font-size: 2rem;
  }
}

.ps-start-search-button svg {
  margin-right: .5em;
}

.ps-warn-suffix {
  color: #7c90a8;
}

.ps--main-head {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  display: flex;
}

@media (min-width: 1025px) {
  .ps--main-head {
    align-items: flex-start;
  }
}

.ps--main-head .btn, .ps--main-head h2 {
  margin: 0;
}

.ps--main-head .btn {
  border-radius: 100px;
}

.ps-config--update-head {
  color: #7c90a8;
  font-weight: bold;
}

.ps-config--update-head--content {
  text-align: center;
  width: 100%;
}

.input-phone .input--wrapper {
  width: 55px;
}

.input-phone select {
  background-color: #e2eaf2;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 55px;
  height: 100%;
}

.input-phone input {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-phone .IconSmile {
  display: none;
}

.contact-modal--content {
  background-color: #fff;
  border-radius: 8px;
  margin: 2vh 15px 0;
  padding: 15px 30px 30px;
  overflow: auto;
}

.contact-modal--header {
  border-bottom: 1px solid #aebfd4;
  margin: 0 -30px 30px;
  padding: 0 30px 15px;
}

.mp-banner {
  border-radius: 1rem;
  height: 120px;
  margin-bottom: 1em;
  text-decoration: none;
  overflow: hidden;
}

@media (min-width: 768px) {
  .mp-banner {
    height: 150px;
  }
}

.mp-banner img {
  width: auto;
  height: 100%;
  margin-bottom: -1rem;
}

.mp-banner label {
  background-color: #f15b56;
  border-radius: 5px;
  padding: .2em 1em;
  font-weight: bold;
  display: inline-block;
}

.mp-banner--content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.mp-banner--content h5 {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .mp-banner--content h5 {
    margin-bottom: 1.2rem;
  }
}

.auction-types img {
  width: 50px;
}

.auction-types .input-el--tile--suffix svg {
  font-size: 10px;
}

.datetime-period-string {
  color: #0073fa;
}

.datetime-period-string:first-letter {
  text-transform: uppercase;
}

.item-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: .5em 0;
  transition: all .25s;
  display: flex;
}

.item-wrapper .item-wrapper--image .input-el--file-photo-display svg path {
  fill: #0073fa;
}

.item-wrapper .item-wrapper--image .input-el--file-photo-display .btn {
  border-color: #7c90a8;
}

.item-wrapper .item-wrapper--image .input-el--file-photo-display .btn:hover {
  background: #e2eaf2;
}

.item-wrapper:first-child, .item-wrapper:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .item-wrapper {
    margin: 1em 0;
  }
}

.item-wrapper--title {
  order: 1;
  width: 100%;
}

@media (min-width: 576px) {
  .item-wrapper--title {
    flex-grow: 1;
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .item-wrapper--title {
    width: 75%;
  }
}

.item-wrapper--volume-fields {
  order: 2;
  width: 100%;
  padding-bottom: 1em;
}

@media (min-width: 576px) {
  .item-wrapper--volume-fields {
    order: 4;
    width: calc(100% - 180px);
  }
}

.item-wrapper--volume-fields .fields {
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
}

.item-wrapper--volume-fields .input-el {
  width: calc(33% - 10px);
  margin-bottom: 0;
}

@media only screen and (max-width: 330px) {
  .item-wrapper--volume-fields .input-el {
    width: calc(33% - 5px);
  }

  .item-wrapper--volume-fields .input-el .input-el--label {
    max-width: 78.5%;
  }
}

.item-wrapper--volume-fields .input-el--feedback {
  white-space: nowrap;
}

.item-wrapper--quantity {
  order: 3;
}

.item-wrapper--image {
  order: 4;
  width: calc(100% - 150px);
}

.item-wrapper--image .input-el--file-photo-display {
  margin: 0 0 0 .2em;
}

.item-wrapper--image .btn {
  margin-top: 0;
}

@media (min-width: 576px) {
  .item-wrapper--image {
    order: 2;
    width: calc(30% - 15px);
    padding-left: 15px;
  }

  .item-wrapper--image .input-el--file-photo-display {
    margin-left: auto;
  }

  .item-wrapper--image .input-el {
    text-align: right;
  }

  .item-wrapper--image .btn {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .item-wrapper--image {
    width: calc(25% - 15px);
  }
}

.item-wrapper--remove-container {
  order: 5;
  justify-content: flex-end;
  align-items: center;
  width: 1.2em;
  display: inline-flex;
}

@media (min-width: 576px) {
  .item-wrapper--remove-container {
    order: 5;
  }
}

.item-wrapper--remove-container .IconTrash {
  cursor: pointer;
  margin-left: 0;
  top: -.5em;
}

.item-wrapper:first-child .IconTrash {
  cursor: not-allowed;
}

.item-wrapper:first-child .IconTrash path {
  fill: #e2eaf2;
}

@media (min-width: 576px) {
  .item-config--actions {
    display: flex;
  }

  .item-config--actions .btn {
    max-width: 260px;
  }
}

.login-wrapper {
  max-width: 650px;
  margin: 0 auto;
  padding: 2em;
  display: block;
}

.invoices-wrapper {
  max-width: 100%;
  margin: 50px auto;
  padding: 2em;
  display: block;
}

.register-wrapper {
  margin: 0 auto;
  padding: 2em;
  display: block;
}

.register-wrapper button {
  float: left;
}

.social-login-failed {
  text-align: center;
}

.social-login-failed h3 {
  color: #d70016 !important;
}

.loginBtn {
  box-sizing: border-box;
  float: left;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  border-radius: .2em;
  width: 100%;
  min-width: 250px;
  margin: .2em;
  padding: 0 15px 0 46px;
  line-height: 34px;
  position: relative;
  color: #fff !important;
  text-shadow: none !important;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  width: 34px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, .1);
}

.loginBtn--facebook {
  text-shadow: 0 -1px #354c8c;
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  font-weight: 900;
}

.loginBtn--facebook:before {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px no-repeat;
  border-right: 1px solid #364e92;
}

.loginBtn--facebook:hover, .loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--google {
  background: #dd4b39;
  font-weight: 900;
}

.loginBtn--google:before {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png") 6px 6px no-repeat;
  border-right: 1px solid #bb3f30;
}

.loginBtn--google:hover, .loginBtn--google:focus {
  background: #e74b37;
}

.proceed-to-login {
  margin: 15px 0;
}

.dashboard-welcome {
  padding: 100px 0 30px;
  position: relative;
}

@media (min-width: 768px) {
  .dashboard-welcome {
    padding: 30px 115px;
  }
}

.dashboard-welcome--logo {
  object-fit: contain;
  background-color: #338ffb;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dashboard-welcome--logo {
    margin-left: 0;
    top: 28px;
    left: 0;
  }
}

.business--price-wrapper .price-display {
  border-top: none;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 0;
  padding-bottom: 1rem;
}

.business--footer .price-display--head {
  white-space: nowrap;
}

.business--date-selector .btn > svg.IconSmile {
  width: .8em;
  height: .8em;
  margin-right: .5em;
}

.business--date-selector .btn:hover path {
  fill: #fff;
}

.business--date-selector .input-el--tile {
  margin-bottom: 0;
}

.business--date-selector .input-el--tile > div {
  flex-wrap: wrap;
  display: flex;
}

.business--date-selector .input-el--tile--copy {
  padding: 0;
}

.business--date-selector .input-el--tile--title {
  text-align: center;
  font-size: 1em;
}

.business--date-selector .input-el--tile--title .date-short {
  margin-left: .5em;
  display: inline-block;
}

@media (min-width: 768px) {
  .business--date-selector .input-el--tile--title .date-short {
    width: 100%;
    margin-left: 0;
  }
}

.business--date-selector .input-el--tile--option {
  padding: 10px;
}

@media (min-width: 768px) {
  .business--date-selector .input-el--tile--option {
    width: calc(50% - 1em);
    margin-right: 1em;
  }

  .business--date-selector .input-el--tile--option:nth-child(odd) {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .business--date-selector .input-el--tile--option {
    width: calc(33% - 1em);
  }

  .business--date-selector .input-el--tile--option:nth-child(odd) {
    margin-right: 1em;
  }

  .business--date-selector .input-el--tile--option:nth-child(3n+1) {
    margin-right: 0;
  }
}

.business--date-selector .input-el--feedback {
  margin: -1em 0 .5em 15px;
}

.order-wrapper {
  z-index: 5;
  flex-direction: column;
  display: flex;
}

.order-wrapper--is-return {
  flex-direction: column-reverse;
}

/*# sourceMappingURL=brenger-main.css.map */
