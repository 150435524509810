@import '../../../assets/scss/helpers/all';

%heading_base {
  color: $color--heading;
  font-weight: bold;
  margin: 0;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.no-margin {
    margin: 0 !important;
  }
}

h1 {
  @extend %heading_base;

  font-size: $font-size--h1--small;
  line-height: $line-height--h1--small;
  margin-bottom: $font-size--h1-space--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--h1;
    line-height: $line-height--h1;
    margin-bottom: $font-size--h1-space;
  }
}

h2 {
  @extend %heading_base;

  font-size: $font-size--h2--small;
  line-height: $line-height--h2--small;
  margin-bottom: $font-size--h2-space--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--h2;
    line-height: $line-height--h2;
    margin-bottom: $font-size--h2-space;
  }
}

h3 {
  @extend %heading_base;

  font-size: $font-size--h3--small;
  line-height: $line-height--h3--small;
  margin-bottom: $font-size--h3-space--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--h3;
    line-height: $line-height--h3;
    margin-bottom: $font-size--h3-space;
  }
}

h4 {
  @extend %heading_base;

  font-size: $font-size--h4--small;
  line-height: $line-height--h4--small;
  margin-bottom: $font-size--h4-space--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--h4;
    line-height: $line-height--h4;
    margin-bottom: $font-size--h4-space;
  }
}

h5 {
  @extend %heading_base;

  font-size: $font-size--h5--small;
  line-height: $line-height--h5--small;
  margin-bottom: $font-size--h5-space--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--h5;
    line-height: $line-height--h5;
    margin-bottom: $font-size--h5-space;
  }
}
