@import '../../../assets/scss/helpers/all';

.input-el--password {
  input {
    padding-right: 45px;
  }

  &--trigger {
    position: absolute;
    top: 0.7em;
    right: 0.6em;
  }

  .IconEye {
    opacity: 0.75;
  }

  &--visible {
    .IconEye {
      opacity: 1;
    }
  }
}
