@import '../../brenger-shared-ui/assets/scss/helpers/all';

@include media-breakpoint-down(sm) {
  .sheet-layout--footer {
    .price-display {
      .dd_tooltip-body {
        display: none;
      }
    }
  }
}

.sheet-layout--sidebar {
  .summary {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;

    &--body {
      height: 100%;
      overflow: auto;
    }

    &--hide-prices {
      .adds-price,
      .price-display {
        display: none;
      }
    }
  }

  .is-guaranteed {
    opacity: 0.8;
    margin: 0;

    svg {
      width: 12px;
      height: 12px;
    }

    .IconCheckMark {
      path {
        fill: #00d896;
      }
    }

    .IconCross {
      path {
        fill: #c10707;
      }
    }
  }

  // AB TEST
  .show-usps-test & {
    .usps-present {
      .summary {
        display: block;
        height: auto;
        overflow: auto;

        &--body {
          height: auto;
        }
      }
    }
  }
}
