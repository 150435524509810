@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.info-message {
  overflow: hidden;
  position: relative;
  visibility: hidden;
  max-height: 0;

  &--wrapper {
    background: $color--blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color--primary;
    transition: all 0.5s cubic-bezier(0, 0.69, 0.46, 1);
    transform: translate3d(0, 110%, 0);
    padding: 10px 0;

    @include media-breakpoint-up('sm') {
      padding: math.div($grid-gutter-width, 2) 0;
    }
  }

  .info-message--visible & {
    visibility: visible;
    max-height: 1000px;

    .info-message--wrapper {
      transform: translate3d(0, 0, 0);
    }
  }

  &--prefix {
    padding-left: 10px;

    @include media-breakpoint-up('sm') {
      padding-left: math.div($grid-gutter-width, 2);
    }

    line-height: 1;

    svg {
      height: 40px;
      width: 40px;

      @include media-breakpoint-up('sm') {
        height: 65px;
        width: 65px;
      }
    }
  }

  &--content {
    flex-grow: 1;
    padding: 0 10px;

    @include media-breakpoint-up('sm') {
      padding: 0 math.div($grid-gutter-width, 2);
    }

    @include media-breakpoint-down('sm') {
      line-height: 1.3;
    }
  }

  &--suffix {
    padding-right: 10px;

    @include media-breakpoint-up('sm') {
      padding-right: math.div($grid-gutter-width, 2);
    }
  }

  &--close {
    line-height: 1;
    padding: 5px 10px 10px 10px;
    position: relative;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 6px;

    @include media-breakpoint-up('xl') {
      top: 3px;
    }

    svg {
      height: 0.7em;
      width: 0.7em;
    }
  }
  ul {
    padding-left: math.div($grid-gutter-width, 2);
    margin: 5px 0 0 0;
  }
}

@media print {
  .info-message {
    display: none;
  }
}
