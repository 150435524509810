@import '../../brenger-shared-ui/assets/scss/helpers/all';

.help-content {
  h3 {
    font-family: $font-family--normal;
  }

  .close-chat {
    display: none;
  }

  .chat--is-visible & {
    .close-chat {
      display: inline-block;
    }

    .open-chat {
      display: none;
    }
  }
}

.general-help--trigger {
  margin-left: auto;
  font-size: 0.8em;
  font-family: $font-family--normal;

  svg {
    position: relative;
    top: 0.25em;
    margin-left: 0.2em;
  }

  path {
    fill: $color--primary;
  }
}

.time-pref-modal {
  .btn {
    &:last-child {
      margin: 0;
    }
  }
}

@media only screen and (max-device-width: 667px),
  only screen and (max-device-width: 900px) and (min-device-width: 668px) and (orientation: landscape) {
  #fc_frame.h-open-container {
    height: 85% !important;
    max-height: 85vh !important;
    min-height: 85px !important;
  }
}

@media only screen and (max-device-width: 667px),
  only screen and (max-device-width: 900px) and (min-device-width: 668px) and (orientation: portrait) {
  #fc_frame.h-open-container {
    height: 85% !important;
    max-height: 85vh !important;
    min-height: 85px !important;
  }
}

b.old-price {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid $color--primary;
  }
}

table.price-table {
  width: 100%;
  color: $color--primary;
  overflow: hidden;
  border-collapse: collapse;
  border-radius: $border-radius--default;

  tr:last-child {
    font-weight: bold;
  }

  tr:nth-child(2n + 1) {
    background-color: $color--white--hover;
  }

  tr:nth-child(2n) {
    background-color: $color--primary--light;
  }

  svg {
    position: relative;
    position: relative;
    top: 0.1em;
    left: 2px;
    height: 1em;
    width: 1em;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
}

.blocked-reason-list-messages {
  .message {
    margin-bottom: 0.2em;
  }
}
