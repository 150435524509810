* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

html {
  font-size: 62.5%;
}

main {
  display: block;
}

body {
  margin: 0;
  font-family: $font-family--normal;
  font-size: $font-size--body--small;
  line-height: $line-height--body--small;
  color: $color--black;

  @include media-breakpoint-up(md) {
    font-size: $font-size--body;
    line-height: $line-height--body;
  }

  &.overflow-hidden {
    overflow: hidden;
  }
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 0.75em;
  display: block;
  line-height: 1.45em;
}

a {
  color: $color--primary;

  @include media-breakpoint-up('md') {
    &[href*='tel:'] {
      color: inherit;
      pointer-events: none;
      text-decoration: none;
    }
  }
}

form {
  margin-bottom: 2em;
}

p {
  margin: 0 0 $font-size--body-space--small 0;

  @include media-breakpoint-up(md) {
    margin: 0 0 $font-size--body-space 0;
  }

  &.lead {
    font-size: $font-size--lead--small;
    line-height: $line-height--lead--small;
    margin: 0 0 $font-size--lead-space--small 0;

    @include media-breakpoint-up(md) {
      font-size: $font-size--lead;
      line-height: $line-height--lead;
      margin: 0 0 $font-size--lead-space 0;
    }
  }
}

table {
  border: none;
  padding: 0;
  margin: 0 0 0.5em 0;

  tr {
    td {
      border: none;
      padding: 0.5em;
      vertical-align: top;
    }

    &:nth-child(2n) {
      background: transparent;
    }

    &:nth-child(2n + 1) {
      background: $color--grey--light;
    }
  }
}
