@import '../../brenger-shared-ui/assets/scss/helpers/all';

.business {
  &--price-wrapper {
    .price-display {
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-top: none;
    }
  }

  &--footer {
    .price-display--head {
      white-space: nowrap;
    }
  }

  &--date-selector {
    .btn {
      > svg.IconSmile {
        width: 0.8em;
        height: 0.8em;
        margin-right: 0.5em;
      }

      &:hover {
        path {
          fill: $color--white;
        }
      }
    }

    .input-el--tile {
      margin-bottom: 0;

      & > div {
        display: flex;
        flex-wrap: wrap;
      }

      &--copy {
        padding: 0;
      }

      &--title {
        font-size: 1em;
        text-align: center;

        .date-short {
          display: inline-block;
          margin-left: 0.5em;

          @include media-breakpoint-up('md') {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      &--option {
        padding: 10px;

        @include media-breakpoint-up('md') {
          width: calc(50% - 1em);
          margin-right: 1em;

          &:nth-child(2n + 1) {
            margin-right: 0;
          }
        }

        @include media-breakpoint-up('lg') {
          width: calc(33% - 1em);

          &:nth-child(2n + 1) {
            margin-right: 1em;
          }

          &:nth-child(3n + 1) {
            margin-right: 0;
          }
        }
      }
    }

    .input-el--feedback {
      margin: -1em 0 0.5em 15px;
    }
  }
}

.order-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 5;
  &--is-return {
    flex-direction: column-reverse;
  }
}
