@use 'sass:math';

@import '../../brenger-shared-ui/assets/scss/helpers/all';

.info-message-container {
  position: absolute;
  bottom: calc(100% + 10px);
  left: math.div($grid-gutter-width-negative, 2);
  right: math.div($grid-gutter-width-negative, 2);
  visibility: hidden;

  &.info-message--visible {
    visibility: visible;
  }

  @include media-breakpoint-up(sm) {
    left: 0;
    right: 0;
  }

  @include media-breakpoint-up(md) {
    bottom: calc(100% + 20px);
  }

  .info-message {
    &--example {
      display: flex;
      max-width: 250px;
    }

    &--example-name {
      flex-grow: 1;
    }
  }
}

.footer-notification-bar {
  position: absolute;
  top: -20px;
  width: 100%;
  left: 0;
  z-index: 0;
  opacity: 0.7;
  padding: 0 45px;
  font-weight: bold;
  -webkit-animation: slideToTop 0.5s forwards;
  animation: slideToTop 0.5s forwards;

  p {
    padding: 2.5px 0;
    margin: 0;
    font-size: 15px;
  }

  a {
    color: white;
  }

  span {
    position: absolute;
    right: 45px;
    top: 0;
    cursor: pointer;
    font-size: 20px;
    text-decoration: underline;
  }

  &.error-notification {
    background: #d63031;
    color: white;
  }

  &.success-notification {
    background: #27ae60;
    color: white;
  }

  &.warn-notification {
    background: #f39c12;
    color: white;
  }
}

.modal .help-header {
  padding-top: 0.3em;

  .IconQuestionBubble {
    float: left;
    position: relative;
    top: -0.3em;
  }

  .question-mark,
  .bubble {
    fill: $color--white;
  }
}

@-webkit-keyframes slideToTop {
  100% {
    top: -30px;
    opacity: 1;
  }
}

@keyframes slideToTop {
  100% {
    top: -30px;
    opacity: 1;
  }
}
@include media-breakpoint-down(md) {
  .notifications--with-contact-bar {
    top: -45px;
  }
}
