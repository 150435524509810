@import '../../../assets/scss/helpers/all';

.input-el--text-toggle {
  input {
    display: none;
  }

  &--label {
    color: $color--primary;
    text-decoration: underline;
    cursor: pointer;
  }
}
