@use 'sass:math';

@import '../../../assets/scss/helpers/all';

$modalIndex: 10000; // this is quite high,

.modal {
  @include visible(false);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $modalIndex;
  overflow: hidden;
  transition: $default-transition-speed opacity;

  &.modal--style-status-page {
    z-index: $modalIndex - 100;
  }

  &.modal--isOpen {
    @include visible(true);
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.modal-backdrop {
  background: $color--black--trans;
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  overflow: auto;

  &--no-shade {
    background: transparent;
  }

  .modal--style-chat & {
    overflow: scroll;
    overflow-x: hidden;
  }
}

.modal-inner {
  max-width: 575px;
  border-radius: $border-radius--default;
  background: $color--white;
  position: relative;
  padding: math.div($grid-gutter-width, 2);
  margin: math.div($grid-gutter-width, 2);
  transition: $default-transition-speed transform;
  transform: translate3d(0, -60px, 0);

  .modal--style-blue & {
    background: $color--primary;
    color: $color--white;
    border-radius: 0;
    max-width: 430px;

    .IconCross {
      path {
        fill: $color--white;
      }
    }
  }

  .modal--style-white > .modal-backdrop > &,
  .modal--style-status-page > .modal-backdrop > &,
  .modal--style-chat > .modal-backdrop > & {
    border-radius: 0;
    max-width: 940px;
  }

  .modal--style-small & {
    max-width: 450px;
  }

  .modal--style-white & {
    margin: 0 auto;
    max-width: 450px;
    max-height: calc(100% - 5vh);
    display: flex;
    flex-direction: column;
    overflow: auto;

    @include media-breakpoint-up(sm) {
      height: auto;
    }
  }

  .modal--style-white > .modal-backdrop > & {
    max-width: 335px;
  }

  .modal--style-status-page > .modal-backdrop > & {
    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    margin: 5vh auto;
  }

  p {
    line-height: 1.2;
  }

  .liveVideo-msg {
    margin-top: 10px;
    display: block;
    background: $color--warn--lighter;
    border-color: $color--warn;
    color: $color--warn;
    padding: $button-spacing;
    line-height: 1.1;
    border-width: 1px;
    border-style: solid;
    border-radius: $border-radius--default;
  }

  .prepaid-msg {
    margin-bottom: 20px;
    display: block;
  }

  .modal--isOpen & {
    transform: translate3d(0, 0, 0);
  }

  .modal-header {
    font-size: 1.2em;
    position: relative;
    font-weight: bold;
    padding-bottom: math.div($grid-gutter-width, 2);
    padding-right: 1.5em;
    margin-bottom: math.div($grid-gutter-width, 2);
    border-bottom: 1px solid $color--grey--teaserborder;

    .modal--style-white &,
    .modal--style-status-page & {
      border: 0;
      margin: 0;
      padding: 0;
      z-index: 5;
      width: 100%;
    }

    .modal--style-blue & {
      border: none;
    }

    .modal--style-chat & {
      display: none;
    }
  }

  .modal-footer {
    padding-top: math.div($grid-gutter-width, 2);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .btn {
      margin: math.div($grid-gutter-width, 4) 0;
    }
  }

  .modal-footer-sub {
    font-size: 0.8em;
    text-align: center;

    .btn {
      margin: 0 0 math.div($grid-gutter-width, 4) 0;
    }

    .trigger {
      text-decoration: underline;
    }
  }
}

.modal-close {
  position: absolute;
  top: 0.025em;
  right: 0;

  .modal--style-status-page > .modal-backdrop > .modal-inner > .modal-header & {
    top: $grid-gutter-width;
    left: $grid-gutter-width;
  }

  .modal--style-white & {
    top: -2px;
    right: 0;
  }

  svg {
    width: 0.75em;
    height: 0.75em;
  }
}

.prompt-modal {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
