@use 'sass:math';

@import '../../brenger-shared-ui/assets/scss/helpers/all';

.item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.5em 0;
  transition: all 0.25s;

  .item-wrapper--image {
    .input-el--file-photo-display {
      svg {
        path {
          fill: $color--primary;
        }
      }

      .btn {
        border-color: $color--grey--darker;

        &:hover {
          background: $color--grey--light;
        }
      }
    }
  }

  &:first-child,
  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    margin: 1em 0;
  }

  &--title {
    width: 100%;
    order: 1;

    @include media-breakpoint-up(sm) {
      width: 60%;
      flex-grow: 1;
    }

    @include media-breakpoint-up(xl) {
      width: 75%;
    }
  }

  &--volume-fields {
    width: 100%;
    order: 2;
    padding-bottom: 1em;

    @include media-breakpoint-up(sm) {
      width: calc(100% - 180px);
      order: 4;
    }

    .fields {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }

    .input-el {
      width: calc(33% - 10px);
      margin-bottom: 0;

      @media only screen and (max-width: 330px) {
        width: calc(33% - 5px);

        .input-el--label {
          max-width: 78.5%;
        }
      }

      &--feedback {
        white-space: nowrap;
      }
    }
  }

  &--quantity {
    order: 3;
  }

  &--image {
    width: calc(100% - 150px);
    order: 4;

    .input-el--file-photo-display {
      margin: 0 0 0 0.2em;
    }

    .btn {
      margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
      order: 2;
      width: calc(30% - #{math.div($grid-gutter-width, 2)});
      padding-left: math.div($grid-gutter-width, 2);

      .input-el--file-photo-display {
        margin-left: auto;
      }
      .input-el {
        text-align: right;
      }

      .btn {
        display: inline-block;
      }
    }

    @include media-breakpoint-up(xl) {
      width: calc(25% - #{math.div($grid-gutter-width, 2)});
    }
  }

  &--remove-container {
    width: 1.2em;
    order: 5;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-up(sm) {
      order: 5;
    }

    .IconTrash {
      cursor: pointer;
      margin-left: 0;
      top: -0.5em;
    }
  }

  &:first-child {
    .IconTrash {
      cursor: not-allowed;

      path {
        fill: $color--grey--light;
      }
    }
  }
}

.item-config--actions {
  @include media-breakpoint-up(sm) {
    display: flex;

    .btn {
      max-width: 260px;
    }
  }
}
