@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.input-file-uploads--wrapper {
  margin-bottom: math.div($grid-gutter-width, 2);

  input[type='file'] {
    display: none;
  }

  .uploaded-file {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 0.8em;

    &--remove {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 1em;
      margin-right: 0.5em;
      color: $color--primary;
      cursor: pointer;
      padding-top: 0.1em;

      .IconTrash {
        transform: translate3d(0, -0.1em, 0) scale(0.8);
      }
    }
  }

  .usp-list {
    max-width: 400px;

    &--header {
      margin: 0;
      color: $color--black;
      font-size: 0.8em;
    }

    &--item {
      justify-content: space-between;
      padding: 5px 0;
      margin-bottom: 0;
      border-bottom: 1px solid $color--grey--light;

      &:last-child {
        border: none;
      }
    }

    &--text {
      flex-grow: 1;
    }
  }
}

.input-file-uploads--with-preview {
  .IconCamera {
    width: 1.5em;
    height: 1.5em;
    margin: 0 0 -0.4em 0;

    circle {
      fill: $color--primary;
    }

    path {
      fill: $color--primary;
    }
  }

  @include media-breakpoint-desk-and-up() {
    .btn--outline:hover {
      .IconCamera {
        circle {
          fill: $color--white;
        }

        path {
          fill: $color--white;
        }
      }
    }
  }
}

.input-el--file-photo-display--image {
  position: relative;

  &-src {
    height: 2.8em;
    width: 2.8em;
    background: $color--white center / cover;
    border-radius: $border-radius--default;
    overflow: hidden;
    border: 1px solid $color--secondary;
    display: inline-block;
    cursor: pointer;
  }

  &-value {
    position: absolute;
    top: 0;
    left: 6em;
    right: 0;
    bottom: 0.7em;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 11px;
    line-height: 1.1;
    word-break: break-all;

    .value-wrap {
      overflow: hidden;
      max-height: 3.4em;
      max-width: 100%;
    }
  }

  &-delete {
    position: absolute;
    top: -0.45em;
    left: 1.4em;
    width: 0.9em;
    cursor: pointer;
    text-align: center;
    padding: 0 0 0.3em 0;
    line-height: 0.6;
    font-size: 1.5em;
    border-radius: $border-radius--default;
    background-color: $color--primary;
    color: $color--white;
  }
}
