@import '../../brenger-shared-ui/assets/scss/helpers/all';

.app-main-wrapper {
  .internal-widget {
    .brenger-tr-widget--wrapper .brenger-autocomplete-widget {
      border-radius: $border-radius--tile;
      padding: 0.5em 0.9em;
      box-shadow: 0px 8px 10px rgba(174, 191, 212, 0.2);
      margin-bottom: 15px;
    }

    .brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete {
      left: -0.9em;
      right: -0.9em;
    }

    .brenger-tr-widget {
      padding: 0;
      box-shadow: none;

      .btn--secondary,
      &--header {
        display: none;
      }
      svg.route,
      &--divider {
        display: none;
      }
      .IconMarker {
        opacity: 1;
      }
      .autocomplete-target .input-el--autocomplete,
      .destination-fields {
        display: block;
        width: 100%;
      }

      .input-el--autocomplete {
        margin-bottom: 0;
        margin-left: 0;
      }
      .input-el--label {
        margin-left: 0;
      }
    }
  }
}

.external-widget {
  display: block;

  .render-app & {
    display: none;
  }
}
