@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.sheet-layout {
  width: 100%;
  margin-top: 5px;

  &--sidebar,
  &--footer {
    padding: math.div($grid-gutter-width, 2) 0;
  }

  &--main {
    max-width: 100%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      overflow-x: hidden;
    }

    &-container {
      padding: 0 math.div($grid-gutter-width, 2);
    }
  }

  &--notifications,
  &--main,
  &--footer {
    @include media-breakpoint-up('lg') {
      padding-right: $sheet-layout-sidebar-lg;
    }

    @include custom-breakpoint-up(#{$sidebar-xl-bp}) {
      padding-right: $sheet-layout-sidebar-xl;
    }

    @include custom-breakpoint-up(#{$sidebar-xxl-bp}) {
      padding-right: 0;
    }
  }

  &--sidebar {
    position: fixed;
    padding-top: 0;
    left: 0;
    right: 0;
    bottom: $sheet-layout-footer--small;
    top: 0;
    z-index: 10;
    background: rgba(34, 59, 88, 0.37);
    transition: all 0.5s cubic-bezier(0, 0.69, 0.46, 1);

    @include visible(false);

    @include media-breakpoint-up(md) {
      transition: none;
    }

    &-background {
      position: absolute;
      top: $progress-header-height--small;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      padding: math.div($grid-gutter-width, 2);
      background: $color--white--hover;
      overflow: auto;
      transition: all 0.5s cubic-bezier(0, 0.69, 0.46, 1);
      transform: translate3d(0, 110%, 0);

      @include media-breakpoint-up(md) {
        transition: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transform: translate3d(0, 0, 0);
        padding: $sheet-layout-sidebar-padding $sheet-layout-sidebar-padding ($sheet-layout-sidebar-padding - 10)
          $sheet-layout-sidebar-padding;
        top: 0;
      }
    }
    &-contact {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: math.div($grid-gutter-width, 3) math.div($grid-gutter-width, 2);
      background-color: $color--primary;
      color: $color--white;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(md) {
        display: flex;
      }
      svg {
        margin: 0 0.2em -0.2em 0.2em;
      }
    }
    .sheet-layout--is-active & {
      @include visible(true);

      &-background {
        transform: translate3d(0, 0, 0);
      }
    }

    @include media-breakpoint-up('md') {
      transform: translate3d(110%, 0, 0);

      @include visible(true);

      left: auto;
      top: $progress-header-height;
      bottom: $sheet-layout-footer;
      width: $sheet-layout-sidebar-md;
      font-size: $font-size--body--small;
      line-height: $line-height--body--small;

      .sheet-layout--is-active & {
        transform: translate3d(0, 0, 0) scale(1);
      }
    }

    @include media-breakpoint-up('lg') {
      left: auto;
      top: $progress-header-height;
      bottom: $sheet-layout-footer;
      width: $sheet-layout-sidebar-lg;
      transform: translate3d(0, 0, 0) scale(1);

      @include visible(true);
    }

    @include custom-breakpoint-up(#{$sidebar-xl-bp}) {
      width: $sheet-layout-sidebar-xl;
    }

    &-shade-toggle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $progress-header-height--small;
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-heading,
    .summary-heading {
      font-weight: bold;
      font-size: $font-size--h2--small;
      line-height: $line-height--h2--small;
    }

    .summary--close {
      svg {
        width: 0.9em;
        height: 0.9em;
        position: absolute;
        right: math.div($grid-gutter-width, 2);
        top: math.div($grid-gutter-width, 2);
      }

      @include media-breakpoint-up('lg') {
        display: none;
      }
    }

    &-heading {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 50;

      @include media-breakpoint-up(md) {
        top: 2em;
      }
    }

    .summary-heading {
      color: $color--primary;
    }

    &-content {
      font-size: 0.85em;
    }
  }

  &--sticky-footer {
    right: 0;
    margin-right: math.div($grid-gutter-width, 2);
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      right: $sheet-layout-sidebar-lg;
    }

    @include custom-breakpoint-up(#{$sidebar-xl-bp}) {
      right: $sheet-layout-sidebar-xl;
    }
  }

  &--footer {
    position: fixed;
    bottom: 0;
    height: $sheet-layout-footer--small;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    z-index: 15;
    left: 0;
    background: $color--white;
    box-shadow:
      0 0 10px 0 rgba(0, 0, 0, 0.2),
      0 -50px 50px 0 #fff,
      0 17px 60px 0 #fff;

    .sheet-layout--is-active & {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    @include media-breakpoint-up(md) {
      height: $sheet-layout-footer;
      padding-top: 2rem;
      padding-bottom: 2rem;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    .btn {
      margin-top: 0;
    }

    .btn--secondary {
      margin: 0;
      padding: 8px 45px 8px 15px;
      width: 155px;
      font-size: 1.1em;
      float: right;

      @include media-breakpoint-up(md) {
        font-size: 1em;
        padding-top: 0.65em;
        padding-bottom: 0.75em;
        width: 100%;
      }
    }
    &-contact {
      display: flex;
      position: absolute;
      left: 0;
      bottom: 100%;
      right: 0;
      padding: math.div($grid-gutter-width, 3) math.div($grid-gutter-width, 2);
      background-color: $color--primary;
      color: $color--white;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      svg {
        margin: 0 0.2em -0.2em 0.2em;
      }
    }
  }

  &--content-wrapper {
    text-align: left;
    padding-bottom: 8rem;

    &--top-header {
      padding: 0 0 0.5em 0;

      @include media-breakpoint-up(md) {
        padding: 1em 0 0.5em 0;
      }
    }
  }

  &--main-header {
    opacity: 0;
    animation: fadeInRight 0.33s ease-in-out 0s 1 forwards;
    max-width: $sheet-layout-container--small;
    margin: 0 auto;
    z-index: 5;
    background: $color--white;

    @include media-breakpoint-up(xl) {
      max-width: $sheet-layout-container;
      margin: 0 8% 0 auto;
    }

    @include custom-breakpoint-up(#{$sidebar-xxl-bp}) {
      margin: 0 auto;
    }

    .sheet-layout--is-navigating & {
      animation-delay: 0.05s;
    }
  }

  &--main-content {
    opacity: 0;
    animation: fadeInRight 0.33s ease-in-out 0.05s 1 forwards;
    padding-bottom: 15px;
    flex-grow: 1;
    position: relative;
    z-index: 0;
  }

  &--container,
  &--main-content {
    max-width: $sheet-layout-container--small;
    margin: 0 auto;

    @include media-breakpoint-up(xl) {
      max-width: $sheet-layout-container;
      margin: 0 8% 0 auto;
    }

    @include custom-breakpoint-up(#{$sidebar-xxl-bp}) {
      margin: 0 auto;
    }

    .sheet-layout--is-navigating & {
      animation-delay: 0s;
    }
  }

  &--nav-forwards {
    &.sheet-layout--is-navigating {
      .sheet-layout--main-header,
      .sheet-layout--main-content {
        opacity: 1;
        animation-name: fadeOutleft;
      }
    }
  }

  &--nav-backwards {
    .sheet-layout--main-header,
    .sheet-layout--main-content {
      animation-name: fadeInLeft;
    }

    &.sheet-layout--is-navigating {
      .sheet-layout--main-header,
      .sheet-layout--main-content {
        opacity: 1;
        animation-name: fadeOutRight;
      }
    }
  }

  &--notifications {
    position: fixed;
    left: 0;
    right: 0;
    bottom: $sheet-layout-footer--small;
    z-index: 30;

    .info-message-container {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
    }

    @include media-breakpoint-up(md) {
      bottom: $sheet-layout-footer;
    }
  }

  &--notifications,
  &--footer {
    .container {
      max-width: $sheet-layout-container--small + $grid-gutter-width;
      text-align: left;
      margin: 0 auto;

      @include media-breakpoint-up(xl) {
        max-width: $sheet-layout-container + $grid-gutter-width;
        margin: 0 8% 0 auto;
      }

      @include custom-breakpoint-up(#{$sidebar-xxl-bp}) {
        margin: 0 auto;
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(100px) scale(0.95);
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px) scale(0.95);
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }

  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      transform: translateX(0) scale(1);
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 0;
      transform: translateX(100px) scale(0.95);
    }
  }

  @keyframes fadeOutleft {
    0% {
      opacity: 1;
      transform: translateX(0) scale(1);
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 0;
      transform: translateX(-100px) scale(0.95);
    }
  }
}
