.btn-no-style {
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
}

.summary-line {
  display: flex;
  justify-content: space-between;

  .summary-line--in-header & {
    display: block;

    & > div {
      display: inline-block;
    }
  }

  div:first-letter {
    text-transform: uppercase;
  }

  .adds-price {
    display: inline-block;
    color: $color--primary;
    width: 80px;
    text-align: right;

    .summary-line--in-header & {
      width: 70px;
      white-space: nowrap;
    }
  }

  &--in-header {
    font-size: 0.9em;
    line-height: 1.5;
    display: none;
    // AB test class
    .show-previous-choices-list & {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      display: none;
      // AB test class
      .show-previous-choices-list & {
        display: none;
      }
    }
  }
}

.van-assets-image {
  width: 181px;

  .modal--style-white & {
    margin-left: -20px;
  }
}

.external-widget {
  margin-top: 100px;

  .app-render & {
    display: none;
  }
}

.list--open-dot {
  padding: 0 0 0 1.25em;
  margin-top: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: $color--white;
    top: 0.5em;
    left: 0;
    width: 0.6em;
    height: 0.6em;
    border: 2px solid $color--black;
    border-radius: 50%;
    z-index: 20;
  }
}
