.app-main-wrapper {
  position: relative;

  @media print {
    font-size: 14px;
  }

  .main-centered-content {
    width: 100%;
    max-width: $contentWidthSmall;
    margin: 0 auto;
  }
}

#root-customer-dialog {
  z-index: 10000;
  position: relative;
}

@media (max-width: 767.98px) {
  .path-node .region .hero .hero-cta-container {
    display: block !important;
  }
}
