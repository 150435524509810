@use 'sass:math';
@import '../../brenger-shared-ui/assets/scss/helpers/all';

.contact-modal {
  &--content {
    background-color: #fff;
    margin: 2vh math.div($grid-gutter-width, 2) 0 math.div($grid-gutter-width, 2);
    overflow: auto;
    border-radius: $border-radius--default;
    padding: math.div($grid-gutter-width, 2) $grid-gutter-width $grid-gutter-width $grid-gutter-width;
  }
  &--header {
    margin: 0 $grid-gutter-width-negative $grid-gutter-width $grid-gutter-width-negative;
    padding: 0 $grid-gutter-width math.div($grid-gutter-width, 2) $grid-gutter-width;
    border-bottom: 1px solid $color--grey--teaserborder;
  }
}
