@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.card {
  padding: math.div($grid-gutter-width, 2);
  margin: 0 0 math.div($grid-gutter-width, 2) 0;
  overflow: hidden;
  border-radius: $border-radius--tile;
  box-shadow: 8px 8px 20px rgba(174, 191, 212, 0.2);
  background: $color--white;
  color: $color--black;

  &--bordered {
    border: 1px solid $color--grey--teaserborder;
  }

  &--shade-2 {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow--teaser;
  }

  &--tr-business,
  &--transport-request {
    padding: 0 math.div($grid-gutter-width, 2);

    .section-wrapper {
      padding-top: math.div($grid-gutter-width, 2);
      padding-bottom: math.div($grid-gutter-width, 2);
    }
  }

  &--tr-business {
    padding-bottom: math.div($grid-gutter-width, 2);

    @include media-breakpoint-up(md) {
      .section-wrapper {
        padding-top: $grid-gutter-width;
      }
    }

    &--short-id {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }

    &--actions {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(xs) {
        order: 3;
      }

      .btn {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0.5em;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--disabled,
  &.trigger {
    .IconSmile {
      position: absolute;
      right: math.div($grid-gutter-width, 2);
      top: 1.1em;
      width: 0.4em;
      height: 1em;
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $color--grey--teaserborder;
    opacity: 0.75;
  }
}
