@import '../../../assets/scss/helpers/all';

.price-label {
  color: $color--white;
  padding: 0 1em;
  border-radius: 5em;
  font-size: 0.8em;
  line-height: 1.5em;
  margin: 0 0.5em;

  &--type {
    &-rush {
      background: $color--yellow;
    }

    &-budget {
      background: $color--primary;
    }

    &-sustainable {
      background: $color--secondary;
    }
  }
}
