@import '../../brenger-shared-ui/assets/scss/helpers/all';

.floor-indication {
  width: 1.2em;
  font-size: 1.5em;
  text-align: center;
  color: $color--primary;
  padding-bottom: 0.1em;
  border-bottom: 1px solid $color--primary;
  margin-left: -0.2em;
}

.another-collection-date {
  color: $color--primary;
  text-decoration: underline;
  font-size: 1.2em;
  cursor: pointer;
}

.no-available-auction-pickup-date {
  background: #f1c40f;
  border: 1px solid #f39c12;
  border-radius: 5px;
  padding: 5px 15px;
}

.more-days {
  .icon-wrapper svg {
    width: 0.8em;
    height: 0.8em;
  }
}

.invoice-item {
  a {
    display: block;
    margin: -20px 0 20px 0;
  }
}
