@use 'sass:math';

@import '../../../assets/scss/helpers/all';

select,
textarea,
input {
  padding: math.div($grid-gutter-width, 2);
  border: 1px solid $color--grey--teaserborder;
  border-radius: $border-radius--default;

  .input-el--has-focus & {
    box-shadow: $box-shadow--form;
    border-color: $color--primary;
  }

  .input-el--has-value.input-el--is-valid & {
    border-color: $color--secondary;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  font-family: $font-family--normal;
}

.input-el {
  position: relative;
  margin-bottom: math.div($grid-gutter-width, 2);

  select,
  textarea,
  input {
    @extend %input-layout;

    .location-input--wrapper & {
      padding-left: 35px;
    }
  }

  &--no-label {
    input {
      padding: 0.432em math.div($grid-gutter-width, 2) 0.432em math.div($grid-gutter-width, 2);
    }
  }

  &--label {
    position: absolute;
    top: 9px;
    left: math.div($grid-gutter-width, 2);
    font-family: $font-family--normal;
    color: $color--grey;
    transform-origin: 0 50%;
    transition: $default-transition-speed all;
    transform: translate3d(0, 0, 0) scale(1);
    border-radius: math.div($border-radius--default, 2);
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
    text-overflow: ellipsis;
    margin: 0;

    @include media-breakpoint-up('md') {
      top: 10px;
      left: 16px;
    }

    &--required {
      &:after {
        content: '*';
        color: $color--warn;
        padding-left: 0.2em;
      }
    }
  }

  &--has-value,
  &--has-focus {
    .input-el--label {
      transform: translate3d(1px, -13px, 0) scale(0.66);

      @include media-breakpoint-up('md') {
        top: 1.2rem;
      }
    }
  }

  &--tag {
    position: relative;
    width: 100%;
  }

  &--inner {
    display: flex;
    overflow: hidden;
  }

  &--prepend,
  &--append {
    background-color: $color--grey--light;
    display: flex;
    text-align: center;
    justify-items: center;
    padding-top: 8px;
    padding-bottom: 5px;
    border-radius: $border-radius--default;
  }

  &--prepend {
    padding-left: 5px;
    padding-right: (8px + $border-radius--default);
    margin-right: -1.5 * $border-radius--default;
  }

  &--append {
    padding-right: 5px;
    padding-left: (8px + $border-radius--default);
    margin-left: -1.5 * $border-radius--default;
  }

  &--radio,
  &--checkbox {
    input {
      display: none;
    }

    label {
      position: relative;
      transform: none !important;
      top: auto;
      left: auto;
      white-space: initial;
      display: block;
      overflow: visible;
      background: transparent;
      max-width: none;
      padding: 0 0 0 1.5em;
      color: $color--black;
      cursor: pointer;
      z-index: 1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
      }

      &::after {
        background: $color--white;
        width: 18px;
        height: 18px;
        top: 5px;
        left: 0;
        border: 1px solid $color--grey--teaserborder;
      }

      &::before {
        z-index: 5;

        @include visible(false);

        transition: $default-transition-speed transform;
      }
    }

    &.input-el--has-value,
    &.input-el--has-focus {
      label {
        top: auto;
      }
    }

    input:checked + label {
      &::before {
        @include visible(true);
      }
    }

    .input-el--feedback {
      margin: 0.2em 0 0 0;
    }

    &--inline {
      .input-el--option--list {
        display: flex;
      }

      .input-el--option--item {
        margin-left: 2em;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &--checkbox {
    label {
      &::after {
        border-radius: $border-radius--forms-checkbox;
      }

      &::before {
        top: 4px;

        .region-content & {
          top: 4px !important;
        }

        left: 5px;
        width: 9px;
        height: 15px;
        border-bottom: 3px solid $color--secondary;
        border-right: 3px solid $color--secondary;
        transform: rotate(45deg) scale(0, 1);
      }
    }

    input:checked + label {
      &::before {
        transform: rotate(45deg) scale(1);
      }
    }
  }

  &--radio {
    label {
      &::after {
        border-radius: 50%;
      }

      &::before {
        top: 9px;
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: $color--secondary;
        transform: scale(0.2);
        border-radius: 50%;
      }
    }

    input:checked + label {
      &::before {
        transform: scale(1);
      }
    }
  }

  &--bordered {
    border: $card-border;
    border-radius: $border-radius--default;
    box-shadow: none;
    transition: box-shadow $default-transition-speed;

    .input-el--label {
      padding: math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) 2.5em !important;

      &:before,
      &:after {
        margin: 0.85em 0 0 0.7em;
      }

      &-title {
        font-size: 1.1em;
        font-weight: bold;
      }

      &-content {
        flex-grow: 1;
      }

      &-amount {
        font-weight: bold;
        padding-left: math.div($grid-gutter-width, 2);
        white-space: nowrap;
        width: 100px;
        color: $primary;
        text-align: right;
      }
      &-text {
        font-weight: bold;
      }
    }

    @include media-breakpoint-up(md) {
      &:hover {
        text-decoration: none;
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }

  &--label--with-suffix {
    $suffix-width: 80px;

    display: flex;
    flex-wrap: wrap;

    .input-el--label-text {
      font-weight: bold;
      width: calc(100% - #{$suffix-width});
    }

    .input-el--description {
      width: calc(100% - #{$suffix-width});
    }

    .input-el--suffix {
      text-align: right;
      width: $suffix-width;
    }
  }
}

.location-input--wrapper {
  position: relative;

  .IconMarker {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate3d(0, -50%, 0);
    z-index: 10;
  }
}
