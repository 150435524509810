.input-phone-number {
  .input-el--prepend {
    padding-left: 10px;
    padding-right: 20px;
  }

  .country-list {
    width: 100px;
    height: 100px;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    background: #fff;
    border: 1px solid #aaa;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        padding: 0 5px;
        cursor: pointer;
        margin: 0;
        width: 100%;
        float: left;

        &:hover {
          background: #eee;
        }

        .flag-icon-wrapper {
          float: left;
          margin: 0 10px;
        }

        span {
          font-weight: bold;
          padding-left: 5px;
        }
      }
    }
  }
}
