@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.section-wrapper {
  width: auto;
  padding: math.div($grid-gutter-width, 2);
  margin-bottom: math.div($grid-gutter-width, 2);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit;
  }

  &:not(.login-wrapper) {
    margin-left: math.div($grid-gutter-width-negative, 2);
    margin-right: math.div($grid-gutter-width-negative, 2);
    .region-content & {
      margin-left: -20px !important;
      margin-right: -20px !important;

      .dashboard-opening {
        h4 {
          font-size: 26px;
        }
      }
    }
  }

  @include media-breakpoint-up('sm') {
    padding: $grid-gutter-width math.div($grid-gutter-width, 2);
  }

  &-content {
    position: relative;

    .item-config-section & {
      z-index: 5;
    }
  }

  &--dashed {
    border-width: 2px 0;
    border-style: dashed;
  }

  &--full-screen {
    min-height: calc(100vh - #{$header-height});
  }

  &--primary {
    color: $color--white;
    background: $color--primary;
    border-color: $color--blue--light;
  }

  &--primary-light {
    background: $color--blue--light;
    border-color: $color--primary;
  }

  &--secondary {
    color: $color--white;
    background: $color--secondary;
    border-color: $color--secondary--light;
  }

  &--secondary-light {
    background: $color--secondary--light;
    border-color: $color--secondary;
  }

  .sheet-layout--main-content {
    padding-bottom: 0;
  }
}
