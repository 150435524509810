@import '../../../assets/scss/helpers/all';

.simple-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;

  &--button {
    height: 1em;
    width: 2.2em;
    border: 1px solid $color--grey--teaserborder;
    background-color: $color--white;
    margin-right: 0.5em;
    border-radius: 0.5em;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 1.2em;
      width: 1.2em;
      background-color: $color--grey--darker;
      border-radius: 50%;
      transition: all 0.15s ease-out;
      transform: translate3d(-0.2em, -0.15em, 0);
    }

    &--active {
      &:after {
        transform: translate3d(1em, -0.15em, 0);
        background-color: $color--secondary;
      }
    }
  }
}
