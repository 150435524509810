@import '../../../assets/scss/helpers/all';

.message {
  width: 100%;
  margin-top: 0;
  padding: $button-spacing;
  line-height: 1.1;
  border-width: 1px;
  border-style: solid;
  border-radius: $border-radius--default;

  .trigger,
  a {
    color: inherit;
    text-decoration: underline !important;
  }

  &--error {
    background: $color--warn--lighter;
    border-color: $color--warn;
    color: $color--warn;
  }

  &--success {
    background: $color--secondary;
    border-color: $color--secondary;
    color: $color--white;
  }

  &--info {
    background: $color--primary;
    border-color: $color--primary;
    color: $color--white;
  }

  &--info-light {
    background: $color--primary--light;
    border-color: $color--primary--light;
    color: $color--primary;
  }
}

@media print {
  .messages,
  .message {
    display: none;
  }
}
