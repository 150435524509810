@use 'sass:math';

@import '../../assets/scss/helpers/all';

.input-el--tile {
  &--title {
    font-weight: bold;
    font-size: 1.1em;
  }

  &--option {
    line-height: 1.45em;
    padding: $tile-spacing--vertical;
    margin-bottom: 1em;

    @include media-breakpoint-up(md) {
      padding: $tile-spacing--vertical--desk;
    }

    .input-el--tile-compact & {
      padding: 15px;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    border-radius: $border-radius--tile;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    width: 100%;
    background-color: $color--white;
    animation: fadeInUp 0.33s ease-in-out 0s 1 forwards;
    border: $card-border;
    box-shadow: none;
    transition: box-shadow $default-transition-speed;

    @extend %cardshadow;

    &--time {
      cursor: default !important;
      border: $card-border;
      flex-wrap: wrap;

      .input-el--tile--copy {
        width: 70%;
        flex-grow: 1;
      }

      .input-el--tile--suffix {
        width: 30%;
      }
    }

    &--align-top {
      align-items: flex-start;
    }

    &--time-fields {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .input-el--select {
        width: calc(50% - #{math.div($grid-gutter-width, 2)});
      }
    }
  }
  &--option,
  &--animation {
    opacity: 0;
    animation: fadeInUp 0.33s ease-in-out 0s 1 forwards;
  }

  &--highlight {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation: backgroundBlink 0.5s ease-in-out 0s 3 forwards;
  }

  &--highlight-none {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation: none;
  }

  @keyframes backgroundBlink {
    0%,
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      background: $color--white;
    }

    50% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      background: $color--white--hover;
    }
  }

  &--copy {
    width: 100%;
    text-align: left;
    padding: 0 math.div($grid-gutter-width, 2) 0 0;
  }

  &--desc {
    font-family: $font-family--normal;
  }

  &--suffix,
  &--prefix {
    display: flex;
    white-space: nowrap;
    align-items: center;

    svg {
      height: 1.7em;
      width: 1.7em;
    }

    svg.IconEcoFriendly {
      height: 1em;
      width: 1.4em;
      margin: 0 0.5em -0.15em 0;
    }

    svg.IconSmile {
      height: 1em;
      width: 1em;
    }
  }

  &--prefix {
    justify-content: flex-start;
    padding-right: 25px;
  }

  &--suffix {
    width: 33%;
    font-weight: bold;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }
}
