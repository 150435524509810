@import '../../assets/scss/helpers/all';

.input-phone {
  .input--wrapper {
    width: 55px;
  }
  select {
    background-color: $color--grey--light;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    width: 55px;
    height: 100%;
  }
  input {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .IconSmile {
    display: none;
  }
}
