@import '../../../assets/scss/helpers/all';
.mp-banner {
  height: 120px;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 1em;
  text-decoration: none;
  @include media-breakpoint-up(md) {
    height: 150px;
  }
  img {
    height: 100%;
    width: auto;
    margin-bottom: -1rem;
  }
  label {
    background-color: #f15b56;
    padding: 0.2em 1em;
    border-radius: 5px;
    display: inline-block;
    font-weight: bold;
  }
  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h5 {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
      h5 {
        margin-bottom: 1.2rem;
      }
    }
  }
}
