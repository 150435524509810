@import '../../../assets/scss/helpers/all';

.status-label {
  display: inline-block;
  padding: 0.3em 0.7em;
  border-radius: 5em;
  font-size: 0.8em;
  line-height: 1;
  color: $color--grey--darker;
  background: $color--white;
  border: 1px solid $color--grey--light;
  margin-right: 0.5em;

  &:last-child {
    margin-left: 0;
  }

  &--caps {
    text-transform: uppercase;
  }

  &--blue {
    font-weight: bold;
    color: $color--white;
    background: $color--primary;
    border-color: $color--primary;
  }

  &--blue-light {
    font-weight: bold;
    color: $color--white;
    background: $color-blue--bright;
    border-color: $color-blue--bright;
  }

  &--green {
    font-weight: bold;
    color: $color--white;
    background: $color--secondary;
    border-color: $color--secondary;
  }

  &--red {
    font-weight: bold;
    color: $color--white;
    background: $color--warn;
    border-color: $color--warn;
  }
  &--black-outline {
    font-weight: bold;
    color: $color--black;
    background: $color--white;
    border-color: $color--black;
  }
}
