// Import bootstrap mixins
@import '../../../../../node_modules/bootstrap/scss/mixins';

//visiblility
@mixin visible($visible: true) {
  @if $visible {
    opacity: 1;
    visibility: visible;
  } @else {
    opacity: 0;
    visibility: hidden;
  }
}

@mixin media-breakpoint-desk-and-up() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin custom-breakpoint-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin labelStyle() {
  padding: 0 7px;
  border-radius: 100px;
  font-size: 0.55em;
  line-height: 1.9;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 0.5em;
}
