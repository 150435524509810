@import '../../../assets/scss/helpers/all';

.step-indicator {
  display: flex;
  color: $color--primary;
  align-items: center;
  font-weight: bold;

  &--circle {
    font-size: 2.8rem;
    background: $color--blue;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.1em;
    margin-right: 0.75em;

    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
      width: 38px;
      height: 38px;
    }
  }

  &--title {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .bubble-wrapper {
    margin-left: auto;
    line-height: 0.8;

    @include media-breakpoint-up(md) {
      &:hover {
        .bubble,
        .question-mark {
          fill: $color--secondary;
        }
      }
    }
  }
}
