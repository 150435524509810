.spinner-parent {
  position: relative;

  &.xs {
    min-height: 200px;
  }
}

.spinning-smile {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  position: relative;
  left: 0.6em;
  margin-right: 0.4em;
  animation: spin 0.33s infinite linear;
  transform-origin: 30% 50%;

  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.4em 0 0 0;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
