@use 'sass:math';

@import '../../../assets/scss/helpers/all';

body.bd-progress-header-loaded {
  padding-top: $progress-header-height !important;

  header.region-header {
    display: none;
  }

  footer.footer.clearfix {
    display: none;
  }
}

.progress-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 15px;
  text-align: center;
  line-height: 1;
  z-index: 10;
  background: $color--white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.15s ease-in-out;
  transition-delay: 0.33s;
  transform: translate3d(0, 0, 0);
  height: $progress-header-height--small;

  @include media-breakpoint-up(md) {
    height: $progress-header-height;
  }

  .bd-progress-header-finished & {
    transform: translate3d(0, -110%, 0);
  }

  &--logo-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: $progress-header-height--small;

    @include media-breakpoint-up(md) {
      height: $progress-header-height;
    }

    .brenger-logo {
      height: $progress-header-height--small * 0.8;

      @include media-breakpoint-up(md) {
        height: $progress-header-height * 0.8;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &--with-third-party-logo {
      .progress-header--logo-wrapper {
        justify-content: center;
        width: calc(100% - 100px);
      }

      .brenger-logo {
        height: 20px;
        width: 72px;
      }
    }
  }

  &--third-party-text {
    font-size: 0.8em;
    margin-left: math.div($grid-gutter-width, 2);
  }

  .go-back {
    padding: math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) 0;

    &-text {
      display: none;
      color: $color--grey--teaserborder;
      padding-left: math.div($grid-gutter-width, 2);

      @include media-breakpoint-up('sm') {
        display: inline;
      }
    }

    svg {
      transform: rotate(-180deg);
      height: 10px;
      width: 6px;
    }
  }
  .progress-steps {
    margin: 0 auto 0 0;
  }
  .input-el--select--lang {
    margin: 0 0 0 auto;
    @include media-breakpoint-up('sm') {
      margin: 0;
    }
    select {
      padding: 0.4em 1.4em 0.4em 0.4em;
    }
    .IconSmile {
      top: 50%;
    }
  }
}

.bd-main-header {
  .bd-progress-header-loaded & {
    transform: translate3d(0, -200px, 0);
  }

  .bd-progress-header-finished & {
    transform: translate3d(0, 0, 0);
    background: $color--primary;

    .brenger-logo {
      .cls-1 {
        fill: $color--white;
      }
    }
  }

  .bd-progress-header-loaded &,
  .bd-hide-menu & {
    .bd-nav-main,
    .bd-nav-toggle {
      display: none;
    }
  }
}
