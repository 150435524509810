@use 'sass:math';

@import '../../../assets/scss/helpers/all';

.progress-steps {
  display: inline-block;
  color: $color--grey--teaserborder;
  position: relative;
  padding: math.div($grid-gutter-width, 2);

  &--with-exit {
    padding-right: $grid-gutter-width * 1.5;
  }

  .progress-step {
    position: absolute;
    top: math.div($grid-gutter-width, 2);
    left: 0;
    transition: 0.33s all ease-in-out;
    transform: translate3d(10px, 10px, 0) rotate(45deg);

    @include visible(false);

    &--done {
      transform: translate3d(-10px, 10px, 0) rotate(-45deg);

      @include visible(false);
    }

    &--active {
      transform: translate3d(0, 0, 0) rotate(0deg);

      @include visible(true);
    }
  }

  &--exit {
    position: absolute;
    top: 50%;
    right: math.div($grid-gutter-width, 2);
    margin-top: -0.5em;

    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }
}
