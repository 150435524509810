%container-layout {
  padding: 50px;
  text-align: center;
}

%input-layout {
  font-family: $font-family--normal;
  padding: 12px 15px 2.112px 15px;
  color: $color--black;
  background: $color--white;
  width: 100%;
  display: block;
  line-height: inherit;
  font-size: $font-size--body--small;

  @include media-breakpoint-up(md) {
    font-size: $font-size--body;
    padding: 14.4px 17px 4.176px 17px;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    pointer-events: none;
    background: $color--blue--light;
  }

  label {
    margin: 0;
  }
}

%button {
  font-family: inherit;
  font-weight: bold;
  border-radius: $border-radius--default;
  -webkit-appearance: none;
  padding: 0.45em 15px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.3em;
  font-size: inherit;
  line-height: inherit;

  @include media-breakpoint-up(md) {
    padding: $input-spacing;

    &:hover {
      text-decoration: none;
    }
  }
}

%cardshadow {
  @include media-breakpoint-up(md) {
    &:hover {
      text-decoration: none;
      box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &.is-selected {
    border-color: $color--secondary;
    box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.33);

    @include media-breakpoint-up(md) {
      &:hover {
        border-color: $color--secondary;
        box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.33);
      }
    }
  }
}
