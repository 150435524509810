@import '../../../assets/scss/helpers/all';

.share-button {
  background-color: transparent;
  background-size: 100%;
  border: none;
  width: 3em;
  height: 3em;
  display: inline-block;
  margin-right: 1em;
  cursor: pointer;

  &:last-child {
    margin-right: none;
  }

  &--facebook {
    background-image: url('https://www.brenger.nl/img/icons/facebook-green.svg');
  }

  &--linkedin {
    background-image: url('https://www.brenger.nl/img/icons/linkedin-green.svg');
  }

  &--email {
    background-image: url('https://www.brenger.nl/img/icons/email-green.svg');
  }

  &--pinterest {
    background-image: url('https://www.brenger.nl/img/icons/pinterest-green.svg');
  }

  &--twitter {
    background-image: url('https://www.brenger.nl/img/icons/twitter-green.svg');
  }

  &--whatsapp {
    background-image: url('https://www.brenger.nl/img/icons/phone-green.svg');

    @include media-breakpoint-up('md') {
      display: none;
    }
  }
}
