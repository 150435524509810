@import '../../assets/scss/helpers/all';

.input-switch {
  background-color: $color--white;
  color: $color--primary;
  font-weight: bold;
  border: 1px solid $color--primary;
  display: flex;
  overflow: hidden;
  border-radius: $border-radius--default;
  label {
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  input:checked + label {
    background-color: $color--primary;
    color: $color--white;
  }
}
